export const buttons = () => {
	return {
		buscar: 'Buscar',
		new: 'Nuevo',
		save: 'Guardar',
		cancel: 'Cancelar',
		detail: 'Detalle',
		cotizar: 'Cotizar',
		emit: 'Emitir',
		exportExcel: 'Exportar a Excel',
		help: 'Ayuda',
		extras: 'Extras',
		confirm: 'Confirmar',
		filtrar: 'Filtrar',
	};
};
