<script setup>
import { defaultFilter, resultKey, resourceName } from '@service/PeopleGroupService';
import AutocompleteBase from './AutocompleteBase.vue';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps({
	label: {
		type: String,
		required: false,
	},
});

const labelWithDefault = props.label || t('shared.labels.perteneceGrupo');

</script>

<template>
	<AutocompleteBase
		:resourceName="resourceName"
		:defaultFilter="defaultFilter"
		:resultKey="resultKey"
		:label="labelWithDefault"
		v-bind="$attrs"
	/>
</template>
