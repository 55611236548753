<script setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue'

const { t } = useI18n();

const emit = defineEmits(['remove-filter']);

/*
const props = defineProps({
	applied_filters: Object,
	root_class: {
		type: String,
		default: 'surface-ground',
	},
});
*/

const filters = defineModel('filters');
const root_class = defineModel('root_class', { type: String, default: 'surface-ground' });

const activeFilters = computed(() => {
	let af = [];
    if (filters.value){
        for (const [key, value] of Object.entries(filters.value)) {
            if (value.applied && value.label) af.push(value);
        }
    }
    //console.log("active filters", af)
	return af;
});

function removeFilter(evt, filter) {
	filter.value = null;
	filter.applied = false;
	emit('remove-filter', evt, filter)
}

</script>
<template>
	<Fieldset
		v-if="activeFilters.length"
		:pt="{
			legend: { class: 'bg-black-alpha-10 pl-2 pr-2 pt-1 pb-1' },
			legendTitle: { class: 'text-700' },
			togglerIcon: { class: 'text-700' },
			root: { class: root_class },
			content: { class: 'p-1' },
		}"
		class="small-fs-legend mt-2"
		:legend="t('shared.labels.filtrosAplicados')"
	>
		<div class="flex align-content-center flex-wrap">
			<template v-for="filter in activeFilters" :key="filter.property+'-'+filter.operator">
				<div class="flex align-items-center justify-content-center mr-2">
					<Chip
						class="border-round-md"
						:label="filter.label"
						removable
						@remove="removeFilter($event, filter)"
					/>
				</div>
			</template>
		</div>
	</Fieldset>
</template>
<style lang="scss">
.small-fs-legend {
	font-size: 0.85rem !important;
	legend {
		a {
			box-shadow: none !important;
			padding: 0.15rem !important;
			// si lo quiero hacer toggable, habilitar esto
			/*
            font-size: 0.75rem !important;
            svg {
                width: 0.5rem;
            }
            */
		}
	}
}
</style>
