export const casco = () => {
	return {
		title: 'Casco',
		labels: {
            ano: 'Año',
            codigo_navegacion: 'Código Navegación',
            motor: 'Motor',
            codigo_construccion: 'Código Construcción',
            eslora: 'Eslora',
            matricula: 'Matrícula',
            puntal: 'Puntal',
            manga: 'Manga',
            guarderia: 'Guardería',
        },
	};
};
