export const granizo = () => {
	return {
		title: 'Granizo',
		labels: {
			tipo_cultivo: 'Tipo Cultivo',
			tipo_valor: 'Tipo Valor',
			hectareas: 'Hectáreas',
			fecha_siembra: 'Fecha de Siembra',
			valor_hectarea: 'Valor hectárea',
			variedad_semilla: 'Variedad de semilla',
		},
	};
};
