export const unidades = () => {
	return {
		toast: {
			error: {
				summary: 'Error',
				detail: 'Error cargando unidades',
			},
		},
		sideBarTitle: 'Búsqueda de Unidades',
		title: 'Unidades (Automotores)',
		labels: {
			motor: 'Motor: {motor}',
            chasis: 'Chasis: {chasis}',
		},
	};
};
