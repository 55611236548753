<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const emit = defineEmits(['search-form']);
</script>

<template>
	<form @submit.prevent="$emit('search-form', $event)">
		<slot></slot>
		<div class="p-fluid flex gap-2">
			<div class="field w-7 flex-grow-1" />
			<div class="w-3" v-if="$slots.leftbutton">
				<slot name="leftbutton" />
			</div>
			<div class="field w-3">
				<span class="p-float-label">
					<Button type="submit" class="p-button-search" :label="t('shared.buttons.buscar')" />
				</span>
			</div>
			<div class="w-3" v-if="$slots.rightbutton">
				<slot name="rightbutton" />
			</div>
		</div>
	</form>
</template>
