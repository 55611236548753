export const preliquidaciones = () => {
	return {
		toast: {
			error: {
				summary: 'Error',
				detail: {
					search: 'Error buscando preliquidaciones',
					generate: 'Error al generar Preliquidación',
				},
			},
		},
		contextMenu: {
			liquidar: 'Liquidar',
			editar: 'Editar Liquidación',
			borrar: 'Borrar Liquidación',
		},
		sideBarTitle: 'Búsqueda de Rendiciones Realizadas',
		title: 'Comisiones - Preliquidaciones',
		labels: {
			numero: 'Número de Liquidación',
			fecha: 'Fecha de Liquidación',
			numero_preliquidacion: 'Número de Preliquidación',
			cambio_dolar: 'Tipo Cambio Dólar',
			cambio_euro: 'Tipo Cambio Euro',
		},
		message: 'Comisiones de esta Preliquidación',
		confirm: {
			header: 'Borrar Preliquidación',
			message: 'Se eliminará la preliquidación. Si las comisiones están liquidadas se perderá esa referencia. ¿Continuar?',
		},
	};
};
