<script setup>
import { ref } from 'vue';
import { searchDefault } from '@service/AxiosBaseService';
import { defaultFilter, resultKey, resourceName } from '@service/ProducerService';
import { defaultFilter as ownDefaultFilter, resultKey as ownResultKey, resourceName as ownResourceName } from '@service/OwnProducerService';
import { useEllipsis } from '@helpers/stringHelper';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
	modelValue: Object | String,
	class: String,
    internals: {
        type: Boolean,
		default: false,
    },
	multiple: {
		type: Boolean,
		default: false,
	},
	dataKey: {
		type: String,
		default: 'id',
	},
	field: {
		type: [String, Function],
		default: 'nombre',
	},
	label: {
		type: String,
		required: false,
	},
	showLabel: {
		type: Boolean,
		default: true,
	},
	showClear: Boolean,
});

const labelWithDefault = props.label || t('shared.labels.productor');

const suggestions = ref([]);

function searchRemote(event) {
	searchDefault(event.query, props.internals? ownResourceName : resourceName, props.internals? ownDefaultFilter : defaultFilter)
		.then((response) => {
			suggestions.value = response.data[props.internals? ownResultKey : resultKey];
		})
		.catch((error) => {
			console.log(error);
		});
}

const emit = defineEmits(['update:modelValue', 'item-click']);
</script>

<template>
	<span class="p-float-label flex">
		<AutoComplete
			:id="resultKey"
			class="w-full flex-grow-1"
			:modelValue="modelValue"
			:multiple="multiple"
			:suggestions="suggestions"
			:dataKey="dataKey"
			:optionLabel="field"
			v-bind="$attrs"
			dropdown
			forceSelection
			@change="emit('update:modelValue', $event.value)"
			@item-click="emit('item-click')"
			@complete="searchRemote"
		>
			<template #option="slotProps">
				<div>
					<i
						class="mr-2"
						:class="
							slotProps.option.external_producer
								? 'la la-user-lock text-red-800'
								: 'la la-user-check text-green-700'
						"
						style="font-size: 1.2rem"
					/>
					{{ slotProps.option.nombre }}
				</div>
			</template>
			<template #chip="slotProps">
				{{ useEllipsis(slotProps.value.nombre, 20) }}
			</template>
		</AutoComplete>
		<Button
			v-show="modelValue && showClear"
			class="w-1 h-full absolute unstyled"
			icon="pi pi-times"
			severity="secondary"
			text
			@click="$emit('update:modelValue', '')"
		/>
		<label :for="resultKey" v-show="showLabel">{{ labelWithDefault }}</label>
	</span>
</template>

<style scoped>
.unstyled {
	right: 4em;
}
.unstyled:hover,
.unstyled:active,
.unstyled:focus {
	background-color: transparent;
}
</style>
