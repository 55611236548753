export const suscripcion = () => {
	return {
		fieldset: {
			legend: 'Mi Suscripción',
			message: `
                El costo de los Planes aumenta mensualmente tomando como base el IPC`,
		},
		tabview: {
			pagos: 'Pagos',
		},
		autocomplete: {
			intervals: 'Tipo Suscripción (aplica a todos los usuarios)',
			plans: 'Plan',
		},
		datatable: {
			pagos: {
				columns: {
					due_date: 'Vencimiento',
					subtotal: 'Subtotal',
					total: 'Total c/iva',
					invoice_concepts: 'Conceptos',
					estado: 'Estado',
					balance: 'Saldo',
				},
				estado: {
					pendiente: 'Pendiente',
					saldada: 'Saldada',
				},
			},
			users: {
				columns: {
					user_nombre: 'Nombre',
					email: 'Mail',
					membership: 'Plan'
				}
			},
		},
		form: {
			toast: 'Datos Actualizados',
			saveButton: 'Guardar Cambios',
		}
	};
};
