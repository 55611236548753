export const refacturaciones = () => {
	return {
		title: 'Refacturaciones', 
		searchTitle: 'Búsqueda de Refacturaciones',
		filters: {
			vigencia: {
				gte: 'Refacturaciones Desde',
				lte: 'Refacturaciones Hasta',
			},
			estado: 'Buscar en Pólizas',

		}
	};
};
