import { ref } from 'vue';
export const messages = ref([])
export const lateralToast = ref()

export const generatingFile = ref(false);

export function addMessage(id, severity, content) {
	messages.value.push({ id: id, severity: severity, content: content });
}

export function deleteMessage(id) {
	messages.value = messages.value.filter(function (msg) {
		return msg.id !== id;
	});
}

export const notaVisible = ref(false)
export const currentNota = ref({})

export function verNota(nota,title = "Notas") {
	currentNota.value.nota = nota
    currentNota.value.title = title
	notaVisible.value = true;
}
