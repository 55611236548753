export const config = () => {
	return {
		toast: {
			success: {
				summary: {
					ready: 'Listo!',
					generated: 'Solicitud generada',
				},
				detail: {
					enabledCode: 'Código habilitado',
					disabledCode: 'Código deshabilitado',
				},
			},
			error: {
				summary: 'Error',
				detail: 'Error actualizando perfil de cotización',
			},
		},
		title: 'Configurar Cotizador',
		ciasHeader: 'Compañías disponibles',
		dialog: {
			sendMailTo: 'Enviar mail a ejecutivo de {title}',
			solicitar: 'Solicitar alta en {title}',
			config: 'Configurar {title}',
		},
		labels: {
			registeredEmail: 'Email registrado en {title}',
			contactEmail: 'Email del contacto comercial en {title}',
			code: 'Código de PAS en {title}',
			enabled: 'Habilitado',
			disabled: 'Deshabilitado',
		},
		buttons: {
			send: 'Enviar Datos',
			solicitar: 'Solicitar alta',
			video: 'Video',
		},
		codigos: {
			header: 'Códigos configurados para cotizar',
			searching: 'Buscando códigos configurados...',
			codes: '{count} Códigos configurados para cotizar',
		},
        coberturas: {
            header: 'Coberturas personalizadas'
        }
	};
};
