<script setup>
import { ref } from 'vue';
import { saveToLocal } from '@service/LocalStorageService';

const id = defineModel('id');
const title = defineModel('title', { default: 'Novedades en el sistema' });
const parts = defineModel('parts', { default: [] });
const visible = defineModel('visible', { default: false });

const closeDialog = () => {
	saveToLocal('modal_watched_' + id.value, true);
};
</script>

<template>
	<Dialog
		:pt="{
			header: { class: 'gradientNewsModal' },
			content: { class: 'bg-surface-900' },
			closeButton: { class: 'text-white' },
		}"
		:style="{ width: '40vw' }"
		:breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
		v-model:visible="visible"
		position="center"
		:modal="true"
		:draggable="false"
		@hide="closeDialog"
	>
		<template #header>
			<div class="flex align-items-center justify-content-center">
				<img src="/public/images/adminse_inverted_54.png" alt="Adminse-logo" />
			</div>
			<div class="flex align-items-center justify-content-center mt-4">
				<h5 class="text-white">{{ title }}</h5>
			</div>
		</template>
		<template #default>
			<div class="flex flex-column mt-7 ml-5 mr-5">
				<div v-for="part in parts" :key="part" class="mb-5 flex align-items-center justify-content-start">
					<i class="las la-angle-right mr-4"></i>
					<span v-html="part"></span>
				</div>
			</div>
		</template>
	</Dialog>
</template>
<style>
.gradientNewsModal {
	background: linear-gradient(to bottom right, #0f172a, #1c75bb);
}
</style>
