import { usePut, useDelete, saveGenericEntity } from '@service/AxiosBaseService';
import { ref } from 'vue';
import { createFilter } from '@helpers/filtersHelper';

export const resourceName = 'api/producers';
export const resultKey = 'producer';
export const selectedProducer = ref({
	id: null,
});


export const filters = ref({
	nombre: createFilter('nombre', 'string', 'like', 'name'),
});

export const defaultFilter = {
	nombre: createFilter('nombre', 'string', 'like', 'name'),
};


export const updateProducer = (data) => usePut(`${resourceName}/${data.id}`, { producer: data });

export const deleteProducer = async (producer) => {
	await useDelete(`${resourceName}/${producer.id}`, producer);
};

export const saveProducer = async (selectedProducer) => {
	let producerCopy = JSON.parse(JSON.stringify(selectedProducer.value));
	producerCopy = setSimpleAssociations(producerCopy);
	return await saveGenericEntity(selectedProducer, { producer: producerCopy }, resourceName, resultKey);
};

const setSimpleAssociations = (producerCopy) => {
	return producerCopy;
};
