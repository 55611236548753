import { saveGenericEntity, useDelete, useGetAll } from '@service/AxiosBaseService';
import { ref, unref } from 'vue';

export const resourceName = 'api/productions';
export const resultKey = 'production';

export const productions = ref([]);

export const filters = ref({
	producer_id: { property: 'producer_id', value: null, type: 'combo', operator: 'in' },
});

export function getProductionsByProducers(producer_ids) {
	filters.value.producer_id.value = producer_ids;
	return useGetAll({ filters: filters.value, rows: 1000 }, resourceName);
}

export const getProductions = async (params) => {
	const response = await useGetAll(params?? {rows: 300, start:0},  resourceName);
	productions.value = response.data[resultKey]; // lo guardo para reutilizarlo
	const esto = {
		data: response.data[resultKey],
		total: response.data.meta.total,
	};
	return esto;
};

export const saveProductions = async (production) => {
	let copy = JSON.parse(JSON.stringify(unref(production)));
	copy = setSimpleAssociations(copy);
	return await saveGenericEntity(production, { [resultKey]: copy }, resourceName, resultKey);
};

export const deleteProduction = async (productionId) => {
	try {
		const response = await useDelete(`${resourceName}/${productionId}`, { production: productionId });
		return response;
	} catch (error) {
		return error;
	}
};

const setSimpleAssociations = (copy) => {
	console.log('copy', copy);
	copy.company_id = copy.company.id;
	copy.producer_id = copy.producer.id;
	return copy;
};
