<script setup>
import { ref } from 'vue';
//import { useUploadAttachment, useLoadAttachments, useDeleteAttachment, useDownloadAttachment } from '@service/AttachmentService'
import Axios from 'axios';
import { Cropper } from 'vue-advanced-cropper';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { useAppConfigStore } from '@/stores/AppConfigStore';
const { t } = useI18n(); // use as global scope

const props = defineProps({
	tipo: String,
});

const emit = defineEmits(['refresh']);

const appConfigStore = useAppConfigStore();

const toast = useToast();

const cropper = ref();
const displayCropper = ref(false);
const fotoUpload = ref(null);
const imageToCrop = ref({ src: null });

function doUploadFoto(evt, tipo) {
	imageToCrop.value.src = evt.files[0].objectURL;
	imageToCrop.value.type = evt.files[0].type;
	imageToCrop.value.tipo = tipo;
	imageToCrop.value.evt = evt;
	displayCropper.value = true;
}

function uploadFoto(event) {
	event.preventDefault();
	fotoUpload.value.choose();
}

function closeImageCrop() {
	// event.preventDefault();
	imageToCrop.value.src = null;
}

function uploadCroppedImage() {
	if (cropper.value) {
		const { canvas } = cropper.value.getResult();

		let formData = new FormData();

		canvas.toBlob((blob) => {
			switch (imageToCrop.value.tipo) {
				case 'logo':
					formData.append('partnership[logo]', blob);

					Axios.put('/api/partnership', formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
						.then(function () {
							toast.add({
								severity: 'success',
								summary: t('app.topbar.logo.success'),
								detail: '',
								life: 3000,
							});
							// reload en store
							//emit('reload-partnership', imageToCrop.value.evt);
							appConfigStore.reloadCurrentPartnership(toast);
							imageToCrop.value.src = null;
							displayCropper.value = false;
						})
						.catch(function () {
							toast.add({
								severity: 'error',
								summary: 'Error',
								detail: t('app.topbar.logo.error'),
								life: 3000,
							});
						});
					break;
				case 'foto':
					formData.append('user[foto]', blob);
					Axios.put('/api/user', formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
						.then(function () {
							toast.add({
								severity: 'success',
								summary: t('app.topbar.foto.success'),
								detail: '',
								life: 3000,
							});
							appConfigStore.loadCurrentUser(toast);
							imageToCrop.value.src = null;
							displayCropper.value = false;
						})
						.catch(function () {
							toast.add({
								severity: 'error',
								summary: 'Error',
								detail: t('app.topbar.foto.error'),
								life: 3000,
							});
						});
					break;
			}
		});
	}
	emit('refresh');
}
</script>
<template>
	<Toast />
	<Dialog :header="t('app.topbar.cropDialog.title')" v-model:visible="displayCropper">
		<Cropper class="cropper" ref="cropper" :src="imageToCrop.src" />
		<template #footer>
			<div class="mt-4">
				<Button
					:label="t('shared.buttons.cancel')"
					icon="pi pi-times"
					@click="closeImageCrop"
					class="p-button-text"
				/>
				<Button :label="t('shared.buttons.save')" icon="pi pi-check" @click="uploadCroppedImage" autofocus />
			</div>
		</template>
	</Dialog>
	<FileUpload
		accept="image/*"
		:hidden="true"
		ref="fotoUpload"
		:chooseLabel="t('app.topbar.chooseLabel')"
		mode="basic"
		:auto="true"
		:customUpload="true"
		@uploader="doUploadFoto($event, tipo)"
	/>
	<button class="logo-button p-link layout-menu-button layout-topbar-button" @click="uploadFoto($event)">
		<slot></slot>
	</button>
</template>

<style scoped lang="scss">
.logo-button {
	&:hover {
		background: none;

		img {
			background-color: var(--surface-200);
			border-radius: 5em;
		}
	}

	&:focus {
		box-shadow: none;
	}
}
</style>
