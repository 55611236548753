export const historial = () => {
	return {
        errors: {
            mark_all: 'Marcar importación como revisada'
        },
		toast: {
			success: {
				summary: {
					freeze: 'Bloqueando operaciones de esta importación. Puede demorar varios minutos.',
					generate: 'Generando reporte en paralelo...',
					reejecutar: 'Reejecutando en paralelo...',
					delete: 'Eliminando datos en paralelo. Esto puede demorar varios minutos',
					showError: 'Reporte de error enviado a Soporte. Te responderemos vía mail a la brevedad.',
				},
			},
			error: {
				summary: {
					error: 'Error',
					generate: 'Error en generación de reporte',
					reejecutar: 'Error en reejecución',
					download: 'Error en descarga de archivo',
				},
				detail: {
					loadError: 'Error cargando historial',
					delete: 'Error: La importación no pudo eliminarse. Pruebe más tarde.',
				},
			},
		},
		contextMenu: {
			inform: 'Informar error o póliza faltante a Soporte AdminSE',
			download: 'Descargar archivo(s) usado(s) en la importación',
			reejecutar: 'Reejecutar importación',
			generate: 'Generar reporte de operaciones importadas',
			delete: 'Borrar Importación (y todos los datos que se importaron)',
			showErrors: 'Ver detalle de errores',
		},
		confirm: {
			freeze: '¿Está seguro que desea bloquear las operaciones de esta importación? Esta acción no se puede deshacer',
			delete: '¿Está seguro que desea borrar esta importación? Esta acción no se puede deshacer',
			generate:
				'Se va a generar el reporte de importación y te lo enviaremos por mail en los próximos minutos ¿Continuar?',
			reejecutar:
				'¿Está seguro que desea reejecutar esta importación? Consulte con Soporte antes de realizar esta acción',
		},
		labels: {
			dateGte: 'Fecha de Ejecución mayor a',
			dateLte: 'Fecha de Ejecución menor a',
			errors: 'Tiene errores de algún tipo',
		},
		title: 'Historial de Importaciones',
		sideBarTitle: 'Búsqueda de Importaciones',
		dialog: {
			inform: 'Informar póliza faltante en {denominacion}',
			reports: 'Reportes de errores realizados',
			detail: 'Detalle de Errores en Importación',
		},
		overlayPanel: {
			operaciones: 'Operaciones con error: {amount}',
			pagosPendientes: 'Pagos pendientes con error: {amount}',
			pagos: 'Pagos con error: {amount}',
			comisiones: 'Comisiones con error: {amount}',
		},
		buttons: {
			errors: 'Mis Errores Reportados',
		},
		datatables: {
			columns: {
				state: 'Estado de importación',
				result: 'Resultado',
				ejecucion: 'Ejecución',
                period: 'Período'
			},
			archivo: 'Archivo: {filename}',
			operaciones: 'Operaciones procesadas: {amount}',
			pagosPendientes: 'Pagos pendientes procesadas: {amount}',
			pagosRealizados: 'Pagos realizados procesados: {amount}',
			comisiones: 'Comisiones procesadas: {amount}',
			for: 'Por',
		},
	};
};
