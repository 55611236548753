const validarLargoCBU = (cbu) => cbu.replaceAll(' ', '').length === 22;

const validarCodigoBanco = (number) => {
	const codigo = number.replaceAll(' ', '');
	if (codigo.length !== 8) {
		return false;
	}
	var banco = codigo.substr(0, 3);
	var digitoVerificador1 = codigo[3];
	var sucursal = codigo.substr(4, 3);
	var digitoVerificador2 = codigo[7];

	var suma =
		banco[0] * 7 +
		banco[1] * 1 +
		banco[2] * 3 +
		digitoVerificador1 * 9 +
		sucursal[0] * 7 +
		sucursal[1] * 1 +
		sucursal[2] * 3;

	var diferencia = 10 - (suma % 10);

	return diferencia === digitoVerificador2;
};

const validarCuenta = (number) => {
	const cuenta = number.replaceAll(' ', '');
	if (cuenta.length !== 14) {
		return false;
	}
	var digitoVerificador = cuenta[13];
	var suma =
		cuenta[0] * 3 +
		cuenta[1] * 9 +
		cuenta[2] * 7 +
		cuenta[3] * 1 +
		cuenta[4] * 3 +
		cuenta[5] * 9 +
		cuenta[6] * 7 +
		cuenta[7] * 1 +
		cuenta[8] * 3 +
		cuenta[9] * 9 +
		cuenta[10] * 7 +
		cuenta[11] * 1 +
		cuenta[12] * 3;
	var diferencia = 10 - (suma % 10);
	return diferencia === digitoVerificador;
};

export function validarCBU(cbu) {
	return (
		(cbu && validarLargoCBU(cbu)) ||
		//validarCodigoBanco(cbu.substr(0, 8)) &&
		//validarCuenta(cbu.substr(8, 14)))
		!cbu
	);
}

export const getStatus = (cbu) => {
	if (cbu) {
		if (!validarLargoCBU(cbu)) return 'El largo del CBU es incorrecto.';
		else if (!validarCodigoBanco(cbu.substr(0, 8))) return 'Código de banco erroneo';
		else return 'Cuenta inválida';
	}
};
