<script setup>
import { ref, watch, onBeforeMount, onMounted, onBeforeUnmount } from 'vue';
import { lateralToast } from '@service/MessageService';
import { darkModeSwitch } from '@helpers/themeHelper';
import { addMessage } from '@service/MessageService';
import Axios from 'axios';
import { useAppConfigStore } from '@/stores/AppConfigStore';
import { useNotificationStore } from '@/stores/NotificationStore';
import { storeToRefs } from 'pinia';
import { useToast } from 'primevue/usetoast';
import FotoUploader from './components/FotoUploader.vue';
import 'vue-advanced-cropper/dist/style.css';
import { useI18n } from 'vue-i18n';
import { usePrimeVue } from 'primevue/config';
import { useAdminseWindowOpen } from '@helpers/downloadHelper';
import { saveToLocal, getFromLocal } from '@service/LocalStorageService';
import { pageLoading } from './main';
import NewsModal from '@components/NewsModal.vue';
import Novedades from '@service/NovedadesService';

const { t } = useI18n(); // use as global scope

const primevue = usePrimeVue();

const appConfigStore = useAppConfigStore();
const { currentPartnership, currentUser, currentNovedad } = storeToRefs(appConfigStore);

const notificationStore = useNotificationStore();
const { totalNotifications } = storeToRefs(notificationStore);

const emit = defineEmits(['menu-toggle', 'topbar-menu-toggle']);

const toast = useToast();
const settingsPanel = ref();
const scale = ref(null);
const scales = ref([11, 12, 13, 14, 15, 16, 17]);

watch(darkModeSwitch, () => {
	setTheme();
	localStorage.setItem('darkMode', darkModeSwitch.value);
});

const setTheme = () => {
	if (darkModeSwitch.value) {
		primevue.changeTheme('viva-light', 'viva-dark', 'theme-link', () => {});
	} else {
		primevue.changeTheme('viva-dark', 'viva-light', 'theme-link', () => {});
	}
};

onBeforeMount(() => {
	getScale();
	applyScale();
	setNovedades();
});

watch(lateralToast, (lt) => {
	toast.add(lt);
});

watch(currentPartnership, (np, op) => {
	// Debt logic
	if (
		np.id != op.id &&
		currentPartnership.value.invoices_pendientes_pago &&
		currentPartnership.value?.current_subscription?.tipo_pago != 'debito_cuenta' &&
		!currentPartnership.value?.current_subscription?.plan?.includes('enterprise')
	) {
		addMessage(currentPartnership.value.id, 'error', t('app.topbar.debtMessage'));
	}
});

function setNovedades() {
	// Novedades iniciales
	const novedad = Novedades.find((el) => {
		const date = el.show_until?.includes('/') ? el.show_until?.split('/') : el.show_until?.split('-');
		const isOnDate = date && new Date(date[2], date[1] - 1, date[0]) >= new Date();
		return !getFromLocal('modal_watched_' + el.id) && (!el.show_until || isOnDate);
	});

	novedad && appConfigStore.addToCurrentNovedad(novedad.id, novedad.title ?? '', novedad.text);
}

function getScale() {
	const savedScale = getFromLocal('scale');
	if (savedScale) {
		scale.value = parseInt(savedScale);
		if (scale.value < 11) scale.value = 11;
	} else {
		scale.value = 14;
	}
}

function saveScale() {
	saveToLocal('scale', scale.value);
}
watch(scale, saveScale);

function showLocalSettings(event) {
	settingsPanel.value.toggle(event);
}

function onMenuToggle(event) {
	emit('menu-toggle', event);
}

function onTopbarMenuToggle(event) {
	emit('topbar-menu-toggle', event);
}

function topbarImage() {
	return '/images/new_logo_text.png';
}

function decrementScale() {
	scale.value--;
	applyScale();
}
function incrementScale() {
	scale.value++;
	applyScale();
}
function applyScale() {
	document.documentElement.style.fontSize = scale.value + 'px';
}

const selectedNotification = ref();
const notificationsPanel = ref();

function showNotifications(event) {
	if (totalNotifications.value.length !== 0) {
		notificationsPanel.value.toggle(event);
	}
}

function logout() {
	pageLoading.value = true;
	Axios.delete('/users/sign_out', {
		_method: 'delete',
	})
		.then(function (response) {
			console.log('logged out');
			window.location.href = import.meta.env.VITE_BASE_URL;
		})
		.finally(function (response) {
			pageLoading.value = false;
			console.log('logged out finally');
		});
}

function removeNotification(notification) {
	switch (notification.type) {
		case 'ticket':
		case 'ticket_comment':
			notificationStore.removeBellNotification(notification.id);
			break;
		default:
			totalNotifications.value = totalNotifications.value.filter((tn) => tn.id !== notification.id);
			break;
	}
	if (totalNotifications.value.length === 0) {
		notificationsPanel.value.toggle();
	}
}

// pseudo created hook
//appConfigStore.loadCurrentPartnership(toast);
appConfigStore.loadCurrentUser(toast);
//appConfigStore.loadCompanies(toast);
appConfigStore.loadProductions();

notificationStore.loadCumpleanios(toast);
notificationStore.loadRenovaciones(toast);
notificationStore.loadPropuestas(toast);
notificationStore.loadRefacturaciones(toast);
notificationStore.loadTicketsPendientes(toast);

const executeNotificationJob = () => {
	notificationStore.loadBellNotifications(toast);
};

onMounted(() => {
	if (localStorage.getItem('darkMode')) {
		darkModeSwitch.value = JSON.parse(localStorage.getItem('darkMode'));
	}
	executeNotificationJob();
	window.intervalId = setInterval(executeNotificationJob, 300000);
});

onBeforeUnmount(() => {
	clearInterval(window.intervalid);
});
</script>

<template>
	<div class="layout-topbar flex">
		<NewsModal
			:id="currentNovedad.id"
			:title="currentNovedad.title"
			:parts="currentNovedad.parts"
			v-model:visible="currentNovedad.visible"
		/>

		<router-link to="/" class="layout-topbar-logo">
			<img class="ml-4" alt="Logo" :src="topbarImage()" />
			<!--span>Admin</span><span class="se">SE</span-->
		</router-link>
		<div class="flex-initial">
			<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
				<i class="pi pi-bars"></i>
			</button>
		</div>
		<div class="mr-2">
			<div class="h-full w-full ml-4 mr-2">
				<FotoUploader tipo="logo">
					<img
						v-if="currentPartnership.logo"
						role="presentation"
						:alt="currentPartnership.fantasy_name"
						:src="currentPartnership.logo"
						class="ml-4 w-full h-full"
					/>
					<i v-else class="la la-building text-3xl"></i>
				</FotoUploader>
			</div>
		</div>
		<div class="ml-6 flex-initial">
			{{ currentPartnership.fantasy_name }}
		</div>

		<button
			class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{
				selector: '@next',
				enterClass: 'hidden',
				enterActiveClass: 'scalein',
				leaveToClass: 'hidden',
				leaveActiveClass: 'fadeout',
				hideOnOutsideClick: true,
			}"
		>
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<!--li>
				<button
					v-tooltip="'Volver a la versión anterior (no recibirá mas actualizaciones)'"
					@click="useAdminseWindowOpen('app')"
					class="p-link layout-topbar-button"
				>
					<i class="la la-meteor text-2xl"></i>
				</button>
			</li-->
			<li>
				<button
					@click="showNotifications"
					v-bind:class="totalNotifications.length <= 0 ? 'no-notification' : ''"
					class="p-link layout-topbar-button"
				>
					<i class="pi pi-bell p-overlay-badge" v-badge.danger="totalNotifications.length"></i>
				</button>
				<OverlayPanel ref="notificationsPanel" appendTo="body" :showCloseIcon="false" style="width: 30vw">
					<DataTable
						class="no-column-header"
						:header="false"
						v-model:selection="selectedNotification"
						:value="totalNotifications"
						selectionMode="single"
						:paginator="true"
						:rows="5"
					>
						<Column :header="false">
							<template #body="slotProps">
								<template v-if="slotProps.data.type == 'renovation'">
									<router-link :to="{ name: 'renovaciones' }" class="notificationsLinks">
										<div class="flex align-items-center flex-wrap">
											<div class="flex align-items-center justify-content-center mr-3">
												<Avatar
													:icon="'pi ' + slotProps.data.pi_icon"
													style="background-color: var(--red-100); color: var(--red-800)"
													shape="circle"
												/>
											</div>
											<div class="flex align-items-center justify-content-center">
												<div v-html="slotProps.data.title"></div>
											</div>
										</div>
									</router-link>
								</template>
								<template v-if="slotProps.data.type == 'refacturation'">
									<router-link :to="{ name: 'refacturaciones' }" class="notificationsLinks">
										<div class="flex align-items-center flex-wrap">
											<div class="flex align-items-center justify-content-center mr-3">
												<Avatar
													:icon="'pi ' + slotProps.data.pi_icon"
													style="background-color: var(--red-100); color: var(--red-800)"
													shape="circle"
												/>
											</div>
											<div class="flex align-items-center justify-content-center">
												<div v-html="slotProps.data.title"></div>
											</div>
										</div>
									</router-link>
								</template>
								<template
									v-if="slotProps.data.type != 'renovation' && slotProps.data.type != 'refacturation'"
								>
									<div class="flex align-items-center flex-wrap">
										<div class="flex align-items-center justify-content-center mr-3">
											<Avatar
												:icon="'pi ' + slotProps.data.pi_icon"
												style="background-color: var(--red-100); color: var(--red-800)"
												shape="circle"
											/>
										</div>
										<div class="flex align-items-center justify-content-center">
											<div v-html="slotProps.data.title"></div>
										</div>
									</div>
								</template>
							</template>
						</Column>
						<Column :exportable="false" style="padding: 0; width: 0">
							<template #body="slotProps">
								<Button
									icon="pi pi-trash"
									class="p-button p-button-danger p-button-text"
									@click="removeNotification(slotProps.data)"
								/>
							</template>
						</Column>
					</DataTable>
				</OverlayPanel>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<router-link
						to="/eventos"
						class="p-ripple"
						style="color: var(--text-color-secondary)"
						exact
						role="menuitem"
						v-ripple
					>
						<i class="pi pi-calendar"></i>
					</router-link>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<router-link
						:to="{ name: 'videos' }"
						class="p-ripple"
						style="color: var(--text-color-secondary)"
						exact
						role="menuitem"
						v-ripple
					>
						<i class="pi pi-video"></i>
					</router-link>
				</button>
			</li>
			<li>
				<button @click="showLocalSettings" class="p-link layout-topbar-button">
					<i class="pi pi-cog"></i>
				</button>
				<OverlayPanel
					ref="settingsPanel"
					appendTo="body"
					:showCloseIcon="false"
					id="local_settings"
					class="flex flex-column justify-content-center align-content-center"
				>
					<div class="w-12">
						<h5 class="mt-0">Tamaño</h5>
						<div class="config-scale">
							<Button
								icon="pi pi-minus"
								@click="decrementScale"
								class="p-button-text"
								:disabled="scale === scales[0]"
							/>
							<i
								class="pi pi-circle-on"
								v-for="s of scales"
								:class="{ 'scale-active': s === scale }"
								:key="s"
							/>
							<Button
								icon="pi pi-plus"
								@click="incrementScale"
								class="p-button-text"
								:disabled="scale === scales[scales.length - 1]"
							/>
						</div>
						<div>
							<h5 class="mt-0">Modo oscuro</h5>
							<InputSwitch v-model="darkModeSwitch" />
						</div>
					</div>
				</OverlayPanel>
			</li>
			<li>
				<FotoUploader tipo="foto">
					<div class="h-full w-full relative overflow-hidden" v-if="currentUser.foto">
						<img
							class="absolute h-full border-round-xs"
							role="presentation"
							:alt="currentUser.user_nombre"
							:src="currentUser.foto"
						/>
					</div>
					<i v-else class="pi pi-user"></i>
				</FotoUploader>
			</li>
			<li>
				<div class="ml-4 h-full flex align-items-center">{{ currentUser.user_nombre }}</div>
			</li>
			<li>
				<button v-tooltip="'Cerrar sesión'" @click="logout()" class="p-link layout-topbar-button">
					<i class="pi pi-sign-out"></i>
				</button>
			</li>
		</ul>
	</div>
</template>

<style scoped lang="scss">
.no-column-header :deep(.p-datatable-wrapper th) {
	padding: 0;
	border: none;
}

.no-notification:deep(span) {
	display: none;
}

.config-scale {
	display: flex;
	align-items: center;
	margin: 1rem 0 2rem 0;

	.p-button {
		margin-right: 0.5rem;
	}

	i {
		margin-right: 0.5rem;
		font-size: 0.75rem;
		color: var(--text-color-secondary);

		&.scale-active {
			font-size: 1.25rem;
			color: var(--primary-color);
		}
	}
}

.notificationsLinks {
	// Your custom styles to disable the default styling
	text-decoration: none;
	color: inherit;
}
</style>
