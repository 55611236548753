<script setup>
import {
	paises
} from '@service/entities/LocalEntitiesService.js';

const model = defineModel();
const placeholder = defineModel('placeholder',{default: false});

</script>

<template>
	<Dropdown
        filter
        v-model="model"
        class="w-full"
        :options="paises"
        optionLabel="value"
        optionValue="value"
        :placeholder="placeholder"
    />
</template>
