export const moviemientosCaja = () => {
	return {
		toast: {
			error: {
				summary: 'Error',
				detail: 'Error buscando movimientos',
			},
		},
		sideBarTitle: {
			search: 'Búsqueda Movimientos de Caja',
			movement: 'Movimiento',
		},
		title: 'Movimientos Caja',
		buttons: {
			newMovement: 'Nuevo Movimiento',
		},
		contextMenu: {
			editar: 'Editar Movimiento',
			borrar: 'Borrar Movimiento',
		},
		labels: {
			subtotales: 'Subtotales',
			egresos: 'Egresos',
			ingresos: 'Ingresos',
		},
	};
};
