export const stats = () => {
	return {
		title: 'Estadísticas',
		dialogs: {
			headers: {
				ramosPorCompany: 'Ramos por Compañía',
				companysPorRamo: 'Compañías por Ramo',
			},
		},
		search: {
			title: 'Filtros de Estadísticas',
			todos: {
				labels: {
					productores: 'Productores',
					companies: 'Compañías (se pueden seleccionar varias)',
					ramosSi: 'Incluir pólizas',
					ramosNo: 'NO Incluir pólizas',
				},
			},
		},
		toast: {
			error: {
				summary: 'Error',
				detail: 'Hubo un error al buscar estadísticas para {tab}',
			},
		},
		acumulado: { header: 'Acumulado del {year}' },
		mensual: {
			header: 'Evolución Mensual',
			altas_bajas: 'Pólizas: Altas y Bajas totales por mes',
			primas_pesos: 'Primas de emisiones y anulaciones - Totales por mes en pesos ($)',
			primas_dolares: 'Primas de emisiones y anulaciones - Totales por mes en dólares (U$S)',
			premio_pesos: 'Premio facturado y comisiones: totales por mes (en pesos)',
			premio_dolares: 'Premio facturado y comisiones: totales por mes (en dolares)',
		},
		interanual: {
			header: 'Acumulado interanual (vs año anterior)',
			altas: 'Altas acumuladas (nuevas pólizas + renovaciones)',
			bajas: 'Bajas acumuladas (expiradas + anulaciones)',
			interanual: 'Interanual vs mismo período en año anterior',
			mensual: 'Mensual ({periodo})',
			acumulado: 'Acumulado ({periodo})',
			premio: 'Premio facturado en {currency}',
			comisiones: 'Comisiones percibidas en {currency}',
		},
		producers: {
			header: 'Por Productor',
			totales: 'Totales',
			acumulados: 'Acumulados por período',
		},
	};
};
