export const emisiones = () => {
	return {
		sideBarTitle: 'Búsqueda de emisiones realizadas',
		title: 'Emisión realizadas desde AdminSE',
		searching: 'Buscando documentación...',
		datatables: {
			columnsHeader: {
				createdAt: 'Fecha creación',
			},
			response: 'Respuesta compañía (en crudo)',
			request: 'Request a la compañía (en crudo)',
		},
	};
};
