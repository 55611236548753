<script setup>
import { ref } from 'vue';

const baseUrl = ref(import.meta.env.VITE_BASE_URL);
</script>

<template>
	<div  style="background-color: #4264BB " 
		class="flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
	>
		<div class="flex flex-column align-items-center justify-content-center m-5">
			<img src="/public/images/new_logo_128.png" alt="Adminse-logo" style="z-index:99999"  class="mb-5 w-5rem" />
			<div class="grid flex flex-column align-items-center">
				<Card
					class="flex align-items-center m-2 py-5 px-2 sm:px-8 flex flex-column align-items-center shadow-3"
					style="z-index:99999 !important; border-radius: 55px"
				>
					<template #header>
						<div class="flex justify-content-center align-items-top">
							<div
								class="flex justify-content-center align-items-center bg-blue-600 border-circle"
								style="width: 3.2rem; height: 3.2rem;"
							>
								<i class="text-80 pi pi-fw pi-lock text-2xl text-white"></i>
							</div>
						</div>
						<h1 class="text-gray-900 text-bold text-2xl text-center mt-4">{{ $t('unauthorized.title') }}</h1>
					</template>
					<template #content>
						<div class="m-3">
							<p class="text-sm text-gray-700">{{ $t('unauthorized.loginPrompt') }} <a class="text-blue-600 text-bold text-md no-underline hover:underline  uppercase" :href="baseUrl + '/users/sign_in'"> click aquí</a></p>
							<!--p class="text-sm text-gray-700">{{ $t('unauthorized.updatePrompt') }}</p-->
						</div>
					</template>
				</Card>
			</div>
		</div>
	</div>
</template>
