import { labelizer } from '@service/i18nService';

export const resetAllFilters = (filters) => {
	Object.keys(filters.value).forEach((key) => {
		filters.value[key].value = null;
		filters.value[key].applied = false;
	});
};

/**
 * Esta función se encarga de crear un filtro.
 * @param {string} property - El nombre de la property.
 * @param {string} type - Tipo del dato filtrado.
 * @param {string} operator - Operador utilizado para el filtro.
 * @param {string} labelId - id del label del i18n.
 * @param {any} value - Valor del filtro.
 */
export const createFilter = (property, type, operator, labelId, value = null) => ({
	property,
	value,
	type,
	operator,
	applied: false,
	label: labelizer(labelId),
});
