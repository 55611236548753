import { createI18n, useI18n } from 'vue-i18n';
import { es } from './es/es';
import { ref } from 'vue';

//const { t, locale } = useI18n({ useScope: 'global' })
//import { locale } from './localeService';
//console.log(locale)

//export const locale = ref('es-ar')
// Si cambia el locale al momento de levantar la partnership, me imagino
// que habra que hacer algo como i18n.setLocale(locale) porque esto ya esta creado

const i18n = createI18n({
	allowComposition: true,
	legacy: false,
	// default locale
	locale: 'es-ar',
	fallbackLocale: 'es-ar',
	// translations
	messages: {
        'es-uy': es(),
		en: {},
		'es-ar': es(),
	},
});

export default i18n;
