export function useToTitleCase(str) {
    try{
        if (str){
            str = useToLowerCase(str).split(' ');
            for (var i = 0; i < str.length; i++) {
                str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
            }
            return str.join(' ');
        }
    } catch (e){
        console.log("useToTitleCase error: no es string.")
    }
};

export function useToLowerCase(input){
    if ((typeof input === 'string') || (input instanceof String))
        return input.toLowerCase()
    else
        return input
}

export function useEllipsis(str, n){
    if (str)
        return (str.length > n) ? str.slice(0, n-1) + '…' : str;
    else return str
}

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};