
export const comisiones = () => {
    return {
        comisiones: 'Comisiones',
        esquema: 'Esquema comisionable de esta póliza',
        nominales: 'Comisiones nominales de esta póliza',
        editar: 'Editar comisión',
        borrar: 'Eliminar comisión',
        borrar_esquema: 'Eliminar esquema',
        editar_esquema: 'Editar esquema',
        destinatario: {
            Producer: 'Productor',
            Organizer: 'Organizador',
            Organization: 'Organización',
            Employee: 'Empleado/Datero'
        },
        disabled_from_pp: 'Sólo puede modificar esquemas comisionables desde la Edición de Póliza',
        liquidada: 'Liquidada',
        sin_liquidar: 'Sin liquidar',
        add_comision: 'Nueva comisión manual',
        edit_comision: 'Editar comisión',
        add_manual: 'Agregar comisión manual',
        total_entrante: 'Importe total (entrante)',
        total_saliente: 'Importe total (saliente)',
        sobre_prima: '% sobre Prima',
        sobre_premio: '% sobre Premio',
        nominal_calculada: 'Nominal (calculada)',
        ayuda: '% de ayuda o sobre comisión del PAS',
        comision_sobre_premio: 'Comisión sobre premio {nombre}',
        comision_sobre_prima: 'Comisión sobre prima {nombre}',
        comision_ayuda: 'Comisión ayuda o sobre % del PAS {nombre}',
        edit_esquema: 'Editar esquema comisionable de esta póliza',
        schema_not_empty: 'Se eliminó a {nombre} de la póliza y se quitó su esquema comisionable',
        schema_available: 'El intermediario {nombre} tiene comisiones predefinidad para el código de productor de esta póliza. Lo podés agregar desde el listado de Intermediarios.'
    }
}