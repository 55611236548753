export const cobrados_cliente = () => {
	return {
		toast: {
			error: {
				summary: 'Error',
				detail: 'Error buscando pagos',
			},
		},
		contextMenu: {
			ver_asegurado: 'Ver / Editar Asegurado',
		},
		sideBarTitle: 'Búsqueda de Cobranzas Realizadas',
		title: 'Cta Cte - Pagos Recibidos del Cliente (gestión personal)',
		importe: 'Subtotal importe:',
	};
};
