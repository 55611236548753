export const adjuntos = () => {
	return {
        has_attachments: 'Tiene archivos adjuntos',
        upload: {
            toast: {
                success: {
                    summary: 'Archivo subido',
                },
                error: {
                    summary: 'Error',
                    detail: 'Error subiendo adjunto',
                }
            }
        },
        delete: {
            toast: {
                success: {
                    summary: 'Archivo eliminado',
                },
                error: {
                    summary: 'Error',
                    detail: 'Error eliminando adjunto',
                }
            }
        },
        dragdrop: 'Arrastrar archivos para adjuntar, o seleccionar desde el boton "Adjuntar archivo".',
        chooseLabel: 'Adjuntar archivo',
        chooseDocument: 'Adjuntar documento',
        badge: 'Subido',
        tooltips: {
            eliminar: 'Eliminar Adjunto',
            descargar: 'Descargar Adjunto',
        }
    };
};
