import { pendientes } from './reportes/emisiones/pendientes';
import { realizadas } from './reportes/emisiones/realizadas';
import { refacturaciones } from './reportes/refacturaciones';
import { renovaciones } from './reportes/renovaciones';
import { unidades } from './reportes/unidades';
import { vigentes } from './reportes/vigentes';
import { reportes_descargados } from './reportes/reportes_descargados';

export const reportes = () => {
	return {
		renovaciones: renovaciones(),
		refacturaciones: refacturaciones(),
		emisiones: {
			pendientes: pendientes(),
			realizadas: realizadas(),
		},
		vigentes: vigentes(),
		unidades: unidades(),
		reportes_descargados: reportes_descargados(),
	};
};
