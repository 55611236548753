export const novedades = [
	{
		id: 1,
		title: 'Configurar tamaño',
		text: [
			'Desde el menú superior, en el ícono <b><i class="pi pi-cog"></i></b>, podés modificar el tamaño de los elementos del sistema',
			'Configuralo en el tamaño que mejor te resulte, el cambio se mantiene la próxima vez que ingreses al sistema.',
		],
	},
	{
		id: 2,
		title: 'Interfaz con La Caja',
		text: ['Automática via webservice','Ingresando en Importaciones ya podés configurarla.'],
		show_until: '12/06/2024',
	},
    {
		id: 3,
		title: 'Importación Cosena',
		text: ['Ya podés cargar arhivos de datos de Cosena para importaciones masivas'],
		show_until: '09/06/2024',
	},
    {
		id: 4,
		title: 'Recotización de automotores',
		text: [
            'En pólizas de automotor, ya podés recotizar con click derecho -> Acciones -> Recotizar.',
            'El sistema accede al cotizador con los datos del cliente y el vehículo precargados para la cotización.'
        ],
		show_until: '10/06/2024',
	},
    {
		id: 5,
		title: 'Cotizador - Items en coberturas',
		text: [
            'Ya podés definir items en las coberturas de los resultados del cotizador.',
            'Esto luego se usa para imprimir un presupuesto mas ordenado enumerando los riesgos que se cubren.'
        ],
		show_until: '10/07/2024',
	},
    {
		id: 6,
		title: 'Importación - Berkley ART',
		text: [
            'Rehabilitada la interfaz via archivo de Berkley ART',
            'En la imágen de ayuda se muestran los pasos para generarlo.'
        ],
		show_until: '17/08/2024',
	},
    {
		id: 7,
		title: 'Descarga Documentos - Galeno',
		text: [
            'Ya podés descargar documentación oficial en pólizas de Galeno patrimoniales.',
            'Dentro del menú de las pólizas encontrarás las opciones correspondientes.'
        ],
		show_until: '20/09/2024',
	},
    {
		id: 8,
		title: 'Descarga Documentos - ATM',
		text: [
            'Ya podés descargar documentación oficial en pólizas de ATM',
            'Dentro del menú de las pólizas encontrarás las opciones correspondientes.'
        ],
		show_until: '22/09/2024',
	},
    {
		id: 9,
		title: 'Descarga Documentos - Experta',
		text: [
            'Ya podés descargar documentación oficial en pólizas de Experta',
            'Dentro del menú de las pólizas encontrarás las opciones correspondientes.'
        ],
		show_until: '22/09/2024',
	},
    {
		id: 10,
		title: 'Descarga Documentos - SMG y Rivadavia',
		text: [
            'Ya podés descargar documentación oficial en pólizas de SMG y Rivadavia',
            'Dentro del menú de las pólizas encontrarás las opciones correspondientes.'
        ],
		show_until: '24/09/2024',
	},
    {
		id: 11,
		title: 'Emisión Meridional',
		text: [
            'Ya podés emitir pólizas de Meridional desde el cotizador',
            'También podés descargar la documentación de la póliza luego de emitida.'
        ],
		show_until: '20/11/2024',
	},
    {
		id: 12,
		title: 'Emisión Mercantil',
		text: [
            'Ya podés emitir pólizas de Mercantil desde el cotizador',
            'Si la emisión es exitosa, se descarga el certificado al instante.'
        ],
		show_until: '20/11/2024',
	}
];

export default novedades;
