export const fields = () => {
	return {
        prioridad: {
            alta: 'Prioridad Alta',
            media: 'Prioridad Media',
            baja: 'Prioridad Baja'
        },
        referencia: {
            creado: 'Creado el {fecha}'
        }
	};
};
