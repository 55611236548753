export const sent_mails = () => {
	return {
		toast: {
			error: {
				summary: 'Error',
				detail: 'Error buscando mails enviados',
			},
		},
		sideBarTitle: 'Búsqueda de Mails Enviados',
		title: 'Mails Enviados',
	};
};
