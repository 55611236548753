export const cotizacionAddEdit = () => {
	return {
		toast: {
			success: {
				summary: 'Cotización guardada',
				detail: 'La cotización ha sido guardada correctamente',
			},
		},
		legends: {
			cotizacion: 'Cotización Actual',
			cotizacionPropia: 'Cotización propia',
		},
		labels: {
			actualCompany: 'Compañía actual',
			alicuota: 'Alicuota actual',
			monto: 'Monto fijo actual',
			feep: 'FEEP',
		},
		buttons: { add: 'Agregar cotización propia' },
	};
};
