<script setup>
import AvatarCompanies from '@components/shared/AvatarCompanies.vue';
import { defaultFilter, resultKey, resourceName } from '@service/ProducerCodeService';

import AutocompleteBase from './AutocompleteBase.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
	label: {
		type: String,
		required: false,
	},
	/*companyId: {
		type: Number,
		required: false,
	},*/
    extraFilters: {
        type:Object,
        required: false
    },
});

const labelWithDefault = props.label || t('shared.labels.productorCodigo');

const emit = defineEmits('update:modelValue');

const emitOnlyOnSelect = (event) => {
	if (event) emit('update:modelValue', event);
};

const extrastring = (option) => {
    if (option.description)
	    return option.codigo+" - "+option.description
    else 
        return option.codigo
};


</script>

<template>
	<AutocompleteBase
		field="codigo"
		:resourceName="resourceName"
        :extraFilters="extraFilters?? null"
		:defaultFilter="defaultFilter"
		:resultKey="resultKey"
		:label="labelWithDefault"
		v-bind="$attrs"
		@update:modelValue="emitOnlyOnSelect"
	>
        <template #option="slotProps">
            <div>
                <AvatarCompanies
                    :company="slotProps.option.company"
                    :extraString="extrastring(slotProps.option)"
                />
            </div>
        </template>
    </AutocompleteBase>
</template>
