export const realizados = () => {
	return {
		toast: {
			error: {
				summary: 'Error',
				detail: 'Error buscando pagos realizados',
			},
		},
        en_liquidacion: 'En liquidación {numero}',
		sideBarTitle: 'Búsqueda de Pagos Realizados a la Compañía',
		title: 'Pagos a la Compañía - Realizados',
		labels: { importe: 'Subtotal importe' },
	};
};
