export const renovaciones = () => {
	return {
		title: 'Renovaciones', 
		searchTitle: 'Búsqueda de Renovaciones',
		filters: {
			vigencia: {
				gte: 'Renovaciones Desde',
				lte: 'Renovaciones Hasta',
			},
			estado: 'Buscar en Pólizas',

		}
	};
};
