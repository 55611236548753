import { ref } from 'vue';
import { useGetAll, useGet, usePost, usePut } from '@service/AxiosBaseService';


export const resourceName = 'api/import_error_reports';
export const resultKey = 'import_error_report';

export function saveErrorReport(er){
    if (er.ramo)
        er.ramo_id = er.ramo.id
    return usePost(`${resourceName}`, {import_error_report:er});
}

export const converter_send_zero = (filter) => {
	switch (filter.property) {
		case 'reviewed':
            if (filter.value === false) filter.value = 0;
            break;
	}
};

export const import_error_filters = ref({
    'error_message': { property: 'error_message', value: '', type: 'string', operator: 'like', label:'Error' },
    'numero_poliza': { property: 'numero_poliza', value: '', type: 'string', operator: 'like', label:'Póliza/Endoso' },
    'level': { property: 'level', value: null, type: 'combo', operator: 'eq', label:'Operación' },
    'reviewed': { property: 'reviewed', value: null, type: 'bool', operator: 'eq', label:'Revisado' }
})

export function translateOperationLevel(level){
    return tipo_operations.value.find((to) => to.id == level)?.descripcion || level
}

export const tipos_reviewed = ref([
    {id: 1, descripcion:'Si'},
    {id: 0, descripcion:'No'},
])

export const tipo_operations = ref([
    {id: 'operation', descripcion:'Operación'},
    {id: 'payment', descripcion:'Pago'},
    {id: 'payment_concept', descripcion:'Plan de pago(cuota)'},
    {id: 'commission', descripcion:'Comisión'},
    {id: 'day_movements', descripcion:'Movimientos diarios'},
])

export const error_report_statuses = ref([
    {id: 'pendiente', descripcion:'Pendiente', color:'yellow'},
    {id: 'en_revision', descripcion:'En proceso de revisión', color:'blue' },
    {id: 'terminada', descripcion:'Terminado', color:'green' },
])

export function getErrorReportStatus(status_id){
    return error_report_statuses.value.find((is) => is.id == status_id)
}

export const revision_statuses = ref([
    {id: 'error_adminse', descripcion:'Error en el sistema confirmado', color:'yellow'},
    {id: 'error_cia', descripcion:'Error en servicio de la compañía', color:'green' },
    {id: 'error_usuario_busqueda_poliza', descripcion:'La póliza estaba en el sistema', color:'green' },
    {id: 'error_usuario_revision_archivo', descripcion:'La póliza no estaba en el archivo', color:'green' },
    {id: 'error_usuario_import_period', descripcion:'Período de importación erróneo', color:'green' },
])

export function getRevisionStatus(status_id){
    return revision_statuses.value.find((is) => is.id == status_id)
}
