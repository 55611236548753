export const send_documents = () => {
	return {
		estados: {
			todas: 'Todas',
			todas_excepto_anuladas: 'Todas (excepto anuladas)',
			solo_vigentes: 'Sólo vigentes',
			todas_renovadas: 'Todas las NO renovadas',
			todas_renovadas_anuladas: 'Todas las NO renovadas (incluidas las anuladas)',
		},
		toast: {
			error: {
				summary: 'Error',
				detail: 'Error buscando pólizas',
			},
		},
		denominacion: {
			individual: 'Vida individual',
			obligatorio: 'Vida Obligatorio (Decreto 1567/74)',
			colectivo: 'Vida colectivo',
			constitucion: 'Vida – Sin constitución de reservas Matemáticas',
			comercio: 'Integram de comercio',
			consorcio: 'Integram de consorcio',
			combinado: 'Combinado Familiar',
			integrales: 'Combinados e Integrales',
			robo_riesgos: 'Robo y Riesgos Similares',
			riesgos: 'Riesgos Varios',
			civil: 'Responsabilidad Civil',
			tecnico: 'Técnico',
			incendio: 'Incendio',
			personales: 'Accidentes Personales',
			pasajeros: 'Accidentes a Pasajeros',
			seguro: 'Seguro de Salud',
		},
		sideBarTitle: 'Búsqueda de Documentos enviados/pendientes',
		buttons: {
			send: 'Enviar Documentos a Clientes',
		},
		divider: {
			costos: 'Costos',
			fechas: 'Fechas',
			suma_extras: 'Suma / Extras',
			intermediarios: 'Intermediarios',
		},
		labels: {
			send_to: 'Enviar a',
			emision: 'Emisión',
			desde: 'Vig. Desde',
			hasta: 'Vig. Hasta',
			refacturacion: 'Refacturación',
			prima: 'Prima',
			premio: 'Premio',
			endorsement: 'Endoso',
			deuda: 'Deuda Exigible',
			vigencia: 'Vigencia',
			moneda: 'Moneda del contrato',
			fecha_envio: 'Fecha de envío de la propuesta',
			fecha_recepcion: 'Fecha de recepción de la póliza',
			origen: 'Origen',
			suma_asegurada: 'Suma Asegurada',
			responsables: 'Intermediarios responsables',
			mail_to_send: 'Mail a enviar todos los documentos',
		},
		confirm: {
			header: 'Enviar Documentos',
			message: `
				Se van a enviar por mail los documentos (que haya cargados) de las {cantidad} pólizas del listado.
				<br /><br />
				Se enviarán mails <b>a todos los Clientes de las pólizas del listado</b>
				<br /><br />
				¿Continuar?
			`,
		},
	};
};
