export const organizacion = () => {
	return {
		estructura: {
            code_saved: 'Código creado correctamente',
            code_updated: 'Código actualizado',
			components: {
				organizationProducersTable: {
					menuModel: {
						label: 'Agregar Código de Productor',
					},
					searchSideBar: {
						title: 'Búsqueda de Productor',
						inputs: {
							nombre: 'Nombre del Productor',
						},
					},
					editCodeDialog: {
						label: 'Código de Productor',
					},
					datatable: {
						column: {
							header: 'Productor',
							conAcceso: 'Con acceso al sistema',
							sinAcceso: 'Sin acceso al sistema',
							externo: 'Externo',
						},
					},
				},
				organizationCodes: {
					menuModel: {
						editar: 'Editar Código',
						gestionar: 'Gestionar Comisiones',
						borrar: 'Borrar Código',
					},
					confirm: {
						message:
							'¿Está seguro que desea eliminar el código {codigo} y sus acuerdos comerciales del sistema?',
					},
					editCodeDialog: {
						label: 'Código',
					},
					datatable: {
						columns: {
							headers: {
								codigo: 'Código Organizador',
								acuerdos: 'Acuerdos Comerciales Generales',
							},
						},
					},
				},
				dialogs: {
					agreementColumn: {
						premio: 'sobre premio',
						prima: 'sobre prima',
						extra: 'de ayuda/extra',
						ramo: 'en Ramo {denominacion}',
					},
					editAgreementDialog: {
						header: 'Comisiones para {name} - Código: {codigo}',
						tabs: {
							header: {
								generales: 'Comisiones de Organizador Generales',
								especificos: 'Comisiones de Organizador para Productores Específicos',
							},
						},
					},
					editCodeDialog: {
						header: 'Agregar Código de Productor a {nombre}',
                        toOrgaHeader: 'Agregar Código de Productor a {nombre} para la organización {orga}',
					},
					editSpecificAgreementsDataTable: {
						header: 'Acuerdo Productor Código:',
						button: 'Nuevo Acuerdo por Productor en Código de Organización',
						menuModel: {
							editar: 'Editar Acuerdo Comercial',
							borrar: 'Borrar Acuerdo Comercial'
						},
						columns: {
							codigo: 'Código Organizador',
							acuerdos: 'Acuerdos Comerciales Generales'
						}
					}
				},
			},
			title: 'Mi Árbol de Organizaciones y Códigos',
			addOrganizationTitle: 'Nueva Organización',
			addSubOrganizationTitle: 'Nueva Organización - SubOrganización de {organization}',
			addOrganizationCodeTitle: 'Nuevo Código de Organizador',
			dialogs: {
				autocomplete: {
					labels: {
						companies: 'Compañía',
						producer: 'Usuario Organizador',
					},
				},
				codigo: 'Código',
				addExternalProducer: {
                    external_creado:'Productor externo creado',
                    ingresar_codigo: 'Debe cargar código de Organización',
					header: 'Nuevo Productor Externo',
					nombre: 'Nombre / Razón social',
					codigo: 'Código de Productor',
					matricula: 'Matrícula',
					tipoPersona: 'Tipo Persona',
					error: 'Error al agregar productor externo.',
				},
			},
			menuModel: {
				addSubOrganization: 'Agregar Sub Organización',
				addOrganizationCode: 'Nuevo código de Organizador',
				linkProducerToOrganization: 'Vincular Productor Interno a la Organización',
				addExternalProducer: 'Crear Nuevo Productor Externo y asociar a la Organización',
				addProducerCodeToOrganization: 'Nuevo código de Productor en Organización',
			},
			datatable: {
				header: {
					add: 'Nueva Organización (primer nivel)',
				},
			},
			loadOrganizationTree: {
				toast: {
					error: {
						summary: 'Error',
						detail: 'Error buscando estructura',
					},
				},
			},
			confirmProducerLink: {
				confirm: {
					message: '¿Agregar a {producer} a la Organización {organization}?',
				},
			},
			tabPanels: {
				headers: {
					codigosOrganizaciones: 'Códigos de Organizaciones',
					productores: 'Productores',
					codigosProductores: 'Códigos de Productores',
				},
			},
		},
		productoresTodos: {
			title: 'Productores de mi Organización (Todos)',
		},
	};
};
