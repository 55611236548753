export const robo = () => {
	return {
		title: 'Robo',
		labels: {
			accion: 'Acción',
			codigo_tipo_vivienda: 'Código Vivienda',
			numero_item: 'Número Item',
			tipo_vivienda: 'Tipo Vivienda',
			codigo_categoria: 'Código Categoría',
			codigo_contenido: 'Código Contenido',
			codigo_medidas_seguridad: 'Código Medidas de Seguridad',
			contenido: 'Contenido',
			medida_seguridad: 'Medida de Seguridad',
			codigo_ocupacion: 'Código Ocupación',
			codigo_dinero_caja: 'Código Dinero en Caja',
			ocupacion: 'Ocupación',
			dinero_caja: 'Dinero en Caja',
			codigo_tipo_objeto: 'Código Tipo Objeto',
			tipo_objeto: 'Tipo Objeto',
		},
	};
};
