import { ref } from 'vue';
import { useGetAll } from '@service/AxiosBaseService';
import { country } from '../i18n/localeService';
import { getCiaLogoByCodigo } from '@service/import/ImportsService';

export const resourceName = '/api/productions/companies';
export const resultKey = 'company';

export const allCompanies = ref([]);

export const filters = ref({
	denominacion: { property: 'denominacion', value: null, type: 'string', operator: 'like' },
});

export const defaultFilter = {
	denominacion: { property: 'denominacion', value: null, type: 'string', operator: 'like' },
};

export const artCompanies = ref([]);

export function getArtCompanies() {
	useGetAll(
		{ rows: 1000, filters: { is_art: { property: 'is_art', value: 1, type: 'combo', operator: 'eq' } } },
		'/api/companies'
	)
		.then((response) => {
			artCompanies.value = response.data.company;
		})
		.catch(() => {
			//toast.add({ severity: 'error', summary: 'Error', detail: 'Error buscando Compañías', life: 3000 });
		});
}

export function getAllCompanies() {
    //console.log(country.value)
    useGetAll({ filters: { tipo_pais: { property: 'tipo_pais', value: country.value, type: 'string', operator: 'like' } }, rows: 1000 }, '/api/companies')
		.then((response) => {
			allCompanies.value = response.data.company;
		})
		.catch(() => {
			//toast.add({ severity: 'error', summary: 'Error', detail: 'Error buscando Compañías', life: 3000 });
		});
}

export function getCompanyIdByCodigo(codigo) {
    return allCompanies.value.find((cc) => cc.codigo == codigo)?.id;
}

export function getCompanyValueByCodigo(codigo) {
	try {
		return ciasCodigos.find((cc) => cc.codigo == codigo).value;
	} catch (err) {
		console.log('company codigo missing: ' + codigo);
	}
}

export function getCiaLogo(company) {
    let logo = getCiaLogoByCodigo(company.codigo) 
    if (!logo){
        //console.log(ciasCodigos.find((cc) => cc.codigo == company.codigo))
        const thumb = ciasCodigos.find((cc) => cc.codigo == company.codigo)?.thumb
        logo = thumb? '/images/cias/' + thumb : null
    }
    return logo

}

// Lo separo porque en el legacy voy a seguir toqueteando el importsConfig
export const ciasCodigos = [
	{
		codigo: '0275',
		company_id: 11,
		title: 'Ace',
		value: 'ace',
	},
	{
		codigo: '0011',
		title: 'Comercio',
		value: 'comercio',
	},
	{
		codigo: '0297',
		company_id: 78,
		title: 'Acg',
		value: 'acg',
	},
	{
		company_id: 17,
		codigo: '0755',
		title: 'Afianzadora',
		value: 'afianzadora',
	},
	{
		company_id: 24,
		codigo: '0389',
		title: 'Agrosalta',
		value: 'agrosalta',
	},
	{
		company_id: 26,
		codigo: '0329',
		title: 'Alba',
		value: 'alba',
	},
	{
		company_id: 34,
		codigo: '0036',
		title: 'Allianz',
		value: 'allianz',
	},
	{
		company_id: 64,
		codigo: '0246',
		title: 'Antartida',
		value: 'antartida',
	},
	{
		company_id: 97,
		codigo: '0620',
		title: 'Asociart',
		value: 'asociart',
	},
	{
		codigo: '0823',
		title: 'Asociart RC',
		value: 'asociart_rc',
	},
	{
		company_id: 99,
		codigo: '0771',
		title: 'Assekuransa',
		value: 'assekuransa',
	},
	{
		company_id: 88,
		codigo: '0812',
		title: 'ATM',
		value: 'atm',
	},
	{
		company_id: 125,
		codigo: '0396',
		title: 'BBVA',
		value: 'bbva',
	},
	{
		company_id: 127,
		codigo: '0555',
		title: 'Beneficio',
		value: 'beneficio',
	},
	{
		company_id: 132,
		codigo: '0121',
		title: 'Berkley',
		value: 'berkley',
	},
	{
		company_id: 131,
		codigo: '0624',
		title: 'Berkley ART',
		value: 'berkley_art',
	},
	{
		company_id: 140,
		codigo: '0032',
		title: 'Boston',
		value: 'boston',
	},
	{
		company_id: 90,
		codigo: '0415',
		title: 'Aseguradores de Caucion',
		value: 'Aseguradores de Caucion',
	},
	{
		company_id: 159,
		codigo: '0057',
		title: 'Caledonia',
		value: 'caledonia',
	},
	{
		company_id: 183,
		codigo: '0581',
		title: 'Chubb',
		value: 'chubb',
	},
	{
		company_id: 247,
		codigo: '0196',
		title: 'Cooperacion Mutual Patronal',
		value: 'Cooperacion Mutual Patronal',
	},
	{
		company_id: 263,
		codigo: '0248',
		title: 'Copan',
		value: 'copan',
	},
	{
		company_id: 271,
		codigo: '0656',
		title: 'Créd. y Cauc.',
		value: 'Créd. y Cauc.',
	},
	{
		company_id: 614,
		codigo: '0744',
		title: 'El Noble',
		value: 'el_noble',
	},
	{
		company_id: 221,
		codigo: '0141',
		title: 'El Norte',
		value: 'norte',
	},
	{
		company_id: 314,
		codigo: '0746',
		title: 'Escudo',
		value: 'escudo',
	},
	{
		company_id: 470,
		codigo: '0236',
		title: 'Evolución',
		value: 'evolución',
	},
	{
		company_id: 323,
		codigo: '0616',
		title: 'Experta ART',
		value: 'Experta ART',
	},
	{
		company_id: 896,
		codigo: '0622',
		title: 'Experta',
		value: 'experta',
	},
	{
		company_id: 741,
		codigo: '0749',
		title: 'Seguros Medicos',
		value: 'Seguros Medicos',
	},
	{
		company_id: 339,
		codigo: '0726',
		title: 'Federación Patronal',
		value: 'fedpat',
	},
	{
		codigo: '0128',
		title: 'Federación Patronal C.S.L.',
		value: 'fed pat csl',
	},
	{
		company_id: 343,
		codigo: '0253',
		title: 'Fianzas y Crédito',
		value: 'Fianzas y Crédito',
	},
	{
		company_id: 352,
		codigo: '0867',
		title: 'Woranz',
		value: 'Woranz',
	},
	{
		company_id: 356,
		codigo: '0619',
		title: 'Galeno ART',
		value: 'Galeno ART',
	},
	{
		company_id: 357,
		codigo: '0878',
		title: 'Galeno',
		value: 'galeno',
	},
	{
		company_id: 380,
		codigo: '0882',
		title: 'Gestion',
		value: 'Gestion',
	},
	{
		company_id: 392,
		codigo: '0335',
		title: 'HDI',
		value: 'hdi',
	},
	{
		company_id: 491,
		codigo: '0079',
		title: 'La Holando',
		value: 'holando',
	},
	{
		company_id: 400,
		codigo: '0261',
		title: 'Horizonte',
		value: 'horizonte',
	},
	{
		company_id: 436,
		codigo: '0720',
		title: 'Integrity',
		value: 'integrity',
	},
	{
		company_id: 223,
		codigo: '0780',
		title: 'Insur',
		value: 'Insur',
	},
	{
		company_id: 152,
		codigo: '0501',
		title: 'La Caja',
		value: 'caja',
	},
	{
		company_id: 480,
		codigo: '0044',
		title: 'La Equitativa',
		value: 'equitativa',
	},
	{
		company_id: 604,
		codigo: '0515',
		title: 'Nación',
		value: 'nacion',
	},
	{
		company_id: 505,
		codigo: '0163',
		title: 'La Nueva',
		value: 'la_nueva',
	},
	{
		company_id: 528,
		codigo: '0117',
		title: 'La Segunda Personas',
		value: 'La Segunda Personas',
	},
	{
		company_id: 530,
		codigo: '0436',
		title: 'La Segunda Retiro',
		value: 'La Segunda Retiro',
	},
	{
		company_id: 529,
		codigo: '0317',
		title: 'La Segunda',
		value: 'segunda',
	},
	{
		company_id: 544,
		codigo: '0852',
		title: 'Libra',
		value: 'libra',
	},
	{
		company_id: 546,
		codigo: '0352',
		title: 'Liderar',
		value: 'liderar',
	},
	{
		company_id: 567,
		codigo: '0213',
		title: 'Mapfre',
		value: 'mapfre',
	},
	{
		company_id: 566,
		codigo: '0699',
		title: 'Mapfre vida',
		value: 'Mapfre vida',
	},
	{
		company_id: 226,
		codigo: '0116',
		title: 'Mercantil Andina',
		value: 'mercantil',
	},
    {
		company_id: 908,
		codigo: '0830',
		title: 'CAS',
		value: 'cas',
        thumb: 'cas_60.png'
	},
	{
		codigo: '0244',
		value: 'meridional',
		title: 'Meridional',
	},
	{
		codigo: '0251',
		value: 'life',
		title: 'Life',
	},
	{
		codigo: '0247',
		value: 'nativa',
		title: 'Nativa',
	},
	{
		codigo: '0662',
		value: 'nivel',
		title: 'Nivel',
	},
	{
		codigo: '0862',
		value: 'omint',
		title: 'Omint',
	},
	{
		codigo: '0293',
		value: 'orbis',
		title: 'Orbis',
	},
	{
		codigo: '0330',
		value: 'parana',
		title: 'Parana',
	},
	{
		codigo: '0895',
		value: 'pacifico',
		title: 'Pacífico',
	},
	{
		codigo: '0157',
		value: 'perseverancia',
		title: 'Perseveranc.',
	},
	{
		codigo: '0626',
		value: 'prevencion',
		title: 'Prevención',
	},
	{
		codigo: '0409',
		value: 'previnca',
		title: 'Previnca',
	},
	{
		codigo: '0348',
		value: 'prof',
		title: 'Prof',
	},
	{
		codigo: '0528',
		cia_id: '7777',
		value: 'providencia',
		title: 'Providencia',
	},
	{
		codigo: '0499',
		value: 'provincia',
		title: 'Provincia',
	},
	{
		codigo: '0621',
		value: 'provincia art',
		title: 'Prov. ART',
	},
	{
		codigo: '0040',
		value: 'prudencia',
		title: 'Prudencia',
	},
	{
		codigo: '0861',
		value: 'colon',
		title: 'Colon',
	},
	{
		codigo: '0039',
		value: 'qbe',
		title: 'QBE',
	},
	{
		codigo: '0222',
		value: 'rivadavia',
		title: 'Rivadavia',
	},
	{
		codigo: '0847',
		value: 'sangerman',
		title: 'San German',
	},
	{
		codigo: '0360',
		value: 'rus',
		title: 'Río Uruguay Seguros',
	},
	{
		codigo: '0224',
		value: 'sancor',
		title: 'Sancor',
	},
	{
		codigo: '0192',
		value: 'sancris',
		title: 'San Cristobal',
	},
	{
		codigo: '0286',
		value: 'seguro metal',
		title: 'Seguro Metal',
	},
	{
		codigo: '0002',
		value: 'smg',
		title: 'SMG',
	},
	{
		codigo: '0784',
		value: 'smg art',
		title: 'SMG ART',
	},
	{
		codigo: '0580',
		value: 'smglife',
		title: 'SMG Life',
	},
	{
		codigo: '0025',
		value: 'sura',
		title: 'Galicia',
	},
	{
		codigo: '0402',
		value: 'triunfo',
		title: 'Triunfo',
	},
	{
		codigo: '0741',
		value: 'tpc',
		title: 'TPC',
	},
	{
		codigo: '0841',
		value: 'tutelar',
		title: 'Tutelar',
	},
	{
		codigo: '0064',
		value: 'victoria',
		title: 'Victoria',
	},
	{
		codigo: '0228',
		value: 'zurich',
		title: 'Zurich',
	},
	{
		codigo: '0931',
		value: 'premiar',
		title: 'Premiar',
	},
    {
		codigo: 'GYG1',
		value: 'bse',
		title: 'BSE',
        thumb: 'bse-60.png',
	},
    {
		codigo: 'GYG2',
		value: 'porto',
		title: 'Porto Seguro',
        thumb: 'porto-60.png',
	},
    {
		codigo: 'GYG3',
		value: 'mapfre',
		title: 'Mapfre',
        thumb: 'mapfre_60.png',
	},
    {
		codigo: 'GYG4',
		value: 'sura',
		title: 'Sura',
        thumb: 'sura-60.png',
	},
    {
		codigo: 'GYG7',
		value: 'surco',
		title: 'Surco',
        thumb: 'surco-60.png',
	},
    {
		codigo: 'GYG13',
		value: 'sbi',
		title: 'SBI',
        thumb: 'sbi-60.png',
	},
    {
		codigo: 'GYG14',
		value: 'hdi',
		title: 'HDI',
        thumb: 'hdi-60.png',
	},
    {
		codigo: 'GYG22',
		value: 'berkley',
		title: 'Berkley',
        thumb: 'berkley-60.png',
	},
    {
		codigo: 'GYG16',
		value: 'metlife',
		title: 'Metlife',
        thumb: 'metlife-60.png',
	},
    {
		codigo: 'GYG20',
		value: 'sancor',
		title: 'Sancor',
        thumb: 'sancor-60.png',
	},
    {
		codigo: 'GYG24',
		value: 'uyasist',
		title: 'Uy Asist',
	},
    {
		codigo: 'GYG25',
		value: 'sancris',
		title: 'San Cristóbal',
        thumb: 'sancristobal-60.png',
	},
    {
		codigo: 'GYG26',
		value: 'coris',
		title: 'Coris',
        thumb: 'coris-60.png',
	},
    {
		codigo: 'GYG27',
		value: 'universal',
		title: 'Universal Assistance',
        thumb: 'universal-60.png',
	},
    {
		codigo: 'GYG28',
		value: 'barbuss',
		title: 'Barbuss',
        thumb: 'barbuss-60.png',
	},
    {
		codigo: 'GYG30',
		value: 'bcbs',
		title: 'BCBS',
        thumb: 'bcbs-60.png',
	},
    {
		codigo: 'GYG31',
		value: 'urban',
		title: 'Urban',
        thumb: 'urban-60.png',
	},
    
    
];
