<script setup>
import { useI18n } from 'vue-i18n';
import { getCurrentInstance, ref } from 'vue';

const { t } = useI18n();

//const expandedRows = ref(null)

const props = defineProps({
	expandedRows: { type: [Array, Object] },
	value: { type: [Object, null], required: true },
	selection: { Type: Object, required: false },
	contextMenu: { type: Boolean, required: false, default: true },
	ref: { type: String, required: false },
	columns: { type: Object, required: false },
	paginator: { type: Boolean, required: false, default: true },
	rows: { type: Number, required: false, default: 10 },
	lazy: { type: Boolean, required: false, default: true },
	columnResizeMode: { type: String, required: false, default: 'expand' },
	clase: { type: String, required: false, default: 'p-datatable-gridlines' },
	totalRecords: { type: Number, required: false },
	responsiveLayout: { type: String, required: false, default: 'scroll' },
	rowClass: { type: Function || String, required: false },
	dataKey: { type: String, required: false, default: 'id' },
	rowHover: { type: Boolean, required: false, default: true },
	rowsPerPageOptions: { type: Array, required: false, default: [10, 20, 50, 100] },
	paginatorTemplate: {
		type: String,
		required: false,
		default:
			'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
	},
	currentPageReportTemplate: {
		type: String,
		required: false,
	},
	scrollable: { type: Boolean, required: false, default: true },
});

const currentPageReport =
	props.label ||
	t('shared.datatables.currentPageReportTemplate', {
		first: '{first}',
		last: '{last}',
		totalRecords: '{totalRecords}',
	});

const emits = defineEmits([
	'rowExpand',
	'rowContextmenu',
	'page',
	'gearClick',
	'row-click',
	'refresh',
	'update:selection',
	'update:expandedRows',
	'update:contextMenuSelection',
]);
const updateSelection = (newSelection) => emits('update:selection', newSelection);
const updateExpandedRows = (newExpandedRows) => emits('update:expandedRows', newExpandedRows);
const updateContextMenuSelection = (newSelection) => emits('update:contextMenuSelection', newSelection);

const hasEmit = (emit) => {
	return getCurrentInstance()?.vnode.props?.[emit];
};
</script>

<template>
	<DataTable
		:dataKey="dataKey"
    :ref="ref"
    :value="value"
    :expandedRows="expandedRows"
    :selection="selection"
    :contextMenu="contextMenu"
		:rowClass="rowClass"
		:columns="columns"
		:paginator="paginator"
		:rows="rows"
		:lazy="lazy"
    :scrollable="scrollable"
		:columnResizeMode="columnResizeMode"
		:class="clase"
		:totalRecords="totalRecords"
		:responsiveLayout="responsiveLayout"
		:rowHover="rowHover && value?.length > 0"
		:rowsPerPageOptions="rowsPerPageOptions"
		:paginatorTemplate="paginatorTemplate"
		:currentPageReportTemplate="currentPageReport"
		v-bind="$attrs"
		@update:expandedRows="updateExpandedRows"
		@update:selection="updateSelection"
		@rowExpand="$emit('rowExpand', $event)"
		@update:contextMenuSelection="updateContextMenuSelection"
		@row-click="$emit('row-click', $event)"
		@rowContextmenu="$emit('rowContextmenu', $event)"
		@page="$emit('page', $event)"
	>
		<template #header v-if="$slots.header">
			<slot name="header" />
		</template>
		<Column expander style="width: 2rem" v-if="$slots.expander" />
		<slot />
		<Column v-if="hasEmit('onGearClick')" style="width: 40px" frozen alignFrozen="right" :exportable="false">
			<template #body="{ data, index }">
				<Button
					icon="pi pi-cog"
					class="p-button-text p-button-raised p-button-primary"
					@click="$emit('gearClick', $event, data, index)"
				/>
			</template>
		</Column>
		<template #expansion="{ data }" v-if="$slots.expander">
			<slot name="expander" :data="data" />
		</template>
		<template #empty="{ data }">
			<slot name="empty">{{ data ?? t('shared.datatables.empty') }}</slot>
		</template>
		<template #loading>{{ t('shared.datatables.loading') }}</template>
		<template #paginatorstart>
			<slot name="paginatorstart" v-if="hasEmit('onRefresh')">
				<Button type="button" icon="pi pi-refresh" class="p-button-text" @click="emits('refresh')" />
			</slot>
		</template>
		<template #paginatorend>
			<slot name="paginatorend" />
		</template>
		<template #footer v-if="$slots.footer">
			<slot name="footer" />
		</template>
	</DataTable>
</template>
