export const historial = () => {
	return {
		contextMenu: {
			download: 'Descargar archivo de rúbrica',
			delete: 'Borrar archivo (desprocesa todos los registros que contiene)',
		},
		ayudaItems: {
			pendientes: 'Pendientes - Generación de archivo',
			historial: 'Historial de archivos generados',
		},
		toast: {
			success: {
				summary: 'Éxito',
				detail: 'Archivo borrado',
			},
			error: {
				summary: 'Error',
				detail: {
					searching: 'Error buscando rubricas',
					deleting: 'Error borrando archivo',
				},
			},
		},
		confirm: {
			message:
				'Se eliminará el archivo y se marcarán los registros que contiene como no procesados, ¿Confirma?',
			header: 'Borrar Archivo',
		},
		title: 'Rúbrica - Archivos generados',
	};
};
