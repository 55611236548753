import { useGetAll, useGet, usePut } from '@service/AxiosBaseService';
import { getCompanyIdByCodigo } from '@service/CompaniesService';
import { computed, ref } from 'vue';

export const resourceName = 'api/imports';
export const resultKey = 'import';

export async function loadImport(id) {
	const theimport = await useGet(`${resourceName}/${id}`);
    return theimport.data?.import
}

export function freezeImport(id) {
    return useGet(`${resourceName}/${id}/freeze_operations`);
}

export const filters = ref({
	producer: { property: 'producer_id', value: null, type: 'combo', operator: 'eq', label: 'Productor' },
	company: { property: 'company_id', value: null, type: 'combo', operator: 'eq', label: 'Compañía' },
	status: { property: 'status', value: null, type: 'combo', operator: 'eq', label: 'Estado' },
	created_at_gte: {
		property: 'created_at',
		value: null,
		operator: 'gte',
		type: 'date',
		label: 'Fecha ejecución >',
	},
	created_at_lte: {
		property: 'created_at',
		value: null,
		operator: 'lte',
		type: 'date',
		label: 'Fecha ejecución <',
	},
	with_errors: {
		property: 'with_errors',
		value: null,
		type: 'combo',
		operator: 'eq',
		label: 'Tiene errores',
	},
});

export const updateImport = (data) => { return usePut(`${resourceName}/${data.id}`, { import: data }) };

export const error_types = ref([
	{ id: 'connection', descripcion: 'Error de conexión con la compañia' },
    { id: 'incorrect_poc', descripcion: 'Código de productor erróneo' },
	{ id: 'auth', descripcion: 'Error de autenticación / credenciales' },
	{ id: 'missing_company_credentials', descripcion: 'No hay credenciales de acceso' },
    { id: 'missing_company_credentials_data', descripcion: 'No hay información de credenciales de acceso' },
	{ id: 'missing_production', descripcion: 'No hay producción asociada' },
    { id: 'different_headers', descripcion: 'Diferencias en encabezado de archivo' },
    { id: 'unavailable_service', descripcion: 'Servicio no disponible' },
    { id: 'no_data', descripcion: 'Sin datos' },
    { id: 'unknown', descripcion: 'Desconocido' },
]);

export const import_statuses = ref([
	{ id: 'created', descripcion: 'Esperando ejecución', color: 'text-yellow-800' },
	{ id: 'running', descripcion: 'Ejecutando', color: 'text-blue-800' },
	{ id: 'finished', descripcion: 'Terminado', color: 'text-green-800' },
	{ id: 'error', descripcion: 'Error', color: 'text-red-800' },
    { id: 'pending_implementation', descripcion: 'Pendiente de implementación', color: 'text-red-800' },
	{ id: 'destroying', descripcion: 'Eliminando...', color: 'text-blue-800' },
	{ id: 'waiting_for_re_execution', descripcion: 'Esperando reejecución', color: 'text-orange-800' },
	{ id: 'waiting_next_period', descripcion: 'Esperando próximo período', color: 'text-orange-800' },
]);

export function getStatus (status_id) {
	return import_statuses.value.find((is) => is.id == status_id);
}

export async function getLastImportsByProducerAndCompanyCodigo (producer, company_codigo, toast) {
	const ret = await useGetAll(
		{
			first: 0,
			rows: 10,
			filters: {
				producer: {
					property: 'producer_id',
					value: producer.id,
					type: 'combo',
					operator: 'eq',
					label: 'Productor',
				},
				company: {
					property: 'company_id',
					value: getCompanyIdByCodigo(company_codigo),
					type: 'combo',
					operator: 'eq',
					label: 'Compañía',
				},
			},
		},
		resourceName
	);
	return ret.data;
}

export const importsCias = [
	{
		id: 1,
		codigo: '0275',
		thumb: 'ace-60.jpg',
		value: 'ace',
		title: 'Ace',
		allowedExtension: ['xls', 'xlsx'],
		help: {
			html: "<p>El archivo de importaci&oacute;n que usaremos para las <b>emisiones</b> de ACE es un excel (.xls) que descargan de la p&aacute;gina de la compa&ntilde;&iacute;a.</p><p>El mismo debe contener las columnas exactamente como figuran en <a href='"+import.meta.env.VITE_BASE_URL+"workspace/desk/resources/images/help/ace/ace-modelo.png' target='_blank'>el modelo de archivo.</a></p>",
		},
	},
	{
		id: 5,
		codigo: '0297',
		thumb: 'acg-60.png',
		value: 'acg',
		title: 'Acg',
		scrap: true,
		allowedExtension: ['txt', 'xls'],
		help: {
			html: "<p>El archivo de importación que usaremos para ACG es un TXT que la compañía les envía por mail a través de su ejecutivo de cuenta.</p>"+
            "<p>Deben solicitar el archivo de Descarga de novedades que provee la compañía.</p>",
			thumbs: [{ src: 'acg/acg_1.jpeg' }],
		},
	},
	{
		id: 7,
		codigo: '0755',
		thumb: 'afianzadora-60.png',
		value: 'afianzadora',
		title: 'Afianzadora',
		allowedExtension: ['xlsx'],
		help: {
			html: '<p>Ingresan desde la página de Afianzadora a Producción -> Operaciones.</p><p>Luego seleccionan el período y exportan el resultado a Excel.</p>',
			thumbs: [
                { src: 'afianzadora/afianzadora-1.png' },
                { src: 'afianzadora/afianzadora-3.png' }
            ],
		},
	},
	{
		id: 8,
		codigo: '0389',
		thumb: 'agrosalta-60.png',
		value: 'agrosalta',
		title: 'Agrosalta',
		help: {
			html: "<p>Para importar datos de Agrosalta deben ingresar en Agrosalta Web - o directamente: http://201.216.251.171:8090//Default.aspx -, y en el menú 'Cartera' -> 'Consulta Operaciones', ejecutar la búsqueda y exportar los resultados a Excel.</p>",
			thumbs: [{ src: 'agrosalta/agrosalta-1.png' }, { src: 'agrosalta/agrosalta-2.png' }],
		},
		allowedExtension: ['xls', 'xlsx'],
	},
	{
		id: 9,
		codigo: '0329',
		thumb: 'alba-60.png',
		value: 'alba',
		title: 'Alba',
		help: {
			height: 400,
			html: '<p>Se importan archivos en formato Excel con la información de las operaciones. Deben pedir a su ejecutivo que lo envíe por mail.</p>',
		},
		allowedExtension: ['xls', 'xlsx'],
	},
	{
		id: 10,
		codigo: '0036',
		ws_available: true,
		months_available: 120,
		thumb: 'allianz_60.png',
		value: 'allianz',
		title: 'Allianz',
		credentials: {
			fields: [],
		},
		help: {
			video: 'https://player.vimeo.com/video/586929231',
			webservice: true,
			datos_form: true,
			cotizador: true,
			//"html": ""
		},
	},
	{
		id: 15,
		codigo: '0246',
		thumb: 'antartida-60.png',
		value: 'antartida',
		title: 'Antartida',
		help: {
			html:
				'Sólo deben ingresar Usuario y Contraseña del portal de Antártida para configurar la interfaz.<br/>' +
				'Para habiliar el <b>Cotizador</b>, nos deben enviar un mail a integraciones@adminse.com.ar pidiendo la habilitación al cotizador de Antártida, indicándonos tu código de PAS en la compañía.',
			cotizador: {
				help: 'Deben enviar un mail a integraciones@adminse.com.ar pidiendo la habilitación al cotizador de Antártida, indicándonos tu código de PAS en la compañía.',
			},
		},
		ws_available: true,
		months_available: 120,
		force_prod_code: true,
		credentials: {
			web_credentials: true,
			fields: [
				{
					field: 'username',
					label: 'Operador',
				},
				{
					field: 'password',
					label: 'Clave',
				},
			],
		},
	},
	{
		id: 20,
		codigo: '0620',
		thumb: 'asociart-60.png',
		value: 'asociart',
		title: 'Asociart',
		help: {
			html: "Ingresar al portal web de la compañía y seleccionar COMISIONES"+
            "<br>Elegir la opción DETALLE COMISIONES"+
            "<br>Completar los datos del productor/organizador y periodo que se desea importar"+
            "<br>Realizar la busqueda de los datos seleccionados"+
            "<br>Una vez que la pantalla presenta la informacion, clickear el boton de EXPORTAR",
			thumbs: [
				{ src: 'asociart/asociart_1.png' },
                { src: 'asociart/asociart_2.png' },
                { src: 'asociart/asociart_3.png' },
                { src: 'asociart/asociart_4.png' },
			],
		},
		day_prompt: true,
		allowedExtension: ['xls', 'xlsx'],
		cobranzas: {
			allowedExtension: ['xls', 'xlsx'],
		},
	},
	{
		id: 21,
		codigo: '0771',
		thumb: 'assekuransa-60.png',
		value: 'assekuransa',
		title: 'Assekuransa',
		help: {
			html: '<p>Deben solicitar el archivo de Emisiones a la compañía para recibirlo via mail regularmente. Si tenes dudas sobre el formato del archivo, consultanos.</p>',
		},
		allowedExtension: ['xls', 'xlsx'],
	},
	{
		id: 22,
		codigo: '0812',
		ws_available: true,
		thumb: 'atm-60.png',
		value: 'atm',
		title: 'ATM',
		credentials: {
			hide_dates: true,
			fields: [],
		},
		help: {
			video: 'https://player.vimeo.com/video/580313006',
			html: '<p>Debe solicitar las credenciales en ATM.</p>',
			webservice: true,
			datos_form: true,
			cotizador: true,
		},
	},
	{
		id: 23,
		codigo: '0396',
		thumb: 'bbva-60.png',
		value: 'bbva',
		title: 'BBVA',
        ws_available: true,
		credentials: {
			hide_dates: true,
			fields: [],
		},
		help: {
			html: '<p>BBVA entrega información una vez configurado el servicio con la compañia que deben solicitar con su ejecutivo.</p>',
			webservice: true,
			datos_form: true,
		},
	},
	{
		id: 24,
		codigo: '0555',
		thumb: 'beneficio-60.png',
		value: 'beneficio',
		title: 'Beneficio',
		//"helpPanel": "beneficio",
		help: {
			html: "<p>En la web de Beneficio, ingresan en 'Cartera' -> 'Consulta de Pólizas'.</p><p>Pueden usar el formato XML o en Excel. En el caso de usar el Excel, deben abrirlo y guardarlo con Excel antes de usarlo en la importación.</p>",
			thumbs: [{ src: 'beneficio/beneficio-1.png' }],
		},
		allowedExtension: ['xls', 'xml', 'zip'],
	},
	{
		id: 25,
		codigo: '0121',
		thumb: 'berkley-60.png',
		ws_available: true,
		months_available: 120,
		value: 'berkley',
		title: 'Berkley',
		help: {
			html:
				'Deben tener sus códigos de Productor en Berkley cargados previamente en AdminSE.' +
				'<br/>Luego de ejecutada la interfaz con éxito, chequear en que códigos se asignaron las pólizas y eliminar el que no corresponda.' +
				'<br/>Esto se debe a que la compañía envía la información de diferentes maneras.',
			datos_form: true,
			mail_ejecutivo: {
				extra_text:
					'Deben canalizar el pedido a través de su ejecutivo en la compañía.<br/>' +
					'<b>Necesitamos que Berkley le informe a AdminSE - integraciones@adminse.com.ar - todos los códigos presentes que se van a encontrar en el envío de la información, sin estos datos, no podemos realizar la configuración correspondiente.</b>',
			},
			cotizador: true,
		},
		credentials: {
			fields: [
				{
					field: 'username',
					label: 'Usuario',
				},
				{
					field: 'password',
					label: 'Contraseña',
				},
			],
		},
	},
	{
		id: 27,
		codigo: '0624',
		thumb: 'berkley-art-60.png',
		value: 'berkley-art',
		title: 'Berkley ART',
		help: {
			html: "",
            thumbs: [
				{ src: 'berkley/berkley_art_1.png' },
			],
		},
		allowedExtension: ['xls', 'xlsx'],
	},
	{
		id: 30,
		ws_available: true,
		months_available: 120,
		codigo: '0032',
		thumb: 'boston-60.png',
		value: 'boston',
		title: 'Boston',
		help: {
			webservice: true,
			datos_form: true,
			cotizador: true,
		},
		credentials: {
			fields: [
				{
					field: 'username',
					label: 'Clave',
				},
				{
					field: 'codigo_acuerdo',
					label: 'Acuerdo',
				},
			],
		},
	},
	{
		id: 35,
		codigo: '0415',
		thumb: 'caucion_60.png',
		value: 'aseguradores de caucion',
		title: 'Aseguradores de Caucion',
		shortTitle: 'Caucion',
		helpPanel: 'caucion',
		help: {
			height: 400,
			html: '<p>Se importan archivos en formato Excel con la información de las operaciones. <b>Formato del nombre del archivo: Detalle_Poliza-XXXX-XX-XX.xls</b></p>',
			thumbs: [
				{ src: 'cauciones/caucion_1.png' },
				{ src: 'cauciones/caucion_2.png' },
				{ src: 'cauciones/caucion_3.png' },
			],
		},
		allowedExtension: ['xls', 'xlsx'],
	},
	{
		id: 40,
		codigo: '0057',
		thumb: 'caledonia_60.png',
		value: 'caledonia',
		title: 'Caledonia',
		//"helpPanel": "caledonia",
		help: {
			width: 700,
			height: 500,
			cotizador: true,
			html: "<p>El archivo de importaci&oacute;n que usaremos para Caledonia es un TXT que descargan de la p&aacute;gina de la compa&ntilde;&iacute;a.</p><p>Se descarga desde el link 'Opciones Personales' -> 'Exportar', seleccionan 'Toda la Cartera', 'Polizas'.</p><p>Deben seleccionar 'Exportar nueva versi&oacute;n AMPLIADA'.</p>",
			thumbs: [
				{ src: 'caledonia/caledonia_1.png' },
				{ src: 'caledonia/caledonia_2.png' },
				{ src: 'caledonia/caledonia_3.png' },
			],
		},
		allowedExtension: ['csv', 'txt'],
		cobranzas: {
			allowedExtension: ['csv', 'txt'],
		},
	},
	{
		id: 41,
		codigo: '0581',
		thumb: 'chubb-60.png',
		value: 'chubb',
		title: 'Chubb',
		prod_codes_enabled: true,
		force_prod_code: true,
		help: {
			height: 500,
			html:
				'<h4>Cauciones</h4><p>El archivo de importaci&oacute;n que usaremos para Chubb es un <b>TXT</b> que la compañía les envía por mail a través de su ejecutivo de cuenta.</p><p>Deben solicitar el archivo de datos acorde a la especificación de <b>Intercambio Electrónico</b> que provee la compañía.</p>' +
				'<h4>Automotores y Combinados</h4><p>Para estos ramos deben solicitar de igual manera el archivo <b>XML</b> correspondiente a Automotores y Combinados.</p>' +
				"<h4>Patrimoniales general</h4><p>Usaremos un Excel (.xls) de operaciones que descargan <b><a href='"+import.meta.env.VITE_BASE_URL+"workspace/desk/resources/files/GENERICO-PATRIMONIALES-COMISIONES.xls' target='_blank'>AQUI</a></b>, y deben completar con la información que les envía la compañía.</p>" +
				"<h4>ART</h4><p>Para ART usaremos un Excel (.xls) de comisiones/refacturaciones que descargan <b><a href='"+import.meta.env.VITE_BASE_URL+"workspace/desk/resources/files/GENERICO-ART-COMISIONES-REFACTURACIONES.xls' target='_blank'>AQUI</a></b>, y deben completar con la información que les envía la compañía.</p>"+
                "<h4>Pagos y Comisiones</h4><p>Para cobranzas/comisiones, se utiliza el Excel que figura en la imágen de ayuda.</p>",
            thumbs: [
                { src: 'chubb/chubb_1.png' },
            ]
		},
		allowedExtension: ['txt', 'csv', 'xml', 'xls', 'xlsx'],
	},
	/*{
        "id": 42,
        "codigo": "0861",
        "ws_available": true,
        "thumb": "colon-60.png",
        "value": "colon",
        "title": "Colon",
        "credentials":{
            "hide_dates": true,
            "fields":[]
        },
        "help":{
            "html": "<p>El archivo de importaci&oacute;n que usaremos para Colon es un csv (.csv) que <b>deben solicitar a su ejecutivo de cuenta</b> para que les envíe regularmente.</p><p>El mismo debe contener las columnas exactamente como figuran en <a href='/workspace/desk/resources/images/help/colon/colon-modelo.png' target='_blank'>el modelo de archivo.</a></p>",
            "webservice": true
        }
    },*/
	{
		id: 43,
		codigo: '0196',
		thumb: 'coopmutual-60.png',
		value: 'cooperacion_mutual patronal',
		title: 'Cooperacion Mutual Patronal',
		shortTitle: 'Coop. Mutual',
		help: {
			height: 500,
			html: "<p>Los archivos de importaci&oacute;n que usaremos en este caso son archivos XML que descargan desde la Extranet de Cooperación, ingresando en <b>Servicios en Linea</b>, y clickeando en el link 'Ingrese a la aplicación', como se muestra en la imágen.</p><p>Luego dentro de <b>Reportes -> Libros Digitales</b>, seleccionan el período que quieran importar y <b>formato XML</b>, luego click en el boton 'Generar'</p>",
			thumbs: [{ src: 'coop/coop-1.png' }, { src: 'coop/coop-2.png' }],
		},
		allowedExtension: ['xml'],
		cobranzas: {
			allowedExtension: ['xml'],
		},
	},
	{
		id: 44,
		codigo: '0248',
		thumb: 'copan-60.png',
		value: 'copan',
		title: 'Copan',
		help: {
			width: 650,
			height: 500,
			html: "<p>El archivo de importaci&oacute;n que usaremos para Copan es el XML con archivos de r&uacute;brica que descargan de la p&aacute;gina de la compa&ntilde;&iacute;a.</p><p>Ingresan desde 'Web Productores', en el menú superior van a 'Emision' > 'Registro SSN Emision' y generan el archivo en formato XML</p>",
			thumbs: [{ src: 'copan/copan-1.png' }, { src: 'copan/copan-2.png' }],
		},
		allowedExtension: ['xml'],
		cobranzas: {
			allowedExtension: ['xml'],
		},
	},
    {
		id: 405,
		codigo: '0725',
		thumb: 'cosena-60.png',
		value: 'cosena',
		title: 'Cosena',
		help: {
			//"height": 400,
			html: '<p>Se importan archivos en formato TXT que solicitan a la compañía.</p>',
		},
		allowedExtension: ['txt'],
	},
	{
		id: 410,
		codigo: '0656',
		thumb: 'credycau-60.png',
		value: 'creditoycaucion',
		title: 'Créd. y Cauc.',
		help: {
			html: '<p>Descargan el excel (CSV) desde la web de la compañía como se muestra en las imágenes para Cuenta Corriente, y el TXT para novedades (archivo enviado por la compañía).</p>',
            thumbs: [{ src: 'creditoycaucion/cyc_1.png' },{ src: 'creditoycaucion/cyc_2.png' }],
		},
		ws_available: false,
		allowedExtension: ['csv', 'txt'],
	},
	{
		id: 420,
		codigo: '0872',
		thumb: 'digna-60.png',
		value: 'digna',
		title: 'Digna',
		help: {
			html: '<p>Ingresar a la pagina Web de Digna, Acceder al portal de Productores, Selecciona el Modulo de Descarga, y luego ingresar a Detalle de Operaciones por Sector, completar fecha de Emisión y Seleccionar Pólizas Vigentes. Por ultimo, seleccionar Excel.</p>',
            thumbs: [{ src: 'digna/digna_1.png' },{ src: 'digna/digna_2.png' },{ src: 'digna/digna_3.png' }],
		},
		ws_available: false,
		allowedExtension: ['xls'],
	},
	{
		id: 441,
		codigo: '0744',
		thumb: 'elnoble-60.png',
		value: 'el noble',
		title: 'El Noble',
		help: {
			html: '<p>Deben generar el reporte tal como se muestra en las imágenes.</p>',
			thumbs: [{ src: 'noble/noble_0.png' }, { src: 'noble/noble_1.jpg' }],
		},
		allowedExtension: ['csv'],
	},
	{
		id: 45,
		codigo: '0141',
		thumb: 'elnorte-60.png',
		value: 'el norte',
		title: 'El Norte',
		help: {
			html: '<p>Para importar datos del Norte vamos a usar el TXT que la compañía te envía por mail regularmente.</p><p>Si no te envian este archivo periódicamente, contactanos que te vamos a explicar como solicitarlo.</p>',
			cotizador: {
				help: 'Escribinos a soporte@adminse.com.ar para configurar este cotizador',
			},
		},
		allowedExtension: ['txt'],
	},
	{
		id: 46,
		codigo: '0746',
		thumb: 'escudo-60.png',
		value: 'escudo',
		title: 'Escudo',
		help: {
			html: "<p>Ingresando en 'Descargas', realizan la búsqueda correspondiente, y luego exportan los resultados a Excel, como se muestra en las imágenes.</p>",
			thumbs: [{ src: 'escudo/escudo_0.png' }, { src: 'escudo/escudo_1.png' }],
		},
		allowedExtension: ['xls'],
	},
	{
		id: 47,
		codigo: '0236',
		thumb: 'evolucion-60.png',
		value: 'evolucion',
		title: 'Evolución',
		help: {
			html: '<p>Deben ingresar en <b>Descargas</b> y utilizar el reporte de Detalle de Operaciones por Sección, como se muestra en la imágen.</p>'+
            '<p>Luego descargan el archivo excel, como se ve en el recuadro.</p>',
			thumbs: [{ src: 'evolucion/evolucion1.png' },{ src: 'evolucion/evolucion2.png' },{ src: 'evolucion/evolucion3.jpg' }],
		},
		allowedExtension: ['xls'],
	},
	{
		id: 48,
		codigo: '0616',
		thumb: 'experta-60.png',
		value: 'experta',
		title: 'Experta ART',
		help: {
			html:
            `Sólo pueden importar el archivo de Liquidación de Comisiones.
            <br/>Ingresar en pagina Experta, ir a sección 'productores'
            <br/>Seleccionar opción 'liquidación de comisiones'
            <br/>Seleccionar productor y botón 'seleccionar'
            <br/>Elegir el periodo deseado, seleccionar convenio COMISIONES, luego elegir el botón 'buscar'
            <br/>Una vez realizada la búsqueda seleccionar la opción 'exportar a excel'`,
            thumbs: [
				{ src: 'experta/experta_art_1.png' },
                { src: 'experta/experta_art_2.png' },
                { src: 'experta/experta_art_3.png' },
			],
		},
		allowedExtension: ['xls', 'xlsx'],
	},
	{
		id: 932,
		codigo: '0622',
		thumb: 'experta-patrimoniales-60.png',
		value: 'experta',
		title: 'Experta',
		prod_codes_enabled: true,
		force_prod_code: true,
		months_available: 120,
		ws_available: true,
		credentials: {
			web_credentials: false,
			fields: [
				{
					field: 'username',
					label: 'Usuario',
				},
				{
					field: 'password',
					label: 'Password',
				},
			],
            file_upload: {
				label: 'Otros Ramos - Emisiones y Cobranzas',
				day_prompt: false,
			},
		},
		help: {
			html: '<p>Solicite sus credenciales mediante el canal de soporte@adminse.com.ar . Luego de confirmarle la recepcion de las mismas y configuraras, queda configurado para actualizarse diariamente con el proceso diario.</p><p>Debes ingresar <b>todos los códigos de productor</b> que tengas en la compañia, para que se descarguen todos.</p>'+
            '<p><Use la sección inferior para importar información y cobranzas de otros ramos, descargando el archivo necesario como se indica en las imágenes de ayuda.</p>',
            thumbs: [
                { src: 'experta/experta_1.jpg' }, 
                { src: 'experta/experta_2.jpg' },
                { src: 'experta/experta_3.png' },
                { src: 'experta/experta_4.jpg' },
            ],
		},
        allowedExtension: ['xml','txt'],
	},
	{
		id: 49,
		codigo: '0749',
		thumb: 'seguros-medicos-60.png',
		value: 'seguros medicos',
		title: 'Seguros Medicos',
		allowedExtension: ['xls'],
		help: {},
	},
	{
		id: 50,
		codigo: '0726',
		thumb: 'fedpat-60.png',
		value: 'federacion patronal',
		title: 'Federación Patronal',
		shortTitle: 'Fed.Pat.',
		help: {
			video: 'https://player.vimeo.com/video/580309541',
			height: 600,
			width: 900,
			html:
				"<p>El archivo de importaci&oacute;n que usaremos para Federaci&oacute;n Patronal es un zip (.zip) que descargan de la p&aacute;gina de la compa&ntilde;&iacute;a bajo el link 'solicitar cartera interface'.</p><p>Para descargar el archivo de la p&aacute;gina deben ingresar en SELF -> Productores -> Solicitar cartera interface.</p><p>Luego confirman la actualizaci&oacute;n de la cartera y les enviar&aacute;n el archivo .zip en unas horas.</p><p>Deben subir el archivo <b>exactamente como lo reciben</b> desde la compañía, llamado 'sir.....zip'.</p><p>Cuando ejecuten por vez primera esta interfaz se importarán todos los datos históricos hasta el dia de la generación del archivo de interfaz, por lo que puede demorar un poco mas que las subsiguientes importaciones.</p>" +
				'<p>También pueden importar archivos de Comisiones, tal como se muestra en las imágenes de ayuda.</p>',
			thumbs: [{ src: 'fedpat/fedpat-comisiones-1.png' }, { src: 'fedpat/fedpat-comisiones-2.png' }],
			cotizador: {
				help: 'Escribinos a soporte@adminse.com.ar para configurar este cotizador',
			},
		},
		allowedExtension: ['zip', 'csv'],
	},
	{
		id: 65,
		codigo: '0253',
		thumb: 'fianzas_y_credito-60.png',
		value: 'fianzas_y_credito',
		title: 'Fianzas y Crédito',
		shortTitle: 'Fianzas y C.',
		help: {
			html: "<p>Desde la web de la compañía, ingresan en Descargas -> Fianzas a Productores, buscan el período y descargan con 'Generar Archivo'.</p><p>Para importar <b>cobranzas</b>, ingresan en 'Cuenta Corriente' y descargan el mes que requieran, como se muestra en las imágenes.</p>",
			//"html": "<p>Deben solicitar un archivo de datos completo en formato TXT a la compañía.</p>"
			thumbs: [
				{ src: 'fianzas/fianzas-1.png' },
				{ src: 'fianzas/fianzas-2.png' },
				{ src: 'fianzas/fianzas-3.png' },
			],
		},
		allowedExtension: ['txt', 'xls'],
	},
	{
		id: 67,
		codigo: '0867',
		thumb: 'woranz-60.png',
		value: 'woranz',
		title: 'Woranz',
		help: {
			html: '<p>Ingresan en Cartera -> Consulta Operaciones, y descargan el reporte.</p>',
		},
		thumbs: [{ src: 'foms/foms-1.png' }],
		allowedExtension: ['xls', 'xlsx'],
	},
	{
		id: 70,
		codigo: '0619',
		thumb: 'galeno_logo_60.png',
		value: 'galeno art',
		title: 'Galeno ART',
		ws_available: true,
		//"months_available": 120,
		help: {
			video: 'https://player.vimeo.com/video/613581751',
			webservice: true,
			datos_form: false,
			cotizador: false,
			html: "<p>Deben generar sus credenciales de acceso a Web Services (<b>son las mismas credenciales que las de Galeno patrimoniales</b>).<br/>Clickean en el acceso 'Habilitación a Accesos de Web Services de Multicotizadores' y generan las credenciales.</p>"+
            "<p>Luego deben colocar el usuario y contraseña en el modulo 'Interfaces con compañias - Importar' y enviarnos esas credenciales para terminar la configuración.</p>",
			thumbs: [
                { src: 'galeno/galeno_1.png' },
                { src: 'galeno/galeno_3.png' },
                { src: 'galeno/galeno_2.png' }
            ],
		},
		credentials: {
            /*
			file_upload: {
				label: 'Cobranzas y Comisiones',
				day_prompt: true,
			},
            */
			web_credentials: true,
			fields: [
				{
					field: 'username',
					label: 'Usuario',
				},
				{
					field: 'password',
					label: 'Password',
				} /*,
      // sacado a pedido de seba: 04/2021
                {
                    field:'codigo_acuerdo',
                    label:'Legajo'
                }*/,
			],
		},
		allowedExtension: ['xls', 'xlsx', 'txt'],
		months_available: 120,
	},
	{
		id: 71,
		codigo: '0878',
		thumb: 'galeno_logo_60.png',
		value: 'galeno',
		title: 'Galeno',
		ws_available: true,
		months_available: 120,
		help: {
			video: 'https://player.vimeo.com/video/613581751',
			webservice: true,
			datos_form: false,
			cotizador: true,
            html: "<p>Deben generar sus credenciales de acceso a Web Services<br/>Clickean en el acceso 'Habilitación a Accesos de Web Services de Multicotizadores' y generan las credenciales.</p>"+
            "<p>Luego deben colocar el usuario y contraseña en el modulo 'Interfaces con compañias - Importar' y enviarnos esas credenciales para terminar la configuración.</p>",
			thumbs: [
                { src: 'galeno/galeno_1.png' },
                { src: 'galeno/galeno_3.png' },
                { src: 'galeno/galeno_2.png' }
            ],
		},
		credentials: {
			fields: [
				{
					field: 'username',
					label: 'Usuario',
				},
				{
					field: 'password',
					label: 'Password',
				} /*,
      // sacado a pedido de seba: 04/2021
                {
                    field:'codigo_acuerdo',
                    label:'Legajo'
                }*/,
			],
		},
	},
	{
		id: 72,
		codigo: '0882',
		thumb: 'gestion-60.png',
		value: 'gestion',
		title: 'Gestion',
		help: {
			html: '<p>Deben descargar el <b>Detalle de operaciones por Sección</b>.</p>',
			thumbs: [{ src: 'gestion/gestion1.png' }, { src: 'gestion/gestion2.png' }],
		},
		allowedExtension: ['xls'],
	},
	{
		id: 720,
		codigo: 'GNPMX',
		thumb: 'gnp-60.png',
		value: 'gnp',
		title: 'GNP',
		help: {
			html: '<p>Se importa la información mediante un archivo XLS.</p>',
		},
		allowedExtension: ['xls'],
	},
	{
		id: 73,
		codigo: '0335',
		thumb: 'hdi-60.png',
		value: 'hdi',
		title: 'HDI',
		ws_available: true,
		help: {
			video: 'https://player.vimeo.com/video/600461743',
			datos_form: true,
			cotizador: true,
		},
		credentials: {
			file_upload: {
				label: 'Cobranzas y Comisiones',
				day_prompt: true,
			},
			web_credentials: true,
			fields: [],
		},
		allowedExtension: ['txt'],
		months_available: 120,
	},
	{
		id: 74,
		codigo: '0079',
		thumb: 'holando-60.png',
		value: 'holando',
		title: 'La Holando',
		prod_codes_enabled: true,
		force_prod_code: true,
		months_available: 120,
		ws_available: true,
		credentials: {
			web_credentials: true,
			fields: [
				{
					field: 'username',
					label: 'Usuario',
				},
				{
					field: 'password',
					label: 'Password',
				},
			],
		},
		help: {
			html: "<p>Ingresá tus credenciales de la web de productores de Holando y clickeá en 'Importar' para para ejecutar la interfaz. Luego queda configurado para actualizarse diariamente.</p><p>El <b>código de productor</b> lo tenes que ingresar <b>sin puntos</b>, por ejemplo, si tu código es '8888.1', debes ingresar '88881'.</p>",
			cotizador: {
				help: 'Tenés que configurar la importación y se activa el cotizador en forma automática. Si no queres configurar la importación, escribinos a soporte@adminse.com.ar',
			},
		},
	},
	{
		id: 75,
		codigo: '0261',
		thumb: 'horizonte-60.png',
		value: 'horizonte',
		title: 'Horizonte',
		allowedExtension: ['txt'],
	},
	{
		id: 76,
		codigo: '0720',
		ws_available: true,
		months_available: 120,
		thumb: 'integrity-60.png',
		value: 'integrity',
		title: 'Integrity',
		help: {
			video: 'https://player.vimeo.com/video/657083475',
			webservice: true,
			datos_form: true,
			cotizador: true,
		},
		credentials: {
			fields: [
				{
					field: 'username',
					label: 'Ticket',
				},
				{
					field: 'codigo_acuerdo',
					label: 'Código',
				},
			],
		},
	},
    {
		id: 77,
		codigo: '0381',
		thumb: 'iam-60.png',
		value: 'iam instituto',
		title: 'IAM',
		help: {
			//"height": 400,
			html: '<p>Se importan archivos en formato Excel con la información de las operaciones y las cobranzas.</p>'+
            'Para Operaciones, el archivo debe tener el siguiente encabezado:<br><br>'+
            '<b>"Compañia", "Cod.Sec.", "Nombre Seccion", "Poliza", "Endoso", "Fecha Registrac.", "Contratante/Asegur", "Tpo.Doc.", "Nro.Doc.", "Domicilio", "Localidad", "Provincia", "C.Postal", "Ubicacion del Riesgo", "Bien", "Riesgo", "C.Mon", "Moneda", "Suma Asegurada", "Prima", "Premio", "Fec.Desde", "Fec.Hasta", "Cuotas", "Vt.Cuota 1", "Imp.1ra.Cuota", "Imp.Ult.Cuota", "Imp.Comision", "Observaciones"</b>'+
            '<br><br>Para Cobranzas, el siguiente encabezado:<br><br>'+
            '<b>"Compañia ", "Cod. Seccion", "Nombre Seccion", "Nro Poliza", "Nro Endoso", "Fecha de Cobro", "Importe"</b>',
		},
		allowedExtension: ['xlsx','xls','csv'],
	},
	{
		id: 78,
		codigo: '0780',
		thumb: 'insur-60.png',
		value: 'insur',
		title: 'Insur',
		help: {
			// "html": "<p>Ingresando en 'Consulta Operaciones', realizan la búsqueda correspondiente, y luego exportan los resultados a TXT, con el ícono tal como se muestra en las imágenes.</p>",
			html: '<p>Pueden descargar un TXT con la información de operaciones desde la web de Insur-ExtraNET Caución. Ir a Descargas -> Insur a Productores -> seleccionar período y descargar.</p>',
			thumbs: [
                { src: 'insur/insur-1.png' },
                { src: 'insur/insur-2.png' }, 
                { src: 'insur/insur-3.png' }, 
                { src: 'insur/insur-4.png' }, 
                { src: 'insur/insur-5.png' }, 
            ],
		},
		allowedExtension: ['txt', 'xls'],
	},
	{
		id: 77,
		codigo: '0501',
		thumb: 'lacaja-60.png',
		value: 'la caja',
		title: 'La Caja',
		help: {
            webservice: true,
			datos_form: true,
            /*
			html: "<p>Para importar datos de La Caja vamos a usar los XML de rúbrica que descargan del sistema Polaris (en su nueva o vieja versión), como se muestra en las imágenes.</p><p>En <b>Polaris</b> ingresan en 'Menu AGEX', 'Reportes AGEX', 'Registro de Operaciones y Rendiciones', y generan la búsqueda en formato XML.</p><p>En <b>i-Polaris</b> ingresan en 'Reportes para Productores', 'Registro de Operaciones y Rendiciones', y generan la búsqueda en formato XML.</p>",
			thumbs: [
				{ src: 'caja/caja_help_pol1.png' },
				{ src: 'caja/caja_help_pol2.png' },
				{ src: 'caja/caja_help_ipol1.png' },
				{ src: 'caja/caja_help_ipol2.png' },
			],
            */
		},
        ws_available: true,
		credentials: {
			web_credentials: false,
            hide_dates: true,
			fields: [
				{
					field: 'username',
					label: 'Usuario',
				},
				{
					field: 'password',
					label: 'Contraseña',
				},
			],
		},
        /*
		allowedExtension: ['xml'],
		cobranzas: {
			allowedExtension: ['xml'],
		},
        */
	},
	{
		id: 83,
		codigo: '0044',
		thumb: 'equitativa-60.png',
		value: 'la equitativa',
		title: 'La Equitativa',
		help: {
			html: '<p>Para importar datos de La Equitativa deben solicitar a su ejecutivo de cuenta en la compañía que les envíen el archivo a tal efecto (suelen enviarlo por mail los dias viernes).</p><p>El archivo en cuestión es un CSV.</p><p>Si tienen dudas sobre el formato del archivo nos pueden consultar como siempre a soporte@adminse.com.ar</p>'+
            '<p>Para el cotizador, deben ingresar en "Servicios Online" en la web de La Equitativa, habilitar la API y enviarnos el código de productor, email y API Key generado.</p>',
		},
		allowedExtension: ['csv'],
		cobranzas: {
			allowedExtension: ['csv'],
		},
	},
	{
		id: 86,
		codigo: '0515',
		thumb: 'nacion-60.png',
		value: 'nacion',
		title: 'Nación',
		allowedExtension: ['csv', 'xls'],
		help: {
            thumbs: [{ src: 'nacion/nacion_1.png' }, { src: 'nacion/nacion_2.png' }, { src: 'nacion/nacion_3.png' }, { src: 'nacion/nacion_4.png' }, { src: 'nacion/nacion_5.png' }, ],
        },
	},
	{
		id: 90,
		codigo: '0163',
		thumb: 'lanueva-60.png',
		value: 'la nueva',
		title: 'La Nueva',
		help: {
			height: 500,
			width: 700,
			html: "<h4>Emisiones</h4><p>Vamos a usar un Excel que descargan de p&aacute;gina de la compa&ntilde;&iacute;a, entrando en el Portal de Productores, Libros Rubricados -> Operaciones, haciendo la búsqueda correspondiente.</p><p>Luego clickean en <b>'Descargar Excel'</b>, y descargan el archivo.</p><br/><h4>Cobranzas</h4><p>El archivo de <b>cobranzas</b> es un Excel que se descarga de igual manera, seleccionando Libros de Cobranzas.</p>",
			thumbs: [{ src: 'lanueva/lanueva-1.png' }, { src: 'lanueva/lanueva-2.png' }],
		},
		allowedExtension: ['xls'],
		cobranzas: {
			allowedExtension: ['xls'],
		},
	},
	{
		id: 93,
		codigo: '0117',
		thumb: 'lasegunda-personas-60.png',
		value: 'la segunda',
		title: 'La Segunda Personas',
		shortTitle: '2da Personas',
		help: {
			height: 500,
			width: 700,
			html: "<h4>Emisiones</h4><p>Vamos a usar un TXT que descargan de p&aacute;gina de la compa&ntilde;&iacute;a, desde 'Listados Asegurados' -> 'Listado de Operaciones', exportando la búsqueda correspondiente.</p><p>El mismo debe contener las columnas exactamente como figuran en <a href='"+import.meta.env.VITE_BASE_URL+"workspace/desk/resources/images/help/lasegunda/lasegunda-modelo.png' target='_blank'>el modelo de archivo.</a></p><br/><h4>Cobranzas</h4><p>El archivo de <b>cobranzas</b> en cambio, es un CSV que se descarga de similar manera, desde el menú 'Listado Cuotas Cobradas' y guardando el listado generado.</p><p>El mismo debe contener las columnas exactamente como figuran en <a href='"+import.meta.env.VITE_BASE_URL+"workspace/desk/resources/images/help/lasegunda/lasegunda-cobranza-modelo.png' target='_blank'>el modelo de archivo.</a></p>",
			thumbs: [{ src: 'lasegunda/lasegunda-1.png' }, { src: 'lasegunda/lasegunda-2.png' }],
		},
		allowedExtension: ['txt', 'csv'],
	},
	{
		id: 94,
		codigo: '0436',
		thumb: 'lasegunda-retiro-60.png',
		value: 'la segunda',
		title: 'La Segunda Retiro',
		shortTitle: '2da Retiro',
		help: {
			height: 500,
			width: 700,
			html: "<h4>Emisiones</h4><p>Vamos a usar un TXT que descargan de p&aacute;gina de la compa&ntilde;&iacute;a, desde 'Listados Asegurados' -> 'Listado de Operaciones', exportando la búsqueda correspondiente.</p><p>El mismo debe contener las columnas exactamente como figuran en <a href='"+import.meta.env.VITE_BASE_URL+"workspace/desk/resources/images/help/lasegunda/lasegunda-modelo.png' target='_blank'>el modelo de archivo.</a></p><br/><h4>Cobranzas</h4><p>El archivo de <b>cobranzas</b> en cambio, es un CSV que se descarga de similar manera, desde el menú 'Listado Cuotas Cobradas' y guardando el listado generado.</p><p>El mismo debe contener las columnas exactamente como figuran en <a href='"+import.meta.env.VITE_BASE_URL+"workspace/desk/resources/images/help/lasegunda/lasegunda-cobranza-modelo.png' target='_blank'>el modelo de archivo.</a></p>",
			thumbs: [{ src: 'lasegunda/lasegunda-1.png' }, { src: 'lasegunda/lasegunda-2.png' }],
		},
		allowedExtension: ['txt', 'csv'],
	},
	{
		id: 95,
		codigo: '0317',
		thumb: 'lasegunda-60.png',
		value: 'la segunda',
		title: 'La Segunda',
		help: {
			height: 500,
			width: 700,
			html: "<h4>Emisiones</h4><p>Vamos a usar un TXT que descargan de p&aacute;gina de la compa&ntilde;&iacute;a, desde 'Listados Asegurados' -> 'Listado de Operaciones', exportando la búsqueda correspondiente.</p><p>El mismo debe contener las columnas exactamente como figuran en <a href='"+import.meta.env.VITE_BASE_URL+"workspace/desk/resources/images/help/lasegunda/lasegunda-modelo.png' target='_blank'>el modelo de archivo.</a></p><br/><h4>Cobranzas</h4><p>El archivo de <b>cobranzas</b> en cambio, es un CSV que se descarga de similar manera, desde el menú 'Listado Cuotas Cobradas' y guardando el listado generado.</p><p>El mismo debe contener las columnas exactamente como figuran en <a href='"+import.meta.env.VITE_BASE_URL+"workspace/desk/resources/images/help/lasegunda/lasegunda-cobranza-modelo.png' target='_blank'>el modelo de archivo.</a></p>",
			thumbs: [
				{ src: 'lasegunda/lasegunda-1.png' },
				{ src: 'lasegunda/lasegunda-2.png' },
				{ src: 'lasegunda/lasegunda-3.png' },
			],
		},
		allowedExtension: ['txt', 'csv'],
	},
	{
		id: 98,
		codigo: '0852',
		thumb: 'libra-60.png',
		value: 'libra',
		title: 'Libra',
		ws_available: true,
        credentials: {
			fields: [],
		},
		/*credentials: {
			web_credentials: true,
			fields: [
				{
					field: 'username',
					label: 'Usuario',
				},
				{
					field: 'password',
					label: 'Password',
				},
			],
		},*/
		help: {
			html: '<p>Deben solicitar el alta del servicio a la compañía.</p>',
		},
		/*help: {
			video: 'https://player.vimeo.com/video/568441767',
			html: "<p>Ingresando en 'Descargas', realizan la búsqueda correspondiente, y luego exportan los resultados a Excel, como se muestra en las imágenes.</p>",
			thumbs: [{ src: 'libra/libra_1.png' }, { src: 'libra/libra_2.png' }],
			cotizador: {
				help: 'Escribinos a soporte@adminse.com.ar para configurar este cotizador',
			},
		},
		allowedExtension: ['xls'],
        */
	},
	{
		id: 100,
		codigo: '0352',
		thumb: 'liderar-60.png',
		value: 'liderar',
		title: 'Liderar',
		help: {
			html: "<h4>Emisiones</h4><p>El archivo de importaci&oacute;n que usaremos para las <b>emisiones</b> de Liderar es un csv (.CSV) que descargan de la p&aacute;gina de la compa&ntilde;&iacute;a bajo el link 'Solicitudes' > 'Libro Emisi&oacute;n', como se muestra en la im&aacute;gen.</p><p>Luego seleccionan la emisi&oacute;n que generaron y descargan el archivo en 'Visualizar el TXT generado'.</p><h4>Cobranzas</h4><p>Id&eacute;ntica operatoria, solo que se descarga desde 'Solicitudes' -> 'Libro Cobranzas'</p>",
			thumbs: [{ src: 'liderar/liderar_emision_1.png' }],
		},
		allowedExtension: ['csv'],
		cobranzas: {
			allowedExtension: ['csv'],
		},
	},
	{
		id: 115,
		codigo: '0699',
		thumb: 'mapfre_60.png',
		value: 'mapfre vida',
		title: 'Mapfre vida',
		help: {
			//"height": 400,
			html: '<p>Se importan archivos en formato Excel con la información de las operaciones.</p>',
		},
		allowedExtension: ['xlsx'],
	},
    {
        id: 117,
        codigo: "0213",
        thumb: "mapfre_60.png",
        value: "mapfre",
        title: "Mapfre",
        ws_available: true,
		credentials: {
			web_credentials: true,
			fields: [
				{
					field: 'username',
					label: 'Usuario',
				},
				{
					field: 'password',
					label: 'Password',
				},
			],
		},
		help: {
			html: '<p>Deben solicitar el alta del servicio a la compañía.</p>',
            datos_form: true,
            cotizador: true,
		}
    },
	{
		id: 120,
		codigo: '0116',
		ws_available: true,
		months_available: 120,
		thumb: 'mercantil_60.png',
		value: 'mercantil andina',
		title: 'Mercantil Andina',
		credentials: {
			fields: [],
		},
		help: {
			webservice: true,
			datos_form: true,
			cotizador: true,
			//"html": ""
		},
	},
	// {
	// 	"id": 120,
	// 	"codigo": "0116",
	// 	"ws_available": true,
	// 	"thumb": "mercantil_60.png",
	// 	"value": "mercantil andina",
	// 	"title": "Mercantil Andina",
	// 	// "credentials":{
	// 	// 	"hide_dates": true,
	// 	// 	"fields":[]
	// 	// },
	// 	"shortTitle": "M.Andina",
	// 	"help":{
	// 		"html": "<p>Nos podés detallar desde cuando queres importar la información histórica en un mail a soporte@adminse.com.ar (máximo 1 año)</p>",
	// 		"webservice": true,
	// 		"datos_form": true,
	// 		"cotizador": true
	// 	},
	// 	"credentials":{
	// 		"file_upload": {
	// 			"label": "Cobranzas y Comisiones"
	// 		},
	// 		"web_credentials": true,
	// 		"fields":[
	// 			{
	// 				field:'username',
	// 				label:'Usuario'
	// 			},
	// 			{
	// 				field:'password',
	// 				label:'Contraseña'
	// 			}
	// 		]
	// 	},
	// 	"allowedExtension": [
	// 		"csv"
	// 	],
	// },
	{
		id: 125,
		codigo: '0244',
		ws_available: true,
		thumb: 'meridional-60.png',
		value: 'meridional',
		title: 'Meridional',
		credentials: {
			hide_dates: true,
			fields: [],
		},
		help: {
			html: '<p>Meridional solo entrega información de los siguientes ramos: Automovil Individual, Flota, Hogar, Accidente Personal, Riesgo Varios, Integral de Comercio.</p>',
			webservice: true,
			datos_form: true,
			cotizador: true,
		},
	},
	{
		id: 127,
		codigo: '0671',
		thumb: 'life-60.png',
		value: 'life',
		title: 'Life',
		//allowedExtension: ['xlsx'],
        ws_available: true,
		help: {
			webservice: true,
			datos_form: true,
		},
		credentials: {
			hide_dates: true,
			fields: [
				{
					field: 'username',
					label: 'Usuario',
				},
				{
					field: 'password',
					label: 'Contraseña',
				},
			],
		},
	},
	{
		id: 130,
		codigo: '0247',
		thumb: 'nativa-60.png',
		value: 'nativa',
		title: 'Nativa',
		help: {
			html: '<p>El archivo de importaci&oacute;n que usaremos para Nativa es el XML con archivos de r&uacute;brica que descargan de la p&aacute;gina de la compa&ntilde;&iacute;a.</p><p>Si el archivo lo envían en formato ZIP, pueden subirlos igualmente en ese formato, el proceso se encargará de extraer los XML que contenga.</p>',
		},
		allowedExtension: ['xml', 'zip'],
		cobranzas: {
			allowedExtension: ['xml'],
		},
	},
	{
		id: 131,
		codigo: '0662',
		thumb: 'nivel-60.png',
		value: 'nivel',
		title: 'Nivel',
		help: {
			html: '<p>El archivo de importaci&oacute;n que usaremos para Nivel es el XML con archivos de r&uacute;brica que descargan de la p&aacute;gina de la compa&ntilde;&iacute;a.</p><p>Deben ingresar en Consultas -> Exportación de datos -> seleccionar período, confirmar y guardar.</p>',
			thumbs: [{ src: 'nivel/nivel-1.png' }],
		},
		allowedExtension: ['xml'],
	},
	{
		id: 132,
		codigo: '0862',
		thumb: 'omint-art-60.png',
		value: 'omint',
		title: 'Omint',
		help: {
			html: "<p>Para importar Comisiones de Omint ART, deben descargar los reportes en Excel, tal como se muestra en las imágenes de ayuda.<br><b>Deben grabar el archivo como Excel '97 sin modificar ningún dato.</b></p>",
			thumbs: [
				{ src: 'omint_art/omint_art_comisiones_0.png' },
				{ src: 'omint_art/omint_art_comisiones_1.png' },
			],
		},
		day_prompt: true,
		allowedExtension: ['xls', 'xlsx'],
	},
	//{
	//    "id":132,
	//    "codigo": "0862",
	//    "thumb": "omint-60.png",
	//    "value": "omint",
	//    "title": "Omint Art",
	//    "uploadPath": "/imports/operations/OmintArt",
	//    "allowedExtension":["xlsx"]
	//},
	{
		id: 133,
		codigo: '0293',
		thumb: 'orbis-60.png',
		value: 'orbis',
		title: 'Orbis',
		ws_available: true,
		help: {
			webservice: true,
			datos_form: true,
			mail_ejecutivo: {
				default: true,
				extra_text: '<b>Deben solicitar explicitamente que agreguen los archivos de novedades en el FTP</b>',
			},
			cotizador: true,
		},
		credentials: {
			hide_dates: true,
			fields: [
				{
					field: 'username',
					label: 'Usuario (usa_ftp)',
				},
				{
					field: 'password',
					label: 'Contraseña (pass_ftp)',
				},
			],
		},
	},
	{
		id: 140,
		codigo: '0330',
		thumb: 'parana-60.png',
		value: 'parana',
		title: 'Parana',
		help: {
			video: 'https://player.vimeo.com/video/680863959',
			width: 600,
			height: 800,
			html: "<h4>Emisiones</h4><p>Deben ingresar en 'Sistema GLM Web', como se muestra en la primer imágen.</p><p>El archivo de importaci&oacute;n que usaremos para emisiones es un XML que descargan desde el menú 'Productores' -> 'Libros de Productor/Interfases', y seleccionan el período que desean importar y la opción 'tec XML' en 'Interfaz'.</p><p>Luego de generar el pedido, les aparecerán los archivos generados en el menú superior 'Mis Archivos' -> 'Descarga de Archivos', tal como se muestra en la imágen.</p><p>Si al querer descargar el archivo, <b>solo les abre una pestaña con los datos</b>, deben hacer <b>click derecho</b> dentro de esa pestaña con los datos y seleccionar 'Guardar Como', para descargar el archivo XML.</p><h4>Cobranzas</h4><p>La operatoria es exactamente igual para cobranzas, seleccionando el mismo tipo de interfaz y descargando los archivos XML generados desde el mismo menú 'Descarga de Archivos'.</p>",
			thumbs: [{ src: 'parana/parana_1.png' }, { src: 'parana/parana_2.png' }],
			cotizador: {
				help: 'Escribinos a soporte@adminse.com.ar para configurar este cotizador',
			},
		},
		allowedExtension: ['xml'],
	},
	{
		id: 141,
		codigo: '0895',
		thumb: 'pacifico-60.png',
		value: 'pacifico',
		title: 'Pacífico',
		help: {
			html: '<p>Deben descargar el archivo tal como se muestra en las imágenes.</p>',
			thumbs: [
				{ src: 'pacifico/pacifico_1.jpg' },
				{ src: 'pacifico/pacifico_2.jpg' },
				{ src: 'pacifico/pacifico_3.jpg' },
			],
		},
		ws_available: false,
		allowedExtension: ['txt'],
	},
	{
		id: 143,
		codigo: '0157',
		thumb: 'perseverancia-60.png',
		value: 'perseverancia',
		title: 'Perseveranc.',
		help: {
			width: 600,
			height: 800,
			html: "<p>Ingresar a 'Mis Archivos' en la pagina de Perseverancia</p><p>Seleccionar dentro de DESCARGA DE ARCHIVOS el directorio PRODUCCION.</p><p>Buscar en el listado de archivos para descargar los de EMISION o COBRANZAS tipo .XML y descargarlos</p><p>Se abrira una pagina web con un XML, hacer click derecho y guardarla con formato .TXT</p>",
			thumbs: [{ src: 'perseverancia/perseverancia_1.jpg' }],
		},
		allowedExtension: ['txt'],
		cobranzas: {
			allowedExtension: ['txt'],
		},
	},
	{
		id: 145,
		codigo: '0626',
		thumb: 'prevencion-60.png',
		value: 'prevencion',
		title: 'Prevención',
		help: {
			width: 600,
			height: 800,
			html:
				'<h6>Cobranzas y Comisiones</h6>' +
				'<p>Se descarga el archivo tal como se muestra en la imágen.</p>',
			thumbs: [{ src: 'prevencion/prevencion-1.jpg' }],
		},
		allowedExtension: ['xls'],
	},
	{
		id: 146,
		codigo: '0409',
		thumb: 'previnca-60.png',
		value: 'previnca',
		title: 'Previnca',
		help: {
			html: '<p>El archivo de importaci&oacute;n que usaremos para Previnca es el XML con archivos de r&uacute;brica que descargan de la p&aacute;gina de la compa&ntilde;&iacute;a, tal como se muestra en la im&aacute;gen, seleccionando formato XML.</p>',
			thumbs: [{ src: 'previnca/previnca-1.png' }],
		},
		allowedExtension: ['xml'],
		cobranzas: {
			allowedExtension: ['xml'],
		},
	},
	{
		id: 147,
		codigo: '0348',
		thumb: 'prof-60.png',
		value: 'prof',
		title: 'Prof',
		help: {
			html: '<p>Los archivo de importaci&oacute;n que usaremos para Prof son los archivos de r&uacute;brica que descargan de la p&aacute;gina de la compa&ntilde;&iacute;a, tal como se muestra en la im&aacute;gen.</p>',
			thumbs: [{ src: 'profru/profru-1.png' }],
			cotizador: {
				help: 'Escribinos a soporte@adminse.com.ar para configurar este cotizador',
			},
		},
		allowedExtension: ['xml'],
		cobranzas: {
			uploadPath: '/imports/payments/Profru',
			allowedExtension: ['xml'],
		},
	},
	{
		id: 149,
		codigo: '0528',
		cia_id: '7777',
		thumb: 'providencia-60.png',
		value: 'providencia',
		title: 'Providencia',
		help: {
			html: '<p>Para la interfaz con Providencia solo tenes que ingresar tu usuario y contraseña de la página https://netprod.providencia.com.ar/netprod </p><p>Una vez que ejecutes el primer período que elijas, se guardará la configuración para que puedas optar por automatizar la importación regularmente, de manera que todas las noches se actualice la información que generaste en la compañía.</p>',
			cotizador: {
				help: 'Tenés que configurar la importación para usar este cotizador. Si no querés configurar las importaciones, escribinos a soporte@adminse.com.ar',
			},
		},
		ws_available: true,
		credentials: {
			web_credentials: true,
			fields: [
				{
					field: 'username',
					label: 'Usuario',
				},
				{
					field: 'password',
					label: 'Contraseña',
				},
			],
		},
		//"prod_codes_enabled": true
	},
	{
		id: 150,
		codigo: '0499',
		thumb: 'provincia-60.png',
		value: 'provincia',
		title: 'Provincia',
		help: {
			html:
				'Deben enviarnos el Usuario y Contraseña del portal de Provincia para hacer el pedido de habilitación de la interfaz en la compañía para el servicio de Interfaz' +
				' y el servicio de Cotización, a integraciones@adminse.com.ar, indicándonos tu código de PAS en la compañía.'+
                '<br><p><b>Para Cobranzas y Comisiones, seguir las imágenes de ayuda.</b></p>',
            thumbs: [
                { src: 'provincia/provincia_1.png' }
            ],
			cotizador: {
				help: 'Deben enviarnos el Usuario y Contraseña del portal de Provincia para hacer el pedido de habilitación de la interfaz en la compañía para el servicio de Interfaz y el servicio de Cotización, a integraciones@adminse.com.ar, indicándonos tu código de PAS en la compañía.',
			},
		},
		ws_available: true,
		credentials: {
			file_upload: {
				label: 'Cobranzas y Comisiones',
				day_prompt: true,
			},
			web_credentials: true,
			fields: [
				{
					field: 'username',
					label: 'Mail',
				},
				{
					field: 'password',
					label: 'Contraseña',
				},
			],
		},
		allowedExtension: ['csv'],
		force_prod_code: true,
	},
	{
		id: 160,
		codigo: '0621',
		thumb: 'provincia-art-60.png',
		value: 'provincia art',
		title: 'Prov. ART',
		help: {
			html:
				"<p>El archivo de importaci&oacute;n que usaremos para Provincia es un excel (.xls) que descargan de la p&aacute;gina de la compa&ntilde;&iacute;a.</p><p>Se descarga ingresando en 'Contratos Activos', buscan todos y exportan a XLS.</p>" +
				'<p><b>Comisiones</b>: Pueden importar datos de Comisiones. Deben hacer la búsqueda de Liquidaciones, ingresar en el detalle de la liquidación y exportar a Excel <b>cada liquidación.</b></p>',
			thumbs: [
				{ src: 'provincia_art/provincia_art_1.png' },
                { src: 'provincia_art/provincia_art_2.png' },
                { src: 'provincia_art/provincia_art_3.png' },
                { src: 'provincia_art/provincia_art_4.png' },
                { src: 'provincia_art/provincia_art_5.png' },
			],
		},
		day_prompt: true,
		allowedExtension: ['xls','xlsx'],
	},
	{
		id: 163,
		codigo: '0040',
		thumb: 'prudencia-60.png',
		value: 'prudencia',
		title: 'Prudencia',
		help: {
			webservice: true,
			datos_form: true,
			cotizador: true,
		},
		ws_available: true,
		months_available: 120,
		credentials: {
			fields: [
				{
					field: 'username',
					label: 'Usuario',
				},
				{
					field: 'password',
					label: 'Contraseña',
				},
			],
		},
		// "allowedExtension": [
		// 	"csv"
		// ],
		// "cobranzas": {
		// 	"uploadPath": "/imports/payments/Prudencia",
		// 	"allowedExtension": [
		// 		"csv"
		// 	]
		// }
	},
	{
		id: 165,
		codigo: '0039',
		thumb: 'qbe-60.png',
		value: 'qbe',
		title: 'QBE',
        ws_available: true,
		credentials: {
			hide_dates: true,
			fields: [],
		},
		help: {
			html: '<p>QBE entrega información una vez configurado el servicio con la compañia que deben solicitar con su ejecutivo.</p>',
			webservice: true,
			datos_form: true,
			cotizador: true,
		},
	},
	{
		id: 170,
		codigo: '0222',
		thumb: 'rivadavia_60.png',
		value: 'rivadavia',
		title: 'Rivadavia',
		help: {
			width: 1000,
			height: 800,
			webservice: true,
			datos_form: true,
			mail_ejecutivo: {
				default: true,
			},
			cotizador: true,
		},
		ws_available: true,
		credentials: {
			hide_dates: true,
			fields: [],
		},
		// "uploadPath": "/imports/operations/Rivadavia",
		// "allowedExtension": [
		// 	"xml",
		// 	"zip"
		// ],
		// "view": "importacion.rivadavia_prompt", // tiene AMBOS metodos
		// "cobranzas": {
		// 	"uploadPath": "/imports/payments/Rivadavia",
		// 	"allowedExtension": [
		// 		"xml",
		// 		"zip"
		// 	]
		// }
	},
	{
		id: 190,
		codigo: '0360',
		thumb: 'rus-60.png',
		value: 'rio uruguay',
		title: 'Río Uruguay Seguros',
		shortTitle: 'RUS',
		ws_available: true,
		force_prod_code: true,
		help: {
			webservice: true,
			datos_form: true,
			cotizador: true,
		},
		/*"credentials":{
            "fields":[]
        }*/
		credentials: {
			web_credentials: true,
			fields: [
				{
					field: 'username',
					label: 'Usuario (Solicitar en AdminSE)',
				},
				{
					field: 'password',
					label: 'Contraseña (Solicitar en AdminSE)',
				},
			],
		},
	},
	{
		id: 200,
		codigo: '0224',
		thumb: 'sancor-60.png',
		value: 'sancor',
		title: 'Sancor',
		ws_available: true,
		help: {
            thumbs: [{ src: 'sancor/sancor_comisiones_1.png' }, { src: 'sancor/sancor_comisiones_2.png' }],
            html: '<p>Para la interfaz de emisiones, deben soliticar el Alta de interfaz. Para la importación de Comisiones, vea las imágenes de ayuda.</p>',
			webservice: true,
			datos_form: true,
			mail_ejecutivo: {
				extra_text:
					'Codigo de Organizador: (completar)<br/><br/>Para la descarga de novedades deben disponibilizar los datos en el FTP.',
			},
			cotizador: true,
		},
		credentials: {
            hide_dates: true,
			fields: [
				{
					regex: /^usr/,
					regexText: 'Usuario inválido (debe empezar con "usr")',
					field: 'username',
					label: 'Usuario',
				},
				{
					field: 'password',
					label: 'Contraseña',
				},
			],
            file_upload: {
				label: 'Comisiones',
				day_prompt: false,
			},
		},
	},
	{
		id: 205,
		codigo: '0192',
		thumb: 'sancristobal-60.png',
		value: 'san cristobal',
		title: 'San Cristobal',
		ws_available: true,
		help: {
			video: 'https://player.vimeo.com/video/660998518',
			webservice: true,
			datos_form: true,
			cotizador: true,
		},
		credentials: {
			fields: [
				{
					regex: /\b(20|23|24|27|30|33|34)(\D)[0-9]{8}(\D)[0-9]/,
					regexText: 'Debe ingresar un CUIT/CUIL válido con guiones (xx-xxxxxxxxxx-x)',
					field: 'username',
					label: 'Cuit (xx-xxxxxxxxxx-x) Se debe respetar el formato con los guiones "-"',
				} /*,
                {
                    regex: /[0-9]{2}(\D)[0]{2}[0-9]{4}/,
                    regexText: 'Debe ingresar el código de acuerdo como se especifica',
                    field:'codigo_acuerdo',
                    label:'Codigo (CC-XXxxxx) CC es el codigo de casa. XX suele ser doble cero para luego completar los 4 digitos del codigo de productor'
                }*/,
			],
		},
	},
	{
		id: 208,
		codigo: '0286',
		thumb: 'segurometal-60.png',
		value: 'seguro metal',
		title: 'Seguro Metal',
		allowedExtension: ['xml'],
		help: {},
	},
	{
		id: 210,
		codigo: '0002',
		thumb: 'smg-60.png',
		value: 'smg',
		title: 'SMG',
		help: {
			//"datos_form": true,
			thumbs: [{ src: 'smg/smg_1.png' }, { src: 'smg/smg_2.png' }],
			cotizador: {
				help: 'Tenés que configurar la importación para usar este cotizador. Si no querés configurar las importaciones, escribinos a soporte@adminse.com.ar',
			},
			html:
				"<p>Ingresando tu usuario y contraseña de la web de SMG, clickeas en 'Importar' y queda configurada la interfaz directa automática.</p><p>Recordá que <b>es necesario que ejecutes un período manualmente (con el boton 'Importar') y que configures los codigos a importar, estos son los codigos con los que operas en SMG</b> para que quede configurada la interfaz automática diaria.</p>" +
				'<p>Podes importar <b>cobranzas y comisiones</b> con archivo de la web de SMG, como se muestra en las imágenes.</p>',
		},
		ws_available: true,
		credentials: {
			file_upload: {
				label: 'Cobranzas y Comisiones',
				day_prompt: true,
			},
			web_credentials: true,
			fields: [
				{
					field: 'username',
					label: 'Usuario',
				},
				{
					field: 'password',
					label: 'Contraseña',
				},
			],
		},
		allowedExtension: ['xls', 'xlsx'],
		force_prod_code: true,
	},
	{
		id: 220,
		codigo: '0784',
		thumb: 'smg-art-60.png',
		value: 'smg',
		title: 'SMG ART',
		help: {
			html: '<p>En SMG ART deben descargar los 2 archivos de los accesos que figuran en la imágen de ayuda.</p>',
			thumbs: [{ src: 'smg_art/smg_art_1.png' },{ src: 'smg_art/smg_art_2.png' }],
		},
		allowedExtension: ['xls', 'xlsx'],
	},
	{
		id: 223,
		codigo: '0580',
		thumb: 'smg-life-60.png',
		value: 'smglife',
		title: 'SMG Life',
		help: {
			html: '<p>Deben generar el Reporte de Operaciones en SMG Life, tal como se muestra en la imágen.</p>',
			thumbs: [{ src: 'smg_life/smg-life-1.png' }],
		},
		allowedExtension: ['xls', 'xlsx'],
	},
	// {
	// 	"id": ,
	// 	"codigo": "",
	// 	// "ws_available": true,
	// 	"thumb": "-60.png",
	// 	"value": "sura",
	// 	"title": "Sura",
	// 	"view": "container",
	// 	"credentials": true,
	// 	"help":{
	// 		"html": "<p>Nos podés detallar desde cuando queres importar la información histórica en un mail a soporte@adminse.com.ar (máximo 1 año)</p>",
	//   "webservice": true,
	// 		"datos_form": true,
	// 		"cotizador": true
	// }
	// },

	{
		id: 225,
		codigo: '0025',
		thumb: 'galicia-60.png',
		value: 'sura galicia',
		title: 'Galicia',
		ws_available: true,
		help: {
			html: '<p>Nos podés detallar desde cuando queres importar la información histórica en un mail a soporte@adminse.com.ar (máximo 1 año)</p>',
			webservice: true,
			datos_form: true,
			mail_ejecutivo: {
				extra_text: 'Codigo de Producto: (pueden verlo desde el cotizador web de Sura)',
			},
			cotizador: true,
		},
		credentials: {
			//"hide_dates": true,
			extra_legend: 'El período de fechas sólo aplica para la importación de Cobranzas',
			fields: [
				{
					field: 'username',
					label: 'Usuario (WCFAdminSEXXXX)',
				},
				{
					field: 'password',
					label: 'Contraseña',
				},
			],
		},
		// "force_prod_code": true
	},
	{
		id: 230,
		codigo: '0402',
		thumb: 'triunfo_60.png',
		value: 'triunfo',
		title: 'Triunfo',
		help: {
			//"datos_form": true,
			cotizador: {
				help: 'Tenés que configurar la importación para usar este cotizador. Si no querés configurar las importaciones, escribinos a soporte@adminse.com.ar',
			},
			html:
				"<p>Generá tus credenciales de webservices desde la web, tal como se muestra en la imágen. Luego ingresas esas credenciales en el cuadro de 'Cambiar Credenciales' y ejecutas la importación.</p>" +
				'<p>La clave que generes <b>no puede ser la misma que la clave de TriunfoNet.</b></p>',
			thumbs: [{ src: 'triunfo/triunfo-3.png' }],
		},
		ws_available: true,
		credentials: {
			min_desde: '01/11/2019',
			fields: [
				{
					field: 'username',
					label: 'Usuario',
				},
				{
					field: 'password',
					label: 'Contraseña',
				},
			],
		},
		force_prod_code: true,
	},
	{
		id: 500,
		codigo: '0741',
		thumb: 'tpc-60.png',
		value: 'tpc',
		title: 'TPC',
		help: {
			html: "<p>Ingresando en 'Descargas', realizan la búsqueda correspondiente, y luego exportan los resultados a Excel, como se muestra en las imágenes.</p>",
			thumbs: [{ src: 'tpc/tpc_1.png' }, { src: 'tpc/tpc_2.png' }],
		},
		allowedExtension: ['xls'],
	},
	{
		id: 600,
		codigo: '0841',
		thumb: 'tutelar-60.png',
		value: 'tutelar',
		title: 'Tutelar',
		help: {
			html: '<h3>Interfaz</h3><p>El archivo de importaci&oacute;n que usaremos para Tutelar es el archivo de datos que reciben vía mail, en formato TXT.</p>',
			/*"thumbs":[
                { "src": "triunfo/triunfo-1.png"},
                { "src": "triunfo/triunfo-2.png"}
    ]
    */
		},
		ws_available: false,
		allowedExtension: ['txt', 'xls'],
	},
	{
		id: 800,
		codigo: '0064',
		thumb: 'victoria-60.png',
		value: 'victoria',
		title: 'Victoria',
		help: {
			html: "<p>Ingresan en Victoria NET, van a 'Impresiones' -> 'Deben descargar el xml el rubrica que ofrece la compañia.</p>",
			thumbs: [{ src: 'victoria/victoria-1.png' }, { src: 'victoria/victoria-2.png' }],
		},
		allowedExtension: ['txt'],
	},
	{
		id: 930,
		codigo: '0228',
		thumb: 'zurich-60.png',
		value: 'zurich',
		title: 'Zurich',
		ws_available: true,
		help: {
			html: '<p>Nos podés detallar desde cuando queres importar la información histórica en un mail a soporte@adminse.com.ar (máximo 1 año)</p>'+
            '<p>El período de importación sólo se contempla para descarga de cobranzas, las operaciones se descargan regularmente una vez configurada la interfaz.</p>',
			webservice: true,
			datos_form: true,
			mail_ejecutivo: {
				extra_text: '<b>Si sos productor, tenes que solicitarle a tu Organizador que realice el pedido.</b>',
			},
			cotizador: true,
		},
		credentials: {
			//hide_dates: true,
			fields: [
				{
					field: 'username',
					label: 'Usuario',
				},
				{
					field: 'password',
					label: 'Contraseña',
				},
			],
		},
		// "force_prod_code": true
	},
	{
		id: 931,
		codigo: '0931',
		thumb: 'premiar-60.png',
		value: 'premiar',
		title: 'Premiar',
		help: {
			html: '<p>Se importa un archivo Excel</p>',
		},
		allowedExtension: ['xls'],
	},
	{
		id: 900,
		codigo: 'EXTRA',
		thumb: 'adminse_44.png',
		value: 'generico',
		title: 'Genérico',
		help: {
			html: '<p>Se importa un archivo Excel de un formato específico (solicitar a soporte)</p>',
		},
		allowedExtension: ['xls', 'csv', 'xlsx'],
	},
    {
        pais: 'Uruguay',
		codigo: 'GYG1',
		value: 'bse',
		title: 'BSE',
        thumb: 'bse-60.png',
        allowedExtension: ['xls'],
	},
    {
        pais: 'Uruguay',
		codigo: 'GYG14',
		value: 'hdi',
		title: 'HDI',
        thumb: 'hdi-60.png',
        allowedExtension: ['xlsx'],
	},
    {
        pais: 'Uruguay',
		codigo: 'GYG2',
		value: 'porto',
		title: 'Porto Seguro',
        thumb: 'porto-60.png',
        allowedExtension: ['xls'],
	},
    {
        pais: 'Uruguay',
		codigo: 'GYG3',
		value: 'mapfre',
		title: 'Mapfre',
        thumb: 'mapfre_60.png',
        allowedExtension: ['xlsx'],
	},
    {
        pais: 'Uruguay',
		codigo: 'GYG4',
		value: 'sura',
		title: 'Sura',
        thumb: 'sura-60.png',
        allowedExtension: ['xlsx'],
	},
    {
        pais: 'Uruguay',
		codigo: 'GYG20',
		value: 'sancor',
		title: 'Sancor',
        thumb: 'sancor-60.png',
        allowedExtension: ['xls'],
	},
];

export function getCiaLogoByCodigo (codigo) {
	const found_cia = importsCias.find((cc) => cc.codigo == codigo);
	return found_cia ? '/images/cias/' + found_cia.thumb : null;
}

export function getCiaTitleByCodigo (codigo) {
	const found_cia = importsCias.find((cc) => cc.codigo == codigo);
	return found_cia ? found_cia.title : null;
}
