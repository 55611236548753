<script setup>
import { getSymbol } from '@service/entities/LocalEntitiesService';
import { getLocale } from '@/i18n/localeService';
import { lateralToast } from '@service/MessageService';

const model = defineModel();
const moneda = defineModel('moneda', { default: 'ars' });
const placeholder = defineModel('placeholder', { required: false, default: '' });
const handleKeyDown = (event) => {
	if (['es-ar', 'es-uy'].includes(getLocale())) replaceDotWithComma(event);
};

const replaceDotWithComma = (event) => {
	if (event.key === '.') {
		event.preventDefault();
		const cursorPosition = event.target.selectionStart;
		const inputValue = event.target.value;
		const modifiedValue =
			inputValue.substring(0, cursorPosition) + ',' + inputValue.substring(cursorPosition + 1);
		event.target.value = modifiedValue;
		event.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
	}
};

const paste = async () => {
	await navigator.clipboard
		.readText()
		.then((cboard) => {
			const replaced = cboard.replace('.', '').replace(',', '.');
			if (!isNaN(replaced)) model.value = replaced;
		})
		.catch(
			() =>
				(lateralToast.value = {
					severity: 'error',
					summary: 'Es necesario que permita el uso del Clipboard para pegar el contenido copiado.',
					life: 4000,
				})
		);
};

const copy = async ({ target }) => {
	try {
		await navigator.clipboard.writeText(target.value);
	} catch (_) {
		lateralToast.value = {
			severity: 'error',
			summary: 'Es necesario que permita el uso del Clipboard para copiar.',
			life: 4000,
		};
	}
};
</script>

<template>
	<InputGroup>
		<InputNumber
			:locale="getLocale()"
			v-model:model-value="model"
			:max-fraction-digits="2"
			@keydown="handleKeyDown"
			@keydown.ctrl.v="paste"
			@keydown.ctrl.c="copy"
			:placeholder="placeholder"
		/>
		<InputGroupAddon>{{ getSymbol(moneda) }}</InputGroupAddon>
	</InputGroup>
</template>
