export const entities = () => {
	return {
		tipos_uso_automotor: {
			particular: 'Particular',
			comercial: 'Comercial',
			comercial_carga_peligrosa: 'Comercial carga peligrosa',
			comercial_servicio_especial: 'Comercial servicio especial',
		},
		tipos_carroceria_automotor: {
			cuatro_por_dos: '4X2',
			cuatro_por_cuatro: '4X4',
			abierta: 'Abierta',
			ambulancia: 'Ambulancia',
			baranda: 'Baranda',
			capota: 'C/Capota',
			cupula: 'C/Cupula',
			grua: 'C/Grua',
			camion: 'Camion',
			camion_bacheador: 'Camion Bacheador',
			camon_barandas: 'Camon C/Barandas',
			camion_caja_cerealera: 'Camion Caja Cerealera',
			camion_carrozado: 'Camion Carrozado',
			camion_con_caja: 'Camion Con Caja',
			camion_furg_termico: 'Camion Furg. Termico',
			camion_grua: 'Camion Grua',
			camion_mezclador: 'Camion Mezclador',
			camion_plataforma: 'Camion Plataforma',
			camion_playo: 'Camion Playo',
			camion_regador: 'Camion Regador',
			camion_tractor: 'Camion Tractor',
			camion_volcador: 'Camion Volcador',
			camion_balancin: 'Camion Balancin',
			carrozada: 'Carrozada',
			cerrada: 'Cerrada',
			convertible: 'Convertible',
			coupe: 'Coupe',
			cuatriciclo: 'Cuatriciclo',
			chasis_cabina: 'Chasis C /Cabina ',
			doble_cabina: 'Doble Cabina',
			furgon: 'Furgon',
			furgon_de_fabrica: 'Furgon De Fabrica',
			furgon_termico: 'Furgon Termico',
			jeep_cam_fam: 'Jeep / Cam. Fam.',
			minibus: 'Minibus',
			motocicleta: 'Motocicleta',
			pick_up_clase_a: 'Pick Up Clase A',
			pick_up_clase_b: 'Pick Up Clase B',
			pick_up_cab_y_media: 'Pick Up Cab Y Media',
			pick_up_carrozada: 'Pick Up Carrozada',
			playa: 'Playa',
			portacorona: 'Portacorona',
			rural: 'Rural',
			sedan: 'Sedan',
			semi_remolque: 'Semi Remolque',
			tractor_carretera: 'Tractor De Carretera',
			trailer: 'Trailer',
			microbus: 'Microbus',
			casa_rodante: 'Casa Rodante',
			carreton: 'Carreton',
			camion_menor_10_tn: 'Camion menor 10 tn',
			camion_mayor_10_tn: 'Camion mayor 10 tn',
			bus: 'Bus',
			bantam: 'Bantam',
			autoelevador: 'Autoelevador',
			acoplado: 'Acoplado',
			jeep: 'Jeep',
		},
		tipos_vehiculo: {
			auto: 'Auto',
			moto: 'Moto',
			camion: 'Camion',
			maquinaria: 'Maquinaria',
			remolque: 'Remolque',
		},
		tipos_origen_vehiculo: {
			nacional: 'Nacional',
			importado: 'Importado',
		},
		tipos_documento: {
			dni: 'DNI',
			cuit: 'CUIT/CUIL',
			ci: 'Cédula de identidad',
			pasaporte: 'Pasaporte',
			lc: 'Libreta cívica',
			le: 'Libreta de enrolamiento',
            rut: 'RUT'
		},
		tipos_operacion: {
			nuevo_seguro: 'Seguro nuevo',
			renovacion: 'Renovación',
			endorsement: 'Endoso',
			anulacion: 'Anulación',
			modificacion_registro: 'Modificación de registro',
			anulacion_registro: 'Anulación de registro',
		},
		tipos_profesion: {
			abogado: 'Abogado/a',
			academico: 'Académico/a',
			adjunto: 'Adjunto/a',
			administrador: 'Administrador/a',
			agronomo: 'Agrónomo/a',
			alergista: 'Alergista/a',
			alergologa: 'Alergóloga/a',
			almacenero: 'Almacenero/a',
			anatomista: 'Anatomista/a',
			anestesiologo: 'Anestesiólogo/a',
			antologista: 'Antologista/a',
			antropologo: 'Antropólogo/a',
			arabista: 'Arabista',
			archivero: 'Archivero/a',
			arqueologo: 'Arqueólogo/a',
			arquitecto: 'Arquitecto/a',
			asesor: 'Asesor/a',
			asistente: 'Asistente/a',
			astrofisico: 'Astrofísico/a',
			astrologo: 'Astrólogo/a',
			astronomo: 'Astrónomo/a',
			atleta: 'Atleta/a',
			ats: 'ATS',
			autor: 'Autor/a',
			auxiliar: 'Auxiliar',
			avicultor: 'Avicultor/a',
			bacteriologo: 'Bacteriólogo/a',
			bedel: 'Bedel/a',
			bibliografo: 'Bibliógrafo/a',
			bibliotecario: 'Bibliotecario/a',
			biofisico: 'Biofísico/a',
			biografo: 'Biógrafo/a',
			biologo: 'Biólogo/a',
			bioquimico: 'Bioquímico/a',
			botanico: 'Botánico/a',
			cancerologo: 'Cancerólogo/a',
			cardiologo: 'Cardiólogo/a',
			cartografo: 'Cartógrafo/a',
			castrador: 'Castrador/a',
			catedratico: 'Catedrático/a',
			cirujano: 'Cirujano/a',
			citologo: 'Citólogo/a',
			climatologo: 'Climatólogo/a',
			codirector: 'Codirector/a',
			comadron: 'Comadrón/a',
			consejero: 'Consejero/a',
			conserje: 'Conserje',
			conservador: 'Conservador/a',
			coordinador: 'Coordinador/a',
			cosmografo: 'Cosmógrafo/a',
			cosmologo: 'Cosmólogo/a',
			criminalista: 'Criminalista',
			cronologo: 'Cronólogo/a',
			decano: 'Decano/a',
			decorador: 'Decorador/a',
			defensor: 'Defensor/a',
			delegado: 'Delegado/a',
			delineante: 'Delineante/a',
			demografo: 'Demógrafo/a',
			dentista: 'Dentista/a',
			dermatologo: 'Dermatólogo/a',
			dibujante: 'Dibujante',
			directivo: 'Directivo/a',
			director: 'Director/a',
			dirigente: 'Dirigente',
			doctor: 'Doctor/a',
			documentalista: 'Documentalista',
			ecologo: 'Ecólogo/a',
			economista: 'Economista',
			educador: 'Educador/a',
			egiptologo: 'Egiptólogo/a',
			endocrinologo: 'Endocrinólogo/a',
			enfermero: 'Enfermero/a',
			enologo: 'Enólogo/a',
			entomologo: 'Entomólogo/a',
			epidemiologo: 'Epidemiólogo/a',
			especialista: 'Especialista',
			espeleologo: 'Espeleólogo/a',
			estadista: 'Estadista',
			estadistico: 'Estadístico/a',
			etimologo: 'Etimólogo/a',
			etnografo: 'Etnógrafo/a',
			etnologo: 'Etnólogo/a',
			etologo: 'Etólogo/a',
			examinador: 'Examinador/a',
			facultativo: 'Facultativo/a',
			farmaceutico: 'Farmacéutico/a',
			farmacologo: 'Farmacólogo/a',
			filologo: 'Filólogo/a',
			filosofo: 'Filósofo/a',
			fiscal: 'Fiscal',
			fisico: 'Físico/a',
			fisiologo: 'Fisiólogo/a',
			fisioterapeuta: 'Fisioterapeuta/a',
			fonetista: 'Fonetista/a',
			foniatra: 'Foníatra/a',
			fonologo: 'Fonólogo/a',
			forense: 'Forense',
			fotografo: 'Fotógrafo/a',
			funcionario: 'Funcionario/a',
			gemologo: 'Gemólogo/a',
			genetista: 'Genetista',
			geobotanica: 'Geobotánica/a',
			geodesta: 'Geodesta',
			geofisico: 'Geofísico/a',
			geografo: 'Geógrafo/a',
			geologo: 'Geólogo/a',
			geomantico: 'Geomántico/a',
			geoquimica: 'Geoquímica/a',
			gerente: 'Gerente',
			geriatra: 'Geriatra',
			gerontologo: 'Gerontólogo/a',
			gestor: 'Gestor/a',
			grabador: 'Grabador/a',
			graduado: 'Graduado/a',
			grafologo: 'Grafólogo/a',
			gramatico: 'Gramático/a',
			hematologo: 'Hematólogo/a',
			hepatologo: 'Hepatólogo/a',
			hidrogeologo: 'Hidrogeólogo/a',
			hidrografo: 'Hidrógrafo/a',
			hidrologo: 'Hidrólogo/a',
			higienista: 'Higienista/a',
			hispanista: 'Hispanista/a',
			historiador: 'Historiador/a',
			homeopata: 'Homeópata/a',
			informatico: 'Informático/a',
			ingeniero: 'Ingeniero/a',
			inmunologo: 'Inmunólogo/a',
			inspector: 'Inspector/a',
			interino: 'Interino/a',
			interventor: 'Interventor/a',
			investigador: 'Investigador/a',
			jardinero: 'Jardinero/a',
			jefe: 'Jefe',
			jubilado: 'Jubilado/a',
			juez: 'Juez',
			latinista: 'Latinista/a',
			lector: 'Lector/a',
			letrado: 'Letrado/a',
			lexicografo: 'Lexicógrafo/a',
			lexicologo: 'Lexicólogo/a',
			licenciado: 'Licenciado/a',
			linguista: 'Lingüista/a',
			logopeda: 'Logopeda/a',
			maestro: 'Maestro/a',
			matematico: 'Matemático/a',
			matron: 'Matrón/a',
			medico: 'Médico/a',
			meteorologo: 'Meteorólogo/a',
			micologo: 'Micólogo/a',
			microbiologico: 'Microbiológico/a',
			microcirujano: 'Microcirujano/a',
			mimografo: 'Mimógrafo/a',
			mineralogista: 'Mineralogista/a',
			monitor: 'Monitor/a',
			musicologo: 'Musicólogo/a',
			naturopata: 'Naturópata/a',
			nefrologo: 'Nefrólogo/a',
			neumologo: 'Neumólogo/a',
			neuroanatomista: 'Neuroanatomista/a',
			neurobiologo: 'Neurobiólogo/a',
			neurocirujano: 'Neurocirujano/a',
			neuroembriologo: 'Neuroembriólogo/a',
			neurofisiologo: 'Neurofisiólogo/a',
			neurologo: 'Neurólogo/a',
			nutrologo: 'Nutrólogo/a',
			oceanografo: 'Oceanógrafo/a',
			odontologo: 'Odontólogo/a',
			oficial: 'Oficial',
			oficinista: 'Oficinista',
			oftalmologo: 'Oftalmólogo/a',
			oncologo: 'Oncólogo/a',
			optico: 'Óptico/a',
			optometrista: 'Optometrista/a',
			ordenanza: 'Ordenanza/a',
			orientador: 'Orientador/a',
			ornitologo: 'Ornitólogo/a',
			ortopedico: 'Ortopédico/a',
			ortopedista: 'Ortopedista',
			osteologo: 'Osteólogo/a',
			osteopata: 'Osteópata',
			otorrinolaringologo: 'Otorrinolaringólogo/a',
			paleobiologo: 'Paleobiólogo/a',
			paleobotanico: 'Paleobotánico/a',
			paleografo: 'Paleógrafo/a',
			paleologo: 'Paleólogo/a',
			paleontologo: 'Paleontólogo/a',
			patologo: 'Patólogo/a',
			pedagogo: 'Pedagogo/a',
			pediatra: 'Pediatra',
			pedicuro: 'Pedicuro/a',
			periodista: 'Periodista',
			tecnico: 'Técnico/a',
			piscicultor: 'Piscicultor/a',
			podologo: 'Podólogo/a',
			portero: 'Portero/a',
			prehistoriador: 'Prehistoriador/a',
			presidente: 'Presidente/a',
			proctologo: 'Proctólogo/a',
			profesor: 'Profesor/a',
			programador: 'Programador/a',
			protesico: 'Protésico/a',
			proveedor: 'Proveedor/a',
			psicoanalista: 'Psicoanalista',
			psicologo: 'Psicólogo/a',
			psicofisico: 'Psicofísico/a',
			psicopedagogo: 'Psicopedagogo/a',
			psicoterapeuta: 'Psicoterapeuta',
			psiquiatra: 'Psiquiatra',
			publicista: 'Publicista',
			publicitario: 'Publicitario/a',
			puericultor: 'Puericultor/a',
			quimico: 'Químico/a',
			quiropractico: 'Quiropráctico/a',
			radioastronomo: 'Radioastrónomo/a',
			radiofonista: 'Radiofonista',
			radiologo: 'Radiólogo/a',
			radiotecnico: 'Radiotécnico/a',
			radiotelefonista: 'Radiotelefonista/a',
			radiotelegrafista: 'Radiotelegrafista',
			radioterapeuta: 'Radioterapeuta/a',
			rector: 'Rector/a',
			retirado: 'Retirado/a',
			sanitario: 'Sanitario/a',
			secretario: 'Secretario/a',
			sexologo: 'Sexólogo/a',
			sismologo: 'Sismólogo/a',
			sociologo: 'Sociólogo/a',
			subdelegado: 'Subdelegado/a',
			subdirector: 'Subdirector/a',
			subsecretario: 'Subsecretario/a',
			tecnico: 'Técnico/a',
			telefonista: 'Telefonista/a',
			teologo: 'Teólogo/a',
			terapeuta: 'Terapeuta',
			tocoginecologo: 'Tocoginecólogo/a',
			tocologo: 'Tocólogo/a',
			toxicologo: 'Toxicólogo/a',
			traductor: 'Traductor/a',
			transcriptor: 'Transcriptor/a',
			traumatologo: 'Traumatólogo/a',
			tutor: 'Tutor/a',
			urologo: 'Urólogo/a',
			veterinario: 'Veterinario/a',
			vicedecano: 'Vicedecano/a',
			vicedirector: 'Vicedirector/a',
			vicegerente: 'Vicegerente/a',
			vicepresidente: 'Vicepresidente/a',
			vicerrector: 'Vicerrector/a',
			vicesecretario: 'Vicesecretario/a',
			virologo: 'Virólogo/a',
			viticultor: 'Viticultor/a',
			vulcanologo: 'Vulcanólogo/a',
			xilografo: 'Xilógrafo/a',
			zoologo: 'Zoólogo/a',
			zootecnico: 'Zootécnico/a',
			comerciante: 'Comerciante',
			empleado: 'Empleado/a',
			contador: 'Contador/a',
		},
		tipos_estado_civil: {
			soltero: 'Soltero/a',
			casado: 'Casado/a',
			divorciado: 'Divorciado/a',
			viudo: 'Viudo/a',
			separado: 'Separado/a',
			conviviente: 'Conviviente',
		},
		tipos_direccion: {
			personal: 'Personal',
			laboral: 'Laboral',
			cobranza: 'Cobranza',
			correspondencia: 'Correspondencia',
		},
		paises: [
			'Aruba',
			'Afganistán',
			'Angola',
			'Anguila',
			'Islas Áland',
			'Albania',
			'Andorra',
			'Antillas Neerlandesas',
			'Emiratos Árabes Unidos',
			'Argentina',
			'Armenia',
			'Samoa Americana',
			'Antártida',
			'Territorios Australes Franceses',
			'Antigua y Barbuda',
			'Australia',
			'Austria',
			'Azerbaiyán',
			'Burundi',
			'Bélgica',
			'Benin',
			'Burkina Faso',
			'Bangladesh',
			'Bulgaria',
			'Bahréin',
			'Bahamas',
			'Bosnia y Herzegovina',
			'San Bartolomé',
			'Belarús',
			'Belice',
			'Bermudas',
			'Bolivia',
			'Brasil',
			'Barbados',
			'Brunéi',
			'Bhután',
			'Isla Bouvet',
			'Botsuana',
			'República Centro-Africana',
			'Canadá',
			'Islas Cocos',
			'Suiza',
			'Chile',
			'China',
			'Costa de Marfil',
			'Camerún',
			'Congo',
			'Islas Cook',
			'Colombia',
			'Comoros',
			'Cabo Verde',
			'Costa Rica',
			'Cuba',
			'Islas Christmas',
			'Islas Caimán',
			'Chipre',
			'República Checa',
			'Alemania',
			'Yibuti',
			'Domínica',
			'Dinamarca',
			'República Dominicana',
			'Argel',
			'Ecuador',
			'Egipto',
			'Eritrea',
			'Sahara Occidental',
			'España',
			'Estonia',
			'Etiopía',
			'Finlandia',
			'Fiji',
			'Francia',
			'Islas Faroe',
			'Micronesia',
			'Gabón',
			'Reino Unido',
			'Georgia',
			'Guernsey',
			'Ghana',
			'Gibraltar',
			'Guinea',
			'Guadalupe',
			'Gambia',
			'Guinea-Bissau',
			'Guinea Ecuatorial',
			'Grecia',
			'Granada',
			'Groenlandia',
			'Guatemala',
			'Guayana Francesa',
			'Guam',
			'Guayana',
			'Hong Kong',
			'Islas Heard y McDonald',
			'Honduras',
			'Croacia',
			'Haití',
			'Hungría',
			'Indonesia',
			'Isla de Man',
			'India',
			'Territorio Británico del Océano Índico',
			'Irlanda',
			'Irán',
			'Irak',
			'Islandia',
			'Israel',
			'Italia',
			'Jamaica',
			'Jersey',
			'Jordania',
			'Japón',
			'Kazajstán',
			'Kenia',
			'Kirguistán',
			'Camboya',
			'Kiribati',
			'Islas Malvinas',
			'San Cristóbal y Nieves',
			'Corea del Sur',
			'Kuwait',
			'Laos',
			'Líbano',
			'Liberia',
			'Libia',
			'Santa Lucía',
			'Liechtenstein',
			'Sri Lanka',
			'Lesotho',
			'Lituania',
			'Luxemburgo',
			'Letonia',
			'Macao',
			'Marruecos',
			'Mónaco',
			'Moldova',
			'Madagascar',
			'Maldivas',
			'México',
			'Islas Marshall',
			'Macedonia',
			'Mali',
			'Malta',
			'Myanmar',
			'Montenegro',
			'Mongolia',
			'Mozambique',
			'Mauritania',
			'Montserrat',
			'Martinica',
			'Mauricio',
			'Malawi',
			'Malasia',
			'Mayotte',
			'Namibia',
			'Nueva Caledonia',
			'Níger',
			'Islas Norkfolk',
			'Nigeria',
			'Nicaragua',
			'Niue',
			'Países Bajos',
			'Noruega',
			'Nepal',
			'Nauru',
			'Nueva Zelanda',
			'Omán',
			'Pakistán',
			'Panamá',
			'Islas Pitcairn',
			'Perú',
			'Filipinas',
			'Islas Palaos',
			'Papúa Nueva Guinea',
			'Polonia',
			'Puerto Rico',
			'Corea del Norte',
			'Portugal',
			'Paraguay',
			'Palestina',
			'Polinesia Francesa',
			'Qatar',
			'Reunión',
			'Rumanía',
			'Rusia',
			'Ruanda',
			'Arabia Saudita',
			'Sudán',
			'Senegal',
			'Singapur',
			'Georgia del Sur e Islas Sandwich del Sur',
			'Santa Elena',
			'Islas Svalbard y Jan Mayen',
			'Islas Solomón',
			'Sierra Leona',
			'El Salvador',
			'San Marino',
			'Somalia',
			'San Pedro y Miquelón',
			'Serbia y Montenegro',
			'Santo Tomé y Príncipe',
			'Surinam',
			'Eslovaquia',
			'Eslovenia',
			'Suecia',
			'Suazilandia',
			'Seychelles',
			'Siria',
			'Islas Turcas y Caicos',
			'Chad',
			'Togo',
			'Tailandia',
			'Tayikistán',
			'Tokelau',
			'Turkmenistán',
			'Timor-Leste',
			'Tonga',
			'Trinidad y Tobago',
			'Túnez',
			'Turquía',
			'Tuvalu',
			'Taiwán',
			'Tanzania',
			'Uganda',
			'Ucrania',
			'Uruguay',
			'Estados Unidos de América',
			'Uzbekistán',
			'Ciudad del Vaticano',
			'San Vicente y las Granadinas',
			'Venezuela',
			'Islas Vírgenes Británicas',
			'Islas Vírgenes de los Estados Unidos de América',
			'Vietnam',
			'Vanuatu',
			'Wallis y Futuna',
			'Samoa',
			'Yemen',
			'Sudáfrica',
		],
		tipos_iva: {
			monotributista: 'Monotributista',
			responsable_inscripto: 'Responsable Inscripto',
			exento: 'Exento',
			consumidor_final: 'Consumidor final',
			no_alcanzado: 'No alcanzado',
			gran_contribuyente: 'Gran Contribuyente',
		},
		tipos_persona: {
			fisica: 'Física',
			juridica: 'Jurídica',
			consorcio: 'Consorcio',
		},
		tipos_sexo: {
			hombre: 'Masculino',
			mujer: 'Femenino',
		},
		tomador_asegurado_options: {
			tomador: 'Tomador',
			asegurado: 'Asegurado',
		},
		potencial_captivo_options: {
			potencial: 'Potencial',
			captivo: 'Captivo',
            never_client: 'Nunca tuvo pólizas'
		},
		tipos_accion: {
			update: 'Actualización',
			create: 'Creación',
			destroy: 'Borrado',
			unify: 'Unificación',
            freeze: 'Bloqueada',
            unfreeze: 'Desbloqueada',
            inactivate: 'Desactivación usuario',
            update_permissions: 'Actualizacion de permisos'
		},
		tipos_entidad: {
			policy: 'Póliza',
			person: 'Cliente',
			ticket: 'Seguimiento',
			siniestro: 'Siniestro',
			endorsement: 'Endoso',
			originalpolicy: 'Póliza',
            companyoperationcode: 'Código de Productor'
		},
		tipo_origen_cheque: {
			propio: 'Propio',
			ajeno: 'Ajeno',
		},

		tipo_estado_cheque: {
			cartera: 'Cartera',
			aplicado: 'Aplicado',
			rechazado: 'Rechazado',
		},
		tipo_seguimiento: {
			reminder: 'Recordatorio',
			llamado: 'Llamado',
			sell_oportunity: 'Oportunidad de venta',
			siniestro: 'Siniestro',
			otro: 'Otro',
			visita: 'Visita',
			envio_doc: 'Envío de documentación',
			gestion_cobranza: 'Gestión de cobranza',
			pedido_info: 'Pedido de información',
            reunion: 'Reunion'
		},
	};
};
