<script setup>
import { ref } from 'vue';
import { searchDefault } from '@service/AxiosBaseService';
import { defaultFilter, resultKey, resourceName } from '@service/PeopleService';
import { useTipoIvaTranslation, useTipoDocTranslation } from '@service/entities/LocalEntitiesService';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
	modelValue: Object | String,
	hasLabel: { type: Boolean, default: true },
	dataKey: {
		type: String,
		default: 'id',
	},
	field: {
		type: [String, Function],
		default: 'nombre',
	},
	label: {
		type: String,
		required: false,
	},
	showDocument: {
		type: Boolean,
		default: false,
	},
	extraClass: {
		type: [String, Object],
		required: false,
	},
	showClear: Boolean,
	filters: {
		type: Object,
		required: false,
	},
});

const labelWithDefault = props.label || t('shared.labels.asegurado');

const suggestions = ref([]);

function searchRemote(event) {
	searchDefault(event.query, resourceName, { ...defaultFilter, ...props.filters })
		.then((response) => {
			suggestions.value = response.data[resultKey];
		})
		.catch((error) => {
			console.log(error);
		});
}

const emit = defineEmits(['update:modelValue', 'person-selected']);
</script>

<template>
	<span class="p-float-label flex">
		<AutoComplete
			:id="resultKey"
			class="flex-grow-1"
			:class="extraClass"
			:modelValue="modelValue"
			:suggestions="suggestions"
			:dataKey="dataKey"
			:optionLabel="field"
			v-bind="$attrs"
			dropdown
			forceSelection
			@change="emit('update:modelValue', $event.value)"
			@item-select="emit('person-selected', $event.value)"
			@complete="searchRemote($event)"
		>
			<template #option="slotProps">
				<div>
					{{ slotProps.option.nombre }}
					<span v-if="showDocument">
						<div v-for="(document, index) in slotProps.option.documents">
							<div v-if="index === 0">
								{{ useTipoDocTranslation(document.tipo_documento) }} {{ document.numero }}
							</div>
						</div>
					</span>
					({{
						slotProps.option.tipo_condicion_iva
							? useTipoIvaTranslation(slotProps.option.tipo_condicion_iva)
							: 'sin condición de IVA'
					}})
				</div>
			</template>
		</AutoComplete>
		<Button
			v-show="modelValue && showClear"
			class="w-1 h-full absolute unstyled"
			icon="pi pi-times"
			severity="secondary"
			text
			@click="$emit('update:modelValue', '')"
		/>
		<label v-if="hasLabel" :for="resultKey">{{ labelWithDefault }}</label>
	</span>
</template>

<style scoped>
.unstyled {
	right: 4em;
}

.unstyled:hover,
.unstyled:active,
.unstyled:focus {
	background-color: transparent;
}
</style>
