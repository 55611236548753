import { stats } from './gestion/stats';
import { events } from './gestion/events';
import { inicio } from './gestion/inicio';

export const gestion = () => {
	return {
		inicio: inicio(),
		events: events(),
		stats: stats(),
	};
};
