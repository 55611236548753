import { administrar } from './modules/administrar';
import { app } from './modules/app';
import { cotizador } from './modules/cotizador';
import { crm } from './modules/crm';
import { importaciones } from './modules/importaciones';
import { gestion } from './modules/gestion';
import { polizas } from './modules/polizas';
import { reportes } from './modules/reportes';
import { rubrica } from './modules/rubrica';
import { siniestros } from './modules/siniestros';
import { tesoreria } from './modules/tesoreria';
import { unauthorized } from './modules/unauthorized';
import { addEditForms } from './shared/addEditForms';
import { adjuntos } from './shared/adjuntos';
import { buttons } from './shared/buttons';
import { datatables } from './shared/datatables';
import { entities } from './shared/entities';
import { fields } from './shared/fields';
import { labels } from './shared/labels';
import { messages } from './shared/messages';
import { searchForms } from './shared/searchForms';

export const es = () => {
	return {
		app: app(),
		crm: crm(),
        polizas: polizas(),
		cotizador: cotizador(),
		administrar: administrar(),
		importaciones: importaciones(),
		gestion: gestion(),
		reportes: reportes(),
		rubrica: rubrica(),
		siniestros: siniestros(),
		tesoreria: tesoreria(),
		unauthorized: unauthorized(),
		shared: {
            messages: messages(),
			addEditForms: addEditForms(),
			searchForms: searchForms(),
			datatables: datatables(),
			buttons: buttons(),
			labels: labels(),
			fields: fields(),
			adjuntos: adjuntos(),
			entities: entities(),
		},
	};
};
