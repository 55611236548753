import { useDelete, useGet } from '@service/AxiosBaseService';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useNotificationStore = defineStore('notification', () => {
	const bellNotifications = ref([]);
	const totalNotifications = ref([]);

	function loadBellNotifications(toast) {
		useGet('/api/bell_notifications')
			.then((response) => {
				const newNotifications = response.data.bell_notification.filter(
					(bn) => bn.bell_notificable && !hasNotification(bn)
				);

				newNotifications.forEach((bn) => {
					switch (bn.bell_notificable.type) {
						case 'ticket':
							totalNotifications.value.push({
								id: bn.id,
								type: bn.bell_notificable.type,
								entity_id: bn.bell_notificable.ticket.id,
								pi_icon: 'pi-comment',
								title: `Nuevo ticket para ${bn.user.user_nombre}<br><br><b>${bn.bell_notificable.ticket.titulo}</b>`,
							});
							break;
						case 'ticket_comment':
							totalNotifications.value.push({
								id: bn.id,
								type: bn.bell_notificable.type,
								entity_id: bn.bell_notificable.ticket_comment.ticket_id,
								pi_icon: 'pi-comments',
								title: `Comentario en ticket para ${bn.user.user_nombre}<br><br><b>${bn.bell_notificable.ticket_comment.detalle}</b>`,
							});
							break;
					}
				});

				if (newNotifications.length > 0) {
					toast.add({
						severity: 'success',
						summary: 'Notificaciones',
						detail: 'Tenés nuevas notificaciones en el sistema',
						life: 3000,
					});
				}
			})
			.catch((error) => {
				console.error(error);
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Error buscando notificaciones',
					life: 3000,
				});
			});
	}

	function hasNotification(notification) {
		return totalNotifications.value.some((tn) => tn.id === notification.id);
	}

	function removeBellNotification(id) {
		useDelete('api/bell_notifications/' + id, {})
			.then((response) => {
				totalNotifications.value = totalNotifications.value.filter((tn) => tn.id !== id);
			})
			.catch((error) => {
				console.log(error);
				toast.add({ severity: 'error', summary: 'Error', detail: 'Error buscando notificación', life: 3000 });
			});
	}

	function loadCumpleanios(toast) {
		useGet('/api/dashboard_report/cumpleanios_summary')
			.then((response) => {
				if (response.data.value && response.data.value > 0) {
					totalNotifications.value.push({
						id: 'cumpleanios',
						title:
							response.data.value == 1
								? '1 Cliente próximo a su cumpleaños'
								: response.data.value + ' clientes próximos a su cumpleaños',
						pi_icon: 'pi-user-plus',
						type: 'birthday',
					});
				}
			})
			.catch((error) => {
				console.log(error);
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Error buscando cumpleaños próximos',
					life: 3000,
				});
			});
	}

	function loadRenovaciones(toast) {
		useGet('/api/dashboard_report/renovaciones_summary')
			.then((response) => {
				if (response.data.value && response.data.value > 0) {
					totalNotifications.value.push({
						id: 'renovations',
						title:
							response.data.value == 1
								? '1 renovación próxima'
								: response.data.value + ' renovaciones próximas',
						pi_icon: 'pi-replay',
						type: 'renovation',
					});
				}
			})
			.catch((error) => {
				console.log(error);
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Error buscando próximas renovaciones',
					life: 3000,
				});
			});
	}

	function loadPropuestas(toast) {
		useGet('/api/dashboard_report/propuestas_summary')
			.then((response) => {
				if (response.data.value && response.data.value > 0) {
					totalNotifications.value.push({
						id: 'proposals',
						title:
							response.data.value == 1
								? '1 propuesta sin emitir'
								: response.data.value + ' propuestas sin emitir',
						pi_icon: 'pi-file-edit',
						type: 'proposal',
					});
				}
			})
			.catch((error) => {
				console.log(error);
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Error buscando propuestas pendientes de emisión',
					life: 3000,
				});
			});
	}

	function loadRefacturaciones(toast) {
		useGet('/api/dashboard_report/refacturaciones_summary')
			.then((response) => {
				if (response.data.value && response.data.value > 0) {
					totalNotifications.value.push({
						id: 'refacturations',
						title:
							response.data.value == 1
								? '1 refacturación próxima'
								: response.data.value + ' refacturaciones próximas',
						pi_icon: 'pi-reply',
						type: 'refacturation',
					});
				}
			})
			.catch((error) => {
				console.log(error);
				toast.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Error buscando próximas refacturaciones',
					life: 3000,
				});
			});
	}

	function loadTicketsPendientes(toast) {
		useGet('/api/dashboard_report/tickets_summary')
			.then((response) => {
				if (response.data.value && response.data.value > 0) {
					totalNotifications.value.push({
						id: 'tickets',
						title:
							response.data.value == 1
								? '1 ticket abierto próximo a su fecha de recordatorio'
								: response.data.value + ' tickets abiertos próximos a su fecha de recordatorio',
						pi_icon: 'pi-tag',
						type: 'ticket',
					});
				}
			})
			.catch((error) => {
				console.log(error);
				toast.add({ severity: 'error', summary: 'Error', detail: 'Error buscando tickets', life: 3000 });
			});
	}

	return {
		loadBellNotifications,
		removeBellNotification,
		loadCumpleanios,
		loadRenovaciones,
		loadRefacturaciones,
		loadPropuestas,
		loadTicketsPendientes,
		totalNotifications,
		bellNotifications,
	};
});
