import { createFilter } from '@helpers/filtersHelper';
import { useGetAll } from '@service/AxiosBaseService';
import { ref } from 'vue';

export const resourceName = '/api/producers/all';
export const resultKey = 'producer';

export const filters = ref({
	nombre: createFilter('nombre', 'string', 'like', 'name'),
});

export const defaultFilter = {
	nombre: createFilter('nombre', 'string', 'like', 'name'),
};

export const getProducers = async () => {
	const response = await useGetAll({ first: 0, rows: 100, filters: filters.value }, resourceName);
	return response.data[resultKey];
};
