import { ref } from 'vue';
import { createFilter } from '@helpers/filtersHelper';

export const resourceName = '/api/ramos';
export const resultKey = 'ramo';

export const filters = ref({
	denominacion: createFilter('denominacion', 'string', 'like', 'denominacion'),
});

export const defaultFilter = {
	denominacion: createFilter('denominacion', 'string', 'like', 'denominacion'),
};
