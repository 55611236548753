export const app = () => {
	return {
		topbar: {
            debtMessage: 'Factura de pago disponible. Podés abonar desde el sistema en el menú Administrar -> Suscripción',
			cropDialog: {
				title: 'Recortar imágen',
			},
			logo: {
				success: 'Logo actualizado',
				error: 'Error subiendo logo',
			},
			foto: {
				success: 'Foto actualizada',
				error: 'Error subiendo foto',
			},
			chooseLabel: 'Adjuntar archivo',
		},
	};
};
