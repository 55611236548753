export const cotizar = () => {
	return {
		toast: {
			error: { summary: 'Error', detail: 'Error cotizando asistencia al viajero' },
		},
		title: 'Asistencia al viajero',
		subtitle: 'Cotizar con PAX y Europ Assistance',
		labels: {
			pais: 'País de Residencia',
			destino: 'Destino',
			salida: 'Salida',
			regreso: 'Regreso',
			kids: 'Hasta 20 años',
			adults: 'De 21 a 75 años',
			elder: 'De 76 a 85 años',
			old_hopes: 'De 86 a 90 años',
		},
		buttons: {
            detail: 'Detalle',
			pasajeros: 'Pasajeros ({cantidad})',
		},
	};
};
