export const coberturas = () => {
	return {
		add: 'Agregar Cobertura por Ramo',
		search: 'Busqueda de Cobertura',
		edit: 'Editar Cobertura',
		contextMenu: {
			edit: 'Editar Cobertura',
			delete: 'Borrar Cobertura',
		},
		toast: {
			load: {
				error: 'Error cargando coberuras.',
			},
			delete: {
				success: 'Cobertura eliminada.',
				error: 'Error: La cobertura no pudo eliminarse. Pruebe más tarde.',
			},
		},
		confirm: '¿Está seguro que desea borrar esta cobertura?',
		datatable: {
			button: {
				add: 'Agregar Cobertura por Ramo',
			},
		},
	};
};
