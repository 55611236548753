export const comisiones_pendientes = () => {
	return {
		toast: {
			error: {
				summary: 'Error',
				detail: {
					search: 'Error buscando pendientes',
					generate: 'Error al generar Preliquidación',
					edit: 'Error al editar la comisión',
				},
			},
			success: {
				summary: 'Preliquidación generada',
				detail: {
					generate: 'La preliquidación ha sido generada correctamente',
				},
			},
		},
		contextMenu: {
			agregar: 'Agregar a Preliq. existente',
			editar: 'Editar Comisión',
			agrupado_por: 'Agrupado por {agrupado}',
		},
		sideBarTitle: 'Búsqueda de Comisiones Pendientes',
		title: 'Comisiones - Pendientes',
		importe: 'Subtotal Importe',
		labels: {
            en_preliquidacion: 'En preliquidación {numero}',
			numero_preliquidacion: 'Número Preliquidación',
			cambio_dolar: 'Tipo Cambio Dólar',
			cambio_euro: 'Tipo Cambio Euro',
			cambio: 'Tipo de Cambio',
			fecha_vencimiento: 'Fecha Vencimiento',
		},
		buttons: { generar: 'Generar Preliquidación' },
		confirm: {
			header: 'Agregar comisión en Preliquidación',
			message: '¿Continuar?',
		},
		confirmGenerarPlural: `Se generarán las preliquidaciones correspondientes por { numero } registros pendientes de la búsqueda.`,
		confirmGenerarSingular: `Se generará la preliquidación correspondiente a { numero } registro pendiente de la búsqueda.`,
		confirmContinuar: `¿Desea Continuar?`,
	};
};
