<script setup>
import SearchForm from '@components/shared/SearchForm.vue';
const emit = defineEmits(['searchForm', 'closeForm']);

const props = defineProps({
	sidebarClass: {
		type: String,
		default: 'p-sidebar-md',
		required: false,
	},
	visible: {
		type: Boolean,
		required: true,
	},
	position: {
		type: 'left' | 'right' | 'top' | 'bottom' | 'full',
		required: false,
		validator: (value) => {
			return ['left', 'right', 'top', 'bottom', 'full'].includes(value);
		},
	},
	header: {
		required: false,
		title: {
			type: String,
			required: true,
		},
		numberOfBreaks: {
			type: Number,
			required: false,
			default: 0,
		},
	},
});
</script>

<template>
	<div>
		<Sidebar
			:class="sidebarClass"
			:visible="visible"
			:position="position"
			@update:visible="emit('closeForm', $event.value)"
		>
			<template v-if="header">
				<h5>{{ header['title'] }}</h5>
				<Divider />
				<template v-for="_ in header['numberOfBreaks']">
					<br />
				</template>
			</template>
			<SearchForm @searchForm="emit('searchForm', $event.value)">
				<template v-for="(_, name) in $slots" #[name]>
					<slot :name="name" />
				</template>
			</SearchForm>
		</Sidebar>
	</div>
</template>
