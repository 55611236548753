export const misDatos = () => {
	return {
		toast: {
			success: {
				summary: 'Datos actualizados',
				partnership: 'Los datos de la empresa fueron actualizados correctamente',
				producer: 'Los datos del productor fueron actualizados correctamente',
				employee: 'Los datos del empleado fueron actualizados correctamente',
				user: 'Los datos del usuario fueron actualizados correctamente',
			},
			error: {
				summary: 'Error al actualizar',
				partnership: 'Los datos de la empresa no pudieron ser actualizados',
				producer: 'Los datos del productor no pudieron ser actualizados',
				employee: 'Los datos del empleado no pudieron ser actualizados',
				user: 'Los datos del usuario no pudieron ser actualizados',
			},
			info: {
				sameEmail: {
					summary: 'El Email es igual',
					detail: 'El email ingresado es igual al email actual',
				},
				differentEmail: {
					summary: 'El Email no coincide',
					detail: 'El email ingresado no coincide con el email de confirmación',
				},
			},
		},
		form: {
			title: 'Datos Personales',
			profilePicture: { divider: 'Foto de perfil', tooltip: 'Subir foto de perfil' },
			divider: 'Datos personales',
			telefono: 'Teléfono/s',
			mail: 'Mail',
			confirmMail: 'Confirmar Mail',
			changePassword: {
				onLabel: '¿Desea cambiar su contraseña?',
				offLabel: 'Proceda a cambiar su contraseña',
			},
			current_password: 'Contraseña Actual',
			new_password: 'Nueva Contraseña',
			confirm_password: 'Confirmar Nueva Contraseña',
		},
		partnership: {
			title: 'Mi Empresa',
			picture: { divider: 'Logo de mi empresa', tooltip: 'Subir logo de la empresa' },
			divider: 'Datos corporativos',
			fantasy_name: 'Nombre Comercial',
			address: 'Dirección Comercial',
			email: 'Email Oficial',
			phone: 'Teléfono Principal',
			whatsapp: 'WhatsApp Oficial',
			datos_fiscales: 'Datos Fiscales (para recibos/pdf)',
		},
	};
};
