<script setup>
import { defaultFilter, resultKey, resourceName } from '@service/RamosService';
import AutocompleteBase from './AutocompleteBase.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
	label: {
		type: String,
		required: false,
	},
    multiple: { type: Boolean, default: true },
});

const labelWithDefault = props.label || t('shared.labels.ramos');
</script>

<template>
	<AutocompleteBase
		field="denominacion"
		:resourceName="resourceName"
		:defaultFilter="defaultFilter"
		:resultKey="resultKey"
		:label="labelWithDefault"
		:multiple="multiple"
	/>
</template>
