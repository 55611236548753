import { auditoria } from './administrar/auditoria';
import { coberturas } from './administrar/coberturas';
import { companies } from './administrar/companies';
import { companiesAddEdit } from './administrar/companiesAddEdit';
import { empleados } from './administrar/empleados';
import { misDatos } from './administrar/misDatos';
import { organizacion } from './administrar/organizacion';
import { productores } from './administrar/productores';
import { suscripcion } from './administrar/suscripcion';
import { permisos } from './administrar/permisos';
import { chatbot } from './administrar/chatbot';

export const administrar = () => {
	return {
        chatbot: chatbot(),
        permisos: permisos(),
		auditoria: auditoria(),
		coberturas: coberturas(),
		companies: companies(),
		companiesAddEdit: companiesAddEdit(),
		organizacion: organizacion(),
		suscripcion: suscripcion(),
		usuariospropios: {
			misDatos: misDatos(),
			empleados: empleados(),
			productores: productores(),
		},
		usuarios: {
			form: {
				productor: 'Productor',
				organizador: 'Organizador',
				input: {
					premio: '% sobre Premio',
					prima: '% sobre Prima',
					extra: '% ayuda / extra',
					relacion: 'En relación a',
					ramo: 'Ramo (vacío aplica para TODOS)',
				},
			},
			agreementTable: {
				menuModel: {
					verEditar: 'Ver / Editar Acuerdo',
					borrar: 'Borrar Acuerdo',
				},
				dialog: {
					header: {
						editar: 'Editar Acuerdo',
						nuevo: 'Nuevo Acuerdo',
					},
				},
				header: {
					nuevo: 'Nuevo Acuerdo Comercial',
				},
				columns: {
					premio: '% sobre premio',
					prima: '% sobre prima',
					extra: '% de ayuda / extra',
					sobre: 'sobre',
					todos: 'Todos',
				},
				enRamo: 'en ramo',
				empty: 'No hay acuerdos comerciales',
			},
			associationTable: {
				menuModel: {
                    agregar: 'Agregar Intermediario',
					borrar: 'Borrar Intermediario',
				},
				dialog: {
					header: 'Seleccionar Intermediario (luego expandir para agregar acuerdos)',
					autocomplete: 'Intermediario',
				},
				header: {
					button: 'Nuevo Intermediario para configurar acuerdos en el Código {code}',
				},
				expandir: '(expandir para ver acuerdos de intermediario)',
				empty: 'Sin datos.',
				expansionTitle: 'Acuerdos para {nombre} en código de productor {codigo}',
			},
			producerCodesTable: {
				menuModel: {
					editar: 'Editar Código',
					gestionar: 'Gestionar Comisiones',
					borrar: 'Borrar Código',
					unificar: 'Unificar código de productor con otro',
				},
				load: {
					toast: {
						summary: 'Error',
						detail: 'Error buscando códigos de productor',
					},
				},
				populate: {
					company: 'Compañía',
				},
				unify: {
					confirm: [
						'Toda la producción del código {code} - {denominacion} pasará al código {newCode} - {newDenominacion}',
						'El código {code} - {denominacion} <b>dejará de existir</b>',
						'ESTA ACCIÓN NO SE PUEDE DESHACER',
						'¿Continuar?',
					],
					accept: {
						toast: {
							summary: 'Unificando códigos',
							detail: 'Unificando códigos en paralelo, refresque el listado en minutos.',
						},
					},
				},
				save: {
					toast: {
						summary: {
							created: 'Código creado',
							updated: 'Código actualizado',
						},
					},
				},
				delete: {
					confirm: {
						message:
							'Esta acción elimina TODAS las pólizas asociadas al código. Revise previamente si tiene pólizas en el mismo. ¿Eliminar Código?',
					},
				},
				search: {
					header: 'Códigos de Productor de {productor}',
				},
				edit: {
					header: {
						edit: 'Editar Código',
						create: 'Nuevo Código para {productor}',
					},
				},
				commissions: {
					header: 'Acuerdos Comerciales para el Código {code}',
					fieldsets: {
						general: 'Comisiones Generales del Productor',
						intermediario: 'Comisiones Para Intermediarios en este Código',
					},
					table: {
						columns: {
							header: 'Acuerdos Comerciales generales del código',
							premio: '{porcentaje}% sobre premio',
							prima: '{porcentaje}% sobre prima',
							extra: '{porcentaje}% de ayuda / extra',
							ramo: 'en ramo {denominacion}'
						},
					},
				},
			},
		},
	};
};
