export const companies = () => {
	return {
		add: 'Agregar Compañía',
		contextMenu: {
			edit: 'Editar Compañía',
			delete: 'Borrar Compañía',
		},
		dialog: { add: 'Editar Compañía: {name}', edit: 'Agregar Compañía' },
		confirm: {
			message: '¿Está seguro que desea eliminar a la compañía {name} del sistema?',
			header: 'Borrar Compañía',
		},
		toast: {
			summary: 'Eliminando Compañía',
			detail: 'Se está eliminando la compañía seleccionada',
			successSummary:'Compañia Eliminada',
			errorSummary:'Error',
			success: 'Se eliminó exitosamente',
			error: 'Error al eliminar la compañia',
		},
		title: 'Compañías',
		datatable: {
			producer: 'Productor: {producer}',
		},
		producerCodesTableData: '{producer} - Códigos / Comisiones predefinidas',
	};
};
