import { saveGenericEntity, useGetAll, usePut, betterSaveGenericEntity, getApiResults, useLoadData } from '@service/AxiosBaseService';
import { tizer } from '@service/i18nService';
import { createFilter } from '@helpers/filtersHelper'
import { ref } from 'vue'

export const defaultFilter = {
	name: { property: 'name', value: null, type: 'string', operator: 'like' },
};

export const filters = ref({
    name: createFilter('name', 'string', 'like', 'name'),
    active: createFilter('active', 'combo', 'like', 'activo'),
});

export const resourceName = 'api/users';
export const resultKey = 'user';

export const users = getApiResults(resultKey)

export const loadUsers = async(params = {first: 0, rows: 10}) => {
    params.filters = filters.value
    useLoadData(params,resourceName, resultKey)
}

export const getUsers = async (params) => {
	return await useGetAll(params, resourceName);
};

export const updateUser = (data) => usePut(`${resourceName}/${data.id}`, { user: data });

export const saveUser = async (selectedUser) => {
	let userCopy = JSON.parse(JSON.stringify(selectedUser.value));
	userCopy = setSimpleAssociations(userCopy);
	return await betterSaveGenericEntity(selectedUser, { user: userCopy }, resourceName, resultKey, 'masc', tizer('shared.labels.user'));
};

const setSimpleAssociations = (userCopy) => {
	userCopy.disabled_modules_ids = [];
	userCopy.disabled_modules?.forEach((dm) => {
		userCopy.disabled_modules_ids.push(dm.id);
	});
	return userCopy;
};



export const available_modules = [
	/*{
        // esto va por logica de user admin
        path: '/administrar/usuariospropios/permisos',
        name: 'administrar_permisos',
        title: 'Administrar Permisos'
    },*/
	{
		id: 1,
		path: '/administrar/usuariospropios/productores',
		name: 'productores',
		title: 'Administrar Productores',
	},
	{
		id: 2,
		path: '/administrar/organizacion',
		name: 'organizacion',
		title: 'Administrar Organización',
	},
	{
		id: 3,
		path: '/administrar/usuariospropios/empleados',
		name: 'empleados',
		title: 'Administrar Empleados',
	},
	{
		id: 4,
		path: '/administrar/usuariospropios/dateros',
		name: 'dateros',
		title: 'Administrar Dateros',
	},
	{
		id: 5,
		path: '/eventos',
		name: 'eventos',
		title: 'Eventos',
	},
	{
		id: 6,
		path: '/estadisticas',
		name: 'estadisticas',
		title: 'Estadísticas',
	},
	{
		id: 7,
		path: '/crm/clients',
		name: 'clients',
		title: 'Clientes',
	},
	{
		id: 8,
		path: '/crm/mailing',
		name: 'mailing',
		title: 'Mailing',
	},
	{
		id: 9,
		path: '/crm/contacts',
		name: 'contacts',
		title: 'CRM / Contactos',
	},
	{
		id: 10,
		path: '/imports',
		name: 'import',
		title: 'Importaciones',
	},
	{
		id: 11,
		path: '/crm/seguimientos',
		name: 'seguimientos',
		title: 'Tickets / Seguimientos',
	},
	{
		id: 12,
		path: '/cotizador/automotor',
		name: 'cotizador_automotor',
		title: 'Cotizador Automotor',
	},
	{
		id: 13,
		path: '/rubrica',
		name: 'rubrica',
		title: 'Rúbrica',
	},
	{
		id: 14,
		path: '/administrar/auditoria',
		name: 'auditoria',
		title: 'Auditoría',
	},
	{
		id: 15,
		path: '/tesoreria',
		name: 'tesoreria',
		title: 'Tesorería (todos)',
	},
    {
        id: 150,
		path: '/tesoreria/pagos/pendientes',
		name: 'pagos_pendientes',
		title: 'Tesorería / Pagos en Cia / Pendientes',
	},
    {
        id: 151,
		path: '/tesoreria/pagos/realizados',
		name: 'realizados',
		title: 'Tesorería / Pagos en Cia / Realizados',
	},
    {
        id: 152,
		path: '/tesoreria/pagos/rendiciones',
		name: 'rendiciones',
		title: 'Tesorería / Pagos en Cia / Rendiciones',
	},
    {
        id: 160,
		path: '/tesoreria/pagos/pendientes-clientes',
		name: 'pendientes_cliente',
		title: 'Tesorería / Pagos de Clientes / Pendientes',
	},
    {
        id: 161,
		path: '/tesoreria/pagos/cobrados-clientes',
		name: 'cobrados_cliente',
		title: 'Tesorería / Pagos de Clientes / Cobrados',
	},
    {
        id: 170,
		path: '/tesoreria/comisiones/pendientes',
		name: 'comisiones_pendientes',
		title: 'Tesorería / Comisiones / Pendientes',
	},
    {
        id: 171,
		path: '/tesoreria/comisiones/preliquidaciones',
		name: 'preliquidaciones',
		title: 'Tesorería / Comisiones / Preliquidaciones',
	},
    {
        id: 180,
		path: '/tesoreria/cheques',
		name: 'tesoreria_cheques',
		title: 'Tesorería / Cheques',
	},
    {
        id: 190,
		path: '/tesoreria/movimientos-caja',
		name: 'movimientos_caja',
		title: 'Tesorería / Mov. Caja',
	},
	{
		id: 16,
		path: '/siniestros',
		name: 'siniestros',
		title: 'Siniestros',
	},
	{
		id: 17,
		path: '/polizas',
		name: 'polizas',
		title: 'Pólizas',
	},
	{
		id: 18,
		path: '/reportes',
		name: 'reportes',
		title: 'Reportes',
	},
	{
		id: 19,
		path: '/administrar/suscripcion',
		name: 'suscripcion',
		title: 'Administrar Suscripción',
	},
    {
		id: 20,
		path: '/administrar/chatbot',
		name: 'chatbot',
		title: 'Administrar Chatbot',
	},
];
