export const pendientes = () => {
	return {
		title: 'Pendientes de Emisión',
		sideBarTitle: 'Búsqueda de Emisiones Pendientes',
		toast: {
			error: {
				summary: 'Error',
				detail: 'Error buscando emisiones.',
			},
			success: {
				summary: 'Éxito',
				detail: 'Solicitud rechazada',
			},
		},
		contextMenu: {
			editar_poliza: 'Editar Póliza',
			ver_endosos: 'Ver Endosos',
			imprimir_propuesta: 'Imprimir Propuesta',
			rechazar_solicitud: 'Rechazar Solicitud',
		},
		labels: {
			cantidad_cuotas_poliza: 'Cantidad de cuotas de la póliza',
		},
		confirmDialog: {
			header: 'Confirmar Acción',
			rechazar_solicitud: '¿Está seguro que desea rechazar esta solicitud?',
		},
	};
};
