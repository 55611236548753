export const rendiciones = () => {
	return {
		toast: {
			error: {
				summary: 'Error',
				detail: 'Error buscando rendiciones',
			},
			deleting: {
				successSummary:'Rendición eliminada',
				success: 'La rendición se eliminó exitosamente',
				//summary: 'Eliminando rendición',
				//detail: 'Se está eliminando la rendición seleccionada',
				errorSummary:'Error',
				error: 'Error al eliminar la compañia',
			}
		},
		sideBarTitle: 'Buscar rendiciones realizadas',
		title: 'Rendiciones Generadas',
		message: 'Registros',
		contextMenu: {
			download: 'Descargar rendición',
			delete: 'Eliminar rendición',
		},
		confirm: {
			message: '¿Está seguro que desea eliminar la rendición número {number} del sistema?',
			header: 'Borrar Rendición',
		},
	};
};
