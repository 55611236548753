export const parametrosGenerales = () => {
	return {
		title: 'Parámetros comunes',
		titleLocalidades: 'Localidades en Compañías',
		loading: 'Buscando información...',
		buttons: {
			edit: 'Editar Cliente',
			new: 'Crear Cliente nuevo',
			cotizar: 'Cotizar todas las compañías',
		},
		labels: {
			automoto: 'Auto / Moto',
			tipo_uso: 'Uso',
			year: 'Año',
			zerokm: 'Es 0km?',
			tipos_doc: 'Tipo Doc.',
			client_dni: 'Número Documento',
			province: 'Provincia',
			location: 'Localidad',
			code: 'Código Postal',
			birthdate: 'Fec. Nacimiento',
			gender: 'Género',
		},
	};
};
