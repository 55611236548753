export const inicio = () => {
	return {
		counters: {
			polizasVigentes: 'Pólizas Vigentes',
			clientesCaptivos: 'Clientes Captivos',
			notificacionesPendientes: 'Notificaciones Pendientes',
			siniestrosPendientes: 'Siniestros Pendientes',
			nuevasPolizas: 'Pólizas nuevas',
			nuevasOperaciones: 'Operaciones nuevas',
			pagosPendientes: 'Pólizas con pagos pendientes',
			nuevos_seguros: 'Seguros nuevos',
			renovaciones: 'Renovaciones',
			anulaciones: 'Anulaciones',
			total_emisiones: 'Emisiones Totales',
			no_renovadas: 'No Renovadas',
			concepts_policies: 'Pólizas impagas',
			concepts: 'Cuotas impagas (totales)',
			payments: 'Cuotas pagas (premio total)',
		},
		chartsOptions: {
			carteraPorCompany: {
				title: 'Distribución actual de Cartera por Compañía',
			},
			carteraPorSeccion: {
				title: 'Distribución actual de Cartera por Sección',
			},
		},
		select_periodo: 'Seleccionar Período',
		distribucion: 'Distribución de Cartera',
	};
};
