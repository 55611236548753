import { useGet, betterSaveGenericEntity } from '@service/AxiosBaseService';
import Axios from 'axios';
import { getAllCompanies } from '@service/CompaniesService'
import { getProductions, productions } from '@service/ProductionService'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { getFromLocal } from '@service/LocalStorageService';
import { setLocaleFromCurrency, setCountry } from '../i18n/localeService';
import { tizer } from '@service/i18nService';

export const useAppConfigStore = defineStore('appconfig', () => {

    const currentUser = ref({})
    const currentProducer = ref({})
    const currentEmployee = ref({})
    const currentPartnership = ref({})

    const currentNovedad = ref({
        id: null,
        parts: [],
        visible: false,
    })

    function addToCurrentNovedad(id, title, parts){
        // Solo voy a mostrar de a 1 novedad, sino caos
        if( (!currentNovedad.value?.id) && (!getFromLocal('modal_watched_'+id)) ){
            currentNovedad.value = {
                id:id,
                title:title,
                parts:parts,
                visible:true
            }
        }
    }

    async function loadCurrentPartnership(toast) {
        if (!Object.keys(currentPartnership.value).length){
            try {
                const response = await useGet('/api/partnership')
                currentPartnership.value = response.data.partnership
                //console.log(response.data.partnership)
                // Locale default de partnership
                // debe existir en la lista de messages de i18n para que lo tome
                setLocaleFromCurrency(response.data.partnership?.default_currency?? 'ars')
                setCountry(response.data.partnership?.tipo_pais)
                loadCompanies()
                
                // Producer default/principal
                if (currentPartnership.value.default_producer?.producer)
                    currentProducer.value = currentPartnership.value.default_producer?.producer
                else {
                    addToCurrentNovedad('default_producer', 'Productor predeterminado', [
                        'Ya podés configurar el Productor predeterminado de tu equipo',
                        'El productor aparecerá como predeterminado al momento de ingresar al Cotizador, en los listados de Rúbrica, etc.',
                        'Lo podés gestionar desde <b>Administrar -> Usuarios -> Productores</b>, haciendo click derecho en el productor que quieras marcar como principal.'
                    ])
                }
            } catch (error){
                if (toast) toast.add({ severity: 'error', summary: 'Error', detail: 'Error buscando estrucura de trabajo', life: 3000 });
            }
        }
        return currentPartnership
    }

    async function reloadCurrentPartnership(){
        try {
            const response = await useGet('/api/partnership')
            currentPartnership.value = response.data.partnership
        } catch (error){
            //toast.add({ severity: 'error', summary: 'Error', detail: 'Error buscando estrucura de trabajo', life: 3000 });
        }
    }

    function loadCurrentUser(toast) {
        if (!Object.keys(currentUser.value).length){
            useGet('/api/user')
            .then((response) => {
                currentUser.value = response.data.user;
                if (currentUser.value.role.producer && (Object.keys(currentProducer.value).length == 0)) currentProducer.value = currentUser.value.role.producer
                if (currentUser.value.role.employee) currentEmployee.value = currentUser.value.role.employee
            })
            .catch((error) => {
                if (toast) toast.add({ severity: 'error', summary: 'Error', detail: 'Error buscando usuario', life: 3000 });
            });
        }
        return currentUser
    }

    function loadCompanies(){
        getAllCompanies()
    }

    function loadProductions(){
        if (!productions.value.length)
            getProductions()
    }
    
    return { 
        reloadCurrentPartnership,
        loadCurrentPartnership,
        loadCurrentUser,
        loadCompanies,
        loadProductions,
        currentNovedad,
        addToCurrentNovedad,
        currentUser, 
        currentProducer, 
        currentEmployee,
        currentPartnership 
    }
})