export const permisos = () => {
	return {
        search_users: 'Búsqueda de Usuarios',
        user_admin: 'Usuario Administrador',
        has_restrict_freeze: 'Sin permiso de confirmación de pólizas',
		title: 'Gestionar permisos de acceso',
        gestionar: 'Administrar Permisos de {msg}',
        gestionar_permisos: 'Deshabilitar módulos',
        restrictions: 'Acciones restringidas por módulo',
        gestionar_restrictions: 'Restringir acciones por módulo',
        gestionar_restrictions_user: 'Restringir acciones de {msg}',
        set_as_admin: 'Hacer Administrador',
        unset_as_admin: 'Quitar acceso de Administrador',
        set_restrict_freeze: 'Quitar permiso de confirmación de pólizas',
        unset_restrict_freeze: 'Habilitar permiso de confirmar pólizas',
        confirm_set_admin: '¿Configurar a {msg} como Administrador?',
        confirm_unset_admin: '¿Quitarle acceso de Administrador a {msg}?',
        confirm_set_restrict_freeze: '¿Quitar a {msg} permiso de confirmación de pólizas?',
        confirm_unset_restrict_freeze: '¿Rehabilitar permiso de confirmación de pólizas a {msg}?',
        restrictions_available: 'Acciones disponibles',
        restrictions_applied: 'Acciones restringidas',
        habilitados: 'Módulos habilitados',
        inhabilitados: 'Módulos deshabilitados',
        disable_modules: 'Deshabilitar módulos',
        set_restrictions: 'Restringir acciones',
        frozen: 'Confirmación de {msg} exitoso',
        unfrozen: 'Desbloqueo de {msg} exitoso',
        fecha_alta: 'Fecha de Alta',
        fecha_baja: 'Fecha de Baja',
        disable_user: 'Deshabilitar usuario',
        enable_user: 'Rehabilitar usuario',
        confirm_disable_user: '¿Deshabilitar usuario {nombre}?',
        roles: {
            confirm_set_rol: '¿Asignar rol?',
            rol_tiene_prioridad: '  Las restricciones definidas previamente dejarán de tener efecto y serán reemplazadas por las del Rol seleccionado',
            set: 'Asignar Rol',
            unset_rol: 'Quitar Rol',
            title: 'Administrar Roles',
            nuevo: 'Nuevo Rol',
            edit: 'Editar Rol {nombre}'
        }
	};
};
