export const importar = () => {
	return {
		toast: {
			success: {
				summary: {
					updated: 'Código actualizado',
					created: 'Código creado',
				},
			},
			error: {
				summary: {
					invalido: 'Archivo inválido. Solo se permiten archivos {allowed}',
					ejecutando: 'Error ejecutando importación',
				},
			},
		},
        add_code: 'Agregar nuevo código en {cia} para {productor} y seleccionarlo',
		message: {
            ejecutando: 'Ejecutando importación. Pueden chequear el estado en el Historial de Importaciones',
			ejecutando_extra:
				'Ejecutando importación. Pueden chequear el estado en el Historial de Importaciones. Recuerden que este tipo de importaciones NO se actualiza automáticamente. Deben repetir este proceso regularmente para tener información actualizada.',
			generada: 'Solicitud generada. Te avisaremos por mail el estado de la solicitud',
		},
		confirm: {
			header: 'Importar',
			message: '¿Ejecutar Importación?',
		},
		title: 'Importaciones - Seleccionar compañía',
		overlayPanel: {
			operaciones: 'Operaciones con error: {amount}',
			pendientes: 'Pagos pendientes con error: {amount}',
			pagos: 'Pagos con error: {amount}',
			comisiones: 'Comisiones con error: {amount}',
		},
		sideBarTitle: 'Últimas importaciones en {title} - {nombre}',
		divider: {
			companies: 'Mis compañías',
			allCompanies: 'Todas las compañías',
		},
		legend: {
			cambiar: 'Cambiar credenciales',
			ingresar: 'Ingresar credenciales',
		},
		dialog: {
			addCode: 'Agregar código en {title} para {nombre}',
			request: 'Solicitar alta en {title}',
			sendMail: 'Enviar mail a ejecutivo de {title}',
			interface: 'Interfaz {title}',
		},
		tooltip: 'Agregar código',
		labels: {
			choose: 'Seleccionar archivo para importación',
			selectedFile: 'Archivo seleccionado: {file}',
			credentials:
				'NO usar las credenciales de acceso a la web de la compañía. Ver asistente de importación.',
			desde: 'Importar desde',
			hasta: 'Hasta',
			importing: 'Ya se están importando los datos desde la configuración del Organizador',
			credencialCorrecta: 'Credenciales configuradas correctamente',
			credencialInvalida: 'Credenciales inválidas',
            credencialSinConfigurar: 'Credenciales sin configurar',
			liquidacion: 'Día Liquidación',
			ayuda: 'Ayuda de importación',
            fecha_liquidacion: 'Fecha Liquidación'
		},
		buttons: {
			ejecutar: 'Ejecutar Importación',
			importacionDe: 'Ejecutar Importación de {label}',
			helpVideo: 'Video ayuda',
			helpImage: 'Ver imágenes de ayuda',
		},
	};
};
