export const aeronavegacion = () => {
	return {
		title: 'Aeronavegación',
		labels: {
			actividad: 'Actividad',
			ano: 'Año',
			categoria_rc: 'Categoría',
			serie: 'Serie',
			codigo_limite_geo: 'Limite geográfico',
			marca: 'Marca',
			matricula: 'Matrícula',
			modelo: 'Modelo',
		},
	};
};
