<script>
import MenuItem from './MenuItem.vue';

export default {
    name: 'appsubmenu',
    props: {
        items: Array,
        root: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            activeIndex: null,
        };
    },
    methods: {
        onMenuItemClick(event, item, index) {
            if (item.disabled) {
                event.preventDefault();
                return;
            }
            if (!item.to && !item.url) {
                event.preventDefault();
            }
            //execute command
            if (item.command) {
                item.command({ originalEvent: event, item: item });
            }
            this.activeIndex = index === this.activeIndex ? null : index;
            this.$emit('menuitem-click', {
                originalEvent: event,
                item: item,
            });
        },
        visible(item) {
            return typeof item.visible === 'function' ? item.visible() : item.visible !== false;
        },
    },
    components: { MenuItem }
};
</script>

<template>
    <ul v-if="items">
        <template v-for="(item, i) of items">
            <li v-if="visible(item) && !item.separator" :key="item.label || i"
                :class="[{ 'layout-menuitem-category': root, 'active-menuitem': activeIndex === i && !item.to && !item.disabled }]"
                role="none">
                <template v-if="root">
                    <div>
                        <a v-if="!item.to" :href="item.url || '#'" :style="item.style"
                            :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
                            @click="onMenuItemClick($event, item, i)" :target="item.target" role="menuitem" v-ripple>
                            <span class="layout-menuitem-root-text" style="align-self: center;">{{ item.label
                            }}</span>
                            <i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon" />
                            <span v-if="item.badge" class="menuitem-badge">{{ item.badge }}</span>
                        </a>
                        <menu-item v-else :item="item" label-class="layout-menuitem-root-text" @menuitem-click="onMenuItemClick" />
                        <transition name="layout-submenu-wrapper" style="margin-top: 0.3rem;">
                            <appsubmenu v-show="activeIndex === i" :items="visible(item) && item.items"
                                @menuitem-click="$emit('menuitem-click', $event)" />
                        </transition>
                    </div>
                </template>
                <template v-else>
                    <div style="margin-left: 1rem">
                        <menu-item v-if="item.to" :item="item" @menuitem-click="onMenuItemClick" />
                        <a v-if="!item.to" :href="item.url || '#'" :style="item.style"
                            :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
                            @click="onMenuItemClick($event, item, i)" :target="item.target" role="menuitem" v-ripple>
                            <i :class="item.icon" />
                            <span>{{ item.label }}</span>
                            <i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon" />
                            <span v-if="item.badge" class="menuitem-badge">{{ item.badge }}</span>
                        </a>
                        <transition name="layout-submenu-wrapper" style="margin-top: 0.3rem;">
                            <appsubmenu v-show="activeIndex === i" :items="visible(item) && item.items"
                                @menuitem-click="$emit('menuitem-click', $event)" />
                        </transition>
                    </div>
                </template>
            </li>
            <li class="p-menu-separator" :style="item.style" v-if="visible(item) && item.separator" :key="'separator' + i"
                role="separator" />
        </template>
    </ul>
</template>
