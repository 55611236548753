import { useGetAll, usePost, useDelete, saveGenericEntity } from '@service/AxiosBaseService';
import { getCompanyIdByCodigo } from '@service/CompaniesService';
import { ref } from 'vue';
import { createFilter } from '@helpers/filtersHelper';

export const resourceName = 'api/producer_operation_codes';
export const resultKey = 'producer_operation_code';

export const pocfilters = ref({
	// código va con un string vacío solo para que funque el search default de axios bien
	codigo: createFilter('codigo', 'string', 'like', 'code', ''),
	company_id: createFilter('company_id', 'eq', 'combo', 'company'),
	producer_id: { property: 'producer_id', value: null, type: 'combo', operator: 'eq' },
	organization_id: { property: 'organization_id', value: null, external_filter: true, type: 'combo' },
});

export const defaultFilter = ref({
	codigo: createFilter('codigo', 'string', 'like', 'code', ''),
});

export function createEmptyBA() {
	return new Object({
		id: null,
	});
}

export function createEmptyCode() {
	return new Object({
		id: null,
		company: { id: null },
		producer_id: null,
		codigo: null,
	});
}

export const loadProducerCodesByCompanyCodigoAndProducer = async (producer, company_codigo) => {
	const filts = {
		producer_id: { property: 'producer_id', value: producer.id, operator: 'eq', type: 'combo' },
		company_id: {
			property: 'company_id',
			value: getCompanyIdByCodigo(company_codigo),
			operator: 'eq',
			type: 'combo',
		},
	};
	const ret = await useGetAll({ first: 0, rows: 100, filters: filts }, resourceName);
	return ret.data ? ret.data.producer_operation_code : [];
};

export const getProducerCodes = async (producerId) => {
	pocfilters.value.producer_id.value = producerId;
	const params = {
		first: 0,
		rows: 10,
		sortField: null,
		sortOrder: null,
		page: null,
		filters: pocfilters.value,
	};
	const response = await useGetAll(params, resourceName);
	console.log(response);
};

export const deleteCode = async (code) => {
	await useDelete(`${resourceName}/${code.id}`, code);
};

export const saveCode = async (selectedCode) => {
	let codeCopy = setSimpleAssociations(JSON.parse(JSON.stringify(selectedCode.value)));
	return await saveGenericEntity(
		selectedCode,
		{ producer_operation_code: codeCopy },
		resourceName,
		resultKey
	);
};

const setSimpleAssociations = (codeCopy) => {
	if (codeCopy.company) codeCopy.company_id = codeCopy.company.id;
	if (codeCopy.business_agreements) {
		codeCopy.business_agreements.forEach((ba) => {
			if (ba.ramo) ba.ramo_id = ba.ramo.id;
		});
	}
	if (codeCopy.employee_business_associations) {
		codeCopy.employee_business_associations.forEach((eba) => {
			if (eba.employee) eba.employee_id = eba.employee.id;
			if (eba.business_agreements) {
				eba.business_agreements.forEach((ba) => {
					if (ba.ramo) ba.ramo_id = ba.ramo.id;
				});
			}
		});
	}

	return codeCopy;
};

export function unifyPocs(pocfrom, pocto) {
	return usePost(resourceName + '/move_to_another', { poc_from_id: pocfrom, poc_to_id: pocto });
}
