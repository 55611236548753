export const pendientes = () => {
	return {
		toast: {
			success: {
				summary: 'Rendición generada',
				detail: {
					generate: 'Rendición generada exitosamente',
				},
			},
            validate_numero_rendicion: 'Debe ingresar un número de rendición',
			error: {
				summary: 'Error al generar rendición',
				detail: {
					search: 'Error buscando pagos',
					generate: 'Recuerde que todos los pagos deben de pertenecer a la misma compañía',
				},
			},
		},
		labels: {
			generar: 'Generar Rendición',
			numero: 'Número Rendición',
			fecha_pago: 'Fecha de Pago',
			ramo: 'Ramo de la póliza',
		},
		sideBarTitle: 'Búsqueda Liquidaciones en Compañía',
		title: 'Pagos a la Compañía - Pendientes',
		buttons: {
			saldar: 'Saldar Pagos Seleccionados (Generar Rendición)',
		},
		importe: 'Subtotal importe: ',
		saldo: 'Subtotal saldo: ',
	};
};
