export const presupuesto = () => {
	return {
		header: 'Seleccionar Coberturas a incluir en el presupuesto',
		selectedCoberturas: '{cantCoberturas} coberturas seleccionadas',
		buttons: {
			download: 'Descargar Presupuesto',
		},
		datatables: {
			groupHeader: 'Coberturas {tipo_cobertura}',
			columnsHeader: {
				tipo: 'Tipo Cob.',
				modif: 'Modif. premio (%)',
				premio: 'Premio (editable)',
				description: 'Descripción (editable)',
			},
		},
	};
};
