<script setup>
import { ref } from 'vue';
import { getLocale } from '@/i18n/localeService';

const model = defineModel()
const placeholder = defineModel('placeholder', {required: false, default:''})
const limit = defineModel('limit', {required: false, default:true})
const showButtons = defineModel('showButtons', {required: false, default:false})

const handleKeyDown = (event) => {
    if (['es-ar','es-uy'].includes(getLocale()))
        replaceDotWithComma(event)
}

const replaceDotWithComma = (event) => {
	if (event.key === '.') {
		event.preventDefault();
		const cursorPosition = event.target.selectionStart;
		const inputValue = event.target.value;
		const modifiedValue =
			inputValue.substring(0, cursorPosition) + ',' + inputValue.substring(cursorPosition + 1);
		event.target.value = modifiedValue;
		event.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
	}
};

</script>

<template>
	<InputGroup> <!-- esto parece al pedo pero resuelve temas de width en estos campos-->
       <InputNumber 
            :showButtons="showButtons"
            :locale="getLocale()"
            v-model:model-value="model" :max-fraction-digits="2"
            suffix=" %"
            :min="0"
            :max="(limit? 100 : null)"
            :placeholder="placeholder"
       />
    </InputGroup>
</template>
<style>

</style>
