import { prospectoAddEdit } from './prospectoAddEdit';
import { cotizacionAddEdit } from './cotizacionAddEdit';
import { cotizacionPropiaAddEdit } from './cotizacionPropiaAddEdit';

export const prospectos = () => {
	return {
		contextMenu: {
			new: 'Nueva Cotización',
			edit: 'Editar Prospecto ART',
			delete: 'Eliminar Prospecto ART',
		},
		toast: {
			error: {
				summary: 'Error',
				detail: 'Error buscando prospectos',
			},
		},
		title: 'Prospectos',
		searchSideBar: {
			title: 'Búsqueda de Prospectos',
			autocomplete: 'Prospecto',
		},
		buttons: {
			new: 'Nuevo Prospecto ART',
		},
		labels: {
			capitas: 'Capitas',
			masa_salarial: 'Masa Salarial',
			ciiu: 'CIIU',
		},
		cotizacion: {
			contextMenu: {
				print: 'Imprimir Cotización',
				edit: 'Editar Cotización',
				delete: 'Eliminar Cotización',
			},
			datatable: {
				header: 'Cotizaciones de',
			},
			labels: {
				in: 'en',
			},
		},
		prospectoAddEdit: prospectoAddEdit(),
		cotizacionAddEdit: cotizacionAddEdit(),
		cotizacionPropiaAddEdit: cotizacionPropiaAddEdit(),
	};
};
