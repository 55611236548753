export const movimientosAddEdit = () => {
	return {
		toast: {
			success: {
				summary: {
					movement: 'Movimiento Guardado',
					exito: 'Éxito',
				},
				detail: {
					movement: 'El movimiento ha sido guardado correctamente.',
					caja: 'Nueva caja agregada éxitosamente.',
					concepto: 'Nuevo concepto agregado éxitosamente.',
				},
			},
			error: {
				summary: {
					error: 'Error',
				},
				detail: {
					caja: 'Error al intentar guardar la caja.',
					concepto: 'Error al intentar guardar el concepto.',
					error: 'Error al intentar borrar.',
				},
			},
		},
		title: {
			cajas: 'Cajas Personales',
			conceptos: 'Conceptos',
		},
		labels: {
			nuevaCaja: 'Nueva caja',
			caja: 'Caja',
			tipoMovimiento: 'Tipo Movimiento',
			tipoFactura: 'Tipo Factura',
			nuevoConcepto: 'Nuevo concepto',
			punto: 'Punto',
			comprobante: 'Comprobante',
		},
	};
};
