export const historial = () => {
	return {
		contextMenu: {
			generate: 'Generar Presupuesto',
			results: 'Ver Resultados',
			recotizar: 'Recotizar',
		},
		sideBarTitle: 'Búsqueda de cotizaciones realizadas',
        title: 'Historial de Cotizaciones'
	};
};
