import { configuracion } from './crm/mailing/configuracion';
import { send_documents } from './crm/mailing/send_documents';
import { sent_mails } from './crm/mailing/sent_mails';
import { seguimientos } from './crm/seguimientos/seguimientos';

export const crm = () => {
	return {
		clients: {
			show_siniestros_header: 'Siniestros del cliente {num}',
			show_seguimientos_header: 'Seguimientos del cliente {num}',
			title: 'Clientes / Prospectos',
			searchTitle: 'Búsqueda de Clientes / Prospectos',
			employees: 'Intermediarios',
			createdAt: 'Fecha creación',
			loadLazyData: {
				toastErrorSummary: 'Error',
				toastErrorDetail: 'Error buscando clientes',
			},
			unify: {
				confirmHeader: 'Unificar Clientes',
				selected: 'Unificar Seleccionados',
				confirmSelected: '¿Está seguro que desea unificar a estos clientes?',
				byNameAndDni: 'Unificar por Nombre y Documento',
				confirmByNameAndDni:
					'Se unificarán todos los clientes con mismo Nombre y Documento en el sistema. Esta acción no se puede deshacer. ¿Desea continuar?',
				byName: 'Unificar por Nombre',
				confirmByName:
					'Se unificarán todos los clientes con mismo Nombre en el sistema. Esta acción no se puede deshacer. ¿Desea continuar?',
				byDni: 'Unificar por Documento',
				confirmByDni:
					'Se unificarán todos los clientes con mismo Documento en el sistema. Esta acción no se puede deshacer. ¿Desea continuar?',
				keep: 'Seleccione el Cliente que quiere mantener',
				toastInfoSummary: 'Unificando Clientes',
				toastInfoDetailSelected: 'Se están unificando los clientes seleccionados',
				toastInfoDetail: 'Se están unificando los clientes',
				toastErrorSummary: 'Rechazado',
				toastErrorDetail: 'Hubo un error unificando clientes',
			},
			delete: {
				confirmMessage: '¿Está seguro que desea eliminar al cliente {msg} del sistema?',
				confirmHeader: 'Borrar Cliente',
				clienteEliminado: 'Cliente eliminado del sistema',
				error: 'No se pudo eliminar del sistema',
				toastInfoSummary: 'Eliminando Cliente',
				toastInfoDetail: 'Se está eliminando el cliente seleccionado',
			},
			showSiniestros: {
				toastInfoSummary: 'Sin Siniestros',
				toastInfoDetail: 'No hay siniestros para mostrar',
			},
			showSeguimientos: {
				toastInfoSummary: 'Sin Seguimientos',
				toastInfoDetail: 'No hay seguimientos para mostrar',
			},
			extras: {
				exportMapaRamos: 'Exportar mapa de ramos contratados',
				configGrilla: 'Config. Grilla',
				restoreGrilla: 'Restaurar Grilla',
				personalBalance: 'Saldo Personal',
				ciaBalance: 'Saldo en CIA',
				polizasVigentes: 'Pólizas Vigentes',
				siniestrosPendientes: 'Siniestros Pendientes',
			},
			menuModel: {
				seeEditClient: 'Ver / Editar Cliente',
				deleteClient: 'Borrar Cliente',
				seeSiniestros: 'Ver Siniestros',
				seeSeguimientos: 'Ver Seguimientos',
				addSeguimiento: 'Nuevo Seguimiento',
			},
			detailCard: {
				pendingTickets: 'Seguimientos Pendientes',
			},
			downloadExcel: {
				header: 'Exportar Agenda a Excel',
				buildingFile: 'Generando archivo...',
			},
			notas: {
				header: 'Notas personales',
			},
			tooltips: {
				polizas: 'Tiene pólizas de {msg}',
				seguimientos: 'Tiene seguimientos pendientes',
				notas: 'Ver notas personales',
				siniestros: 'Tiene siniestros pendientes',
				registro: 'Registro de conducir vencido o próximo a vencer',
				attachments: 'Tiene {msg} archivos adjuntos',
				whatsapp: 'Whatsapp',
				telefonos: 'Ver todos los teléfonos',
				mails: 'Ver todos los mails',
				addresses: 'Ver todas las direcciones',
				documentos: 'Ver todos los documentos',
			},
			addEdit: {
				title: 'Nuevo Cliente',
				datosGenerales: {
					header: 'Datos Generales',
					fieldsetLegends: {
						personales: 'Personales',
						documentos: 'Documentos',
						telefonos: 'Teléfonos',
						mails: 'Mails',
						direcciones: 'Direcciones',
					},
					tipoPersona: 'Tipo Persona',
					fechaNacimiento: 'Fecha Nacimiento',
					nombreRazonSocial: 'Nombre o Razón Social',
					iva: 'Condición IVA',
					telefonosRelationTable: {
						label: 'Teléfono',
						tipoLabel: 'Tipo Teléfono',
						dataLabel: 'Número Teléfono',
						tooltip: 'Un número entre 8 y 10 dígitos',
					},
					mailRelationTable: {
						label: 'Email',
						tipoLabel: 'Tipo Mail',
						dataLabel: 'Email',
					},
					direccionesRelationTable: {
						label: 'Dirección',
					},
				},
				cuentasTarjetas: {
					header: 'Cuentas / Tarjetas',
					cuentasBancarias: 'Cuentas Bancarias',
					tarjetas: 'Tarjetas',
				},
				datosComplementarios: {
					header: 'Datos Complementarios',
					fieldsets: {
						asignarIntermediarios: 'Asignar Intermediarios',
						grupoFamilia: 'Grupo / Familia',
					},
					autocompletes: {
						peopleGroupMemberships: 'Grupo/Familia',
						intermediarios: 'Intermediario',
					},
                    tamanio_empresa: 'Tamaño empresa',
					nacionalidad: 'Nacionalidad',
					genero: 'Género',
					estadoCivil: 'EstadoCivil',
					profesion: 'Profesión',
					estatura: 'Estatura',
					peso: 'Peso',
					capitas: 'Capitas',
					masaSalarial: 'Masa Salarial',
					referidoPor: 'Referido por',
					ciuu: 'CIIU',
					dCiuu: 'Descripción CIIU',
					tCiuu: 'Tabla CIIU',
					numeroRegistro: 'Número de Registro',
					fechaVencimientoRegistro: 'Fecha de Vencimiento de Registro',
					obraSocial: 'Obra Social',
					pep: 'PEP',
					sujeto_obligado: 'Sujeto Obligado',
				},
				contactosEmpresa: {
					header: 'Contactos de la Empresa',
				},
				notasPersonales: {
					header: 'Notas Personales',
				},
				adjuntos: {
					header: 'Adjuntos de {msg}',
				},
				siniestros: {
					header: 'Siniestros',
				},
				seguimientos: {
					header: 'Seguimientos',
				},
			},
			clientFilters: {
				calendar: {
					registro: {
						gte: 'Vencimiento Registro mayor a',
						lte: 'Vencimiento Registro menor a',
					},
					birthday: {
						gte: 'Cumpleaños mayor a',
						lte: 'Cumpleaños menor a',
					},
				},
				tipoPersona: 'Tipo Persona',
			},
		},
		contacts: {
			searchTitle: 'Búsqueda de Contactos',
			addTitle: 'Nuevo Contacto',
			editTitle: 'Editar Contacto',
			menuModel: {
				addEdit: 'Ver / Editar Contacto',
				delete: 'Borrar Contacto',
			},
			loadLazyData: {
				toastErrorSummary: 'Error',
				toastErrorDetail: 'Error buscando contactos',
			},
			showAddEdit: {
				editTitle: 'Editar Contacto',
				addTitle: 'Agregar Contacto',
			},
			doSave: {
				toastSuccessSummary: {
					actualizado: 'Contacto Actualizado',
					creado: 'Contacto Creado',
				},
			},
			delete: {
				toastErrorSummary: 'Error',
				toastErrorDetail: 'Error borrando contacto',
				confirm: {
					message: '¿Está seguro que desea borrar este contacto?',
					toastInfoSummary: 'Eliminando Contacto',
					toastInfoDetail: 'Se está eliminando el contacto seleccionado',
				},
			},
		},
		groups: {
			group: {
				title: 'Grupos de afinidad / Familias',
				search: {
					title: 'Grupos de afinidad / Familias',
					inputs: {
						nombre: 'Nombre del Grupo',
						descripcion: 'Descripción',
					},
				},
				menuModel: {
					seeEdit: 'Ver / Editar Grupo',
					delete: 'Borrar Grupo',
				},
				addEdit: {
					title: {
						add: 'Nuevo Grupo',
						edit: 'Editar Grupo: {group}',
					},
				},
				confirmDelete: {
					message: '¿Está seguro que desea borrar este grupo?',
					toast: {
						info: {
							summary: 'Eliminando Grupo',
							detail: 'Se está eliminando el grupo seleccionado',
						},
					},
				},
				save: {
					toast: {
						success: {
							summary: {
								actualizado: 'Grupo actualizado',
								creado: 'Grupo creado',
							},
						},
					},
				},
			},
			people: {
				title: {
					empty: 'Seleccione Grupo',
					filled: 'Clientes del grupo {group}',
				},
				menuModel: {
					remove: 'Quitar persona del grupo',
				},
				addEdit: {
					title: {
						add: 'Agregar Persona',
						edit: 'Editar Persona: {person}',
					},
				},
			},
		},
		mailing: {
			configuracion: configuracion(),
			send_documents: send_documents(),
			sent_mails: sent_mails(),
		},
		seguimientos: seguimientos(),
	};
};
