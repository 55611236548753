export const prospectoAddEdit = () => {
	return {
		toast: {
			success: {
				summary: 'Prospecto Guardado',
				detail: 'El prospecto ha sido guardado correctamente',
			},
		},
		legends: {
			client: 'Cliente / Prospecto',
			others: 'Otros',
			responsable: 'Responsable',
		},
		labels: {
			searchByName: 'Buscar por nombre',
			traspaso: 'Es traspaso',
			ciiuNumero: 'CIIU Número',
			ciiuDescripcion: 'CIIU Descripción',
			ciiuTabla: 'CIIU Tabla',
			capitas: 'Capitas',
			masaSalarial: 'Masa Salarial',
			ingresarNombre: 'Ingresar nombre',
		},
	};
};
