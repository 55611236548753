export const art = () => {
	return {
		title: '',
		labels: {
			cuit: 'CUIT',
			ciiu: 'CIIU',
			razon_social: 'Razón Social',
			ciiu_descripcion: 'CIIU Descripción',
			capitas: 'Capitas',
			ciiu_tabla: 'CIIU Tabla',
			masa_salarial: 'Masa Salarial',
			monto_fijo: 'Monto Fijo',
			alicuota_variable: 'Alicuota Variable (%)',
			traspaso: 'Traspaso',
		},
	};
};
