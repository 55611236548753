export const auditoria = () => {
	return {
		toast: {
			error: {
				summary: 'Error',
				audits: 'Error buscando Auditorías',
			},
		},
		searchSideBar: {
			title: 'Búsqueda de Auditorías',
			
		},
		title: 'Auditoría',
	};
};
