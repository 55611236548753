import { cheques } from './tesoreria/cheques';
import { moviemientosCaja } from './tesoreria/movimientosCaja/movimientosCaja';
import { movimientosAddEdit } from './tesoreria/movimientosCaja/movimientosAddEdit';
import { pendientes } from './tesoreria/pagos/pendientes';
import { rendiciones } from './tesoreria/pagos/rendiciones';
import { realizados } from './tesoreria/pagos/realizados';
import { pendientes_cliente } from './tesoreria/pagos_cliente/pendientes_cliente';
import { cobrados_cliente } from './tesoreria/pagos_cliente/cobrados_cliente';
import { comisiones_pendientes } from './tesoreria/comisiones/pendientes';
import { preliquidaciones } from './tesoreria/comisiones/preliquidaciones';

export const tesoreria = () => {
	return {
		cheques: cheques(),
		movimientosCaja: moviemientosCaja(),
		movimientosAddEdit: movimientosAddEdit(),
		pagos: {
			pendientes: pendientes(),
			rendiciones: rendiciones(),
			realizados: realizados(),
		},
		pagos_cliente: {
			pendientes_cliente: pendientes_cliente(),
			cobrados_cliente: cobrados_cliente(),
		},
		comisiones: {
			comisiones_pendientes: comisiones_pendientes(),
			preliquidaciones: preliquidaciones(),
		},
	};
};
