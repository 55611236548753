export const datatables = () => {
	return {
        currentPageReportTemplate: `Mostrando {first} al {last} de {totalRecords}`,
        header: {
            searchButton: 'Buscar',
            addEditButton: 'Nuevo',
            extras: 'Extras',
            help: 'Ayuda',
        },
		columnHeaders: {
			contacto: 'Contacto',
			nacimiento: 'Nacimiento',
			descripcion: 'Descripción',
			domicilio: 'Domicilio',
			documento: 'Documento',
			extras: 'Extras',
			email: 'Email',
			telefono: 'Teléfonos',
			company: 'Compañía',
			cargo: 'Cargo',
			oficina: 'Oficina',
			polizasVigentes: 'Pól. Vigentes',
			polVigTot: 'Pól. Vig.Totales',
			polNue: 'Pól. Nuevas',
			polRenov: 'Pól. Renovadas',
			polAnu: 'Pól. Anuladas',
			polVenc:'Pól. Vencidas',
			periodo: 'Período',
		},
		empty: 'Sin datos.',
		loading: 'Buscando información, espere por favor.'
	};
};
