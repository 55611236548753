<script setup>
import { ref, computed } from 'vue';
import { searchDefault } from '@service/AxiosBaseService';
import { useAttrs } from 'vue';
import { onMounted } from 'vue';

const props = defineProps({
	modelValue: Object | String,
	resourceName: { type: String, required: true },
	defaultFilter: Object,
	resultKey: String,
	label: String,
	extraFilters: {
		type: Object,
		required: false,
	},
	dataKey: {
		type: String,
		default: 'id',
	},
	field: {
		type: [String, Function],
		default: 'nombre',
	},
	showClear: Boolean,
});

const emit = defineEmits(['update:modelValue', 'item-click']);

const suggestions = ref(null);
const totalCount = ref(null);
const attrs = ref(null);

const totalCountLegend = computed(() => {
	return totalCount.value + (totalCount.value === 1 ? ' registro' : ' registros totales');
});

function searchRemote({ query }) {
	// es fundamental que el filter Default sea el primero, en caso de tener extra filters
	let filt = props.defaultFilter;
	if (props.extraFilters) filt = { ...props.defaultFilter, ...props.extraFilters };

	searchDefault(query, props.resourceName, filt)
		.then((response) => {
			let suggestion = response.data[props.resultKey];
			if (suggestion.length === undefined) {
				suggestions.value = [suggestion];
			} else {
				suggestions.value = suggestion;
			}
			if (response.data.meta && response.data.meta.total !== null)
				totalCount.value = response.data.meta.total;
		})
		.catch((error) => {
			console.log(error);
		});
}

onMounted(() => {
	attrs.value = useAttrs();
});

const clearInput = () => {
	const emptyData = attrs.value.multiple ? [] : '';
	emit('update:modelValue', emptyData);
};
</script>

<template>
	<span class="p-float-label flex">
		<AutoComplete
			class="flex-grow-1"
			:id="resultKey"
			:modelValue="modelValue"
			:suggestions="suggestions"
			:dataKey="dataKey"
			:optionLabel="field"
			v-bind="$attrs"
			dropdown
			forceSelection
			@change="emit('update:modelValue', $event.value)"
			@complete="searchRemote($event)"
			@item-select="emit('item-click', $event)"
		>
			<template #option="slotProps" v-if="$slots.option">
				<slot name="option" v-bind="slotProps" />
			</template>
			<template v-if="totalCount" #footer>
				<div class="flex justify-content-center">
					<div
						class="flex align-items-center justify-content-center py-1 px-2 bg-green-400 text-white border-round m-2"
					>
						{{ totalCountLegend }}
					</div>
				</div>
			</template>
		</AutoComplete>
		<Button
			v-show="modelValue && showClear"
			class="w-1 h-full absolute unstyled"
			icon="pi pi-times"
			severity="secondary"
			text
			@click="clearInput"
		/>
		<label v-if="label" :for="resultKey">{{ label }}</label>
	</span>
</template>

<style scoped>
.unstyled {
	right: 4.5em;
}
.unstyled:hover,
.unstyled:active,
.unstyled:focus {
	background-color: transparent;
}
</style>
