export const configuracion = () => {
	return {
		toast: {
			error: {
				summary: 'Error',
				detail: 'Error buscando configuración',
			},
		},
		options: {
			todos: 'Todos los Clientes',
			solo: 'Sólo los configurados para enviar notificaciones',
		},
		buttons: {
			save: 'Guardar Configuracíon',
			add: 'Agregar Documentos a enviar por Compañía',
		},
		labels: {
			documents: 'Documentos a enviar',
			tooltip: 'Dirección a la cual el asegurado responderá el correo',
			email: 'Email de respuesta',
			sendEmail: 'Enviar mails a',
			send: 'Enviar documentos oficiales automáticamente',
		},
		message: `
			<p>
				El sistema descarga de las compañías los documentos oficiales disponibles (como ser el certificado
				de cobertura, frente de póliza, etc), se adjuntan en las pólizas (Pestaña "PDFs Póliza/Certificado")
				y se envían automáticamente por mail
				<b> a {label}. </b>
			</p>
			<p>
				Esto sucede cada vez que las interfaces de las compañías descargan nuevas pólizas/endosos. El
				sistema garantiza que no se envía mas de una vez el mismo documento a un Cliente.
			</p>
			<p>
				Para enviar documentos manualmente en forma masiva lo pueden hacer desde "Mailing" -> "Enviar
				Documentos". Desde ese módulo también pueden adjuntar documentos (en "PDFs Póliza/Certificado") en
				las pólizas que no tengan descarga automática de documentos y disparar la acción de envío de Mails
				masivo.
			</p>
			<p>
				Con el boton <b>"Agregar Documentos a enviar por Compañía"</b> vas a poder definir que tipo de
				documentos enviar en cada compañía. Si no se define se enviará el documento por omisión (en general,
				frente de póliza o certificado de cobertura).
			</p>
		`,
	};
};
