import { ref } from 'vue';
import i18n from '.';
import { primevueObjects } from './primevueObjects';

export const navigatorLocale = () => window.navigator.language.slice(0, 2);

export const getLocaleCurrency = () => {
    switch(getLocale()){
        case 'es-ar':
            return "ars"
        case 'es-uy':
            return "uyu"
        default:
            return "ars"
    }
}

export const country = ref('Argentina')

export const setCountry = (pais) => {
    if (pais)
        country.value = pais
}

export const setLocaleFromCurrency = (currency) => {
    switch (currency){
        case 'ars':
            country.value = 'Argentina'
            i18n.global.locale.value = 'es-ar'
            break;
        case 'uyu':
            country.value = 'Uruguay'
            i18n.global.locale.value = 'es-uy'
            break;
    }
}

export const getLocale = () => {
    if (i18n.global.availableLocales.includes(i18n.global.locale.value)) {
        return i18n.global.locale.value;
    } else {
        return i18n.global.fallbackLocale.value;
    }
};

export const getLocaleObject = () => {
    return primevueObjects[getLocale()];
}