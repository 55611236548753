export const vigentes = () => {
	return {
		title: 'Vigentes Históricas', 
		searchTitle: 'Búsqueda de Vigentes Historias',
		filters: {
			vigencia: {
				gte: 'Período Vigencia Desde',
				lte: 'Período Vigencia Hasta',
			},
			estado: 'Buscar en Pólizas',

		}
	};
};
