export const pendientes_cliente = () => {
	return {
		toast: {
            success: {
				summary: 'Cobranzas saldadas',
				detail: {
					generate: 'Cobranzas saldadas exitosamente',
				},
			},
			error: {
				summary: 'Error',
				detail: 'Error buscando pagos',
			},
		},
		contextMenu: {
			ver_asegurado: 'Ver / Editar Asegurado',
		},
		sideBarTitle: 'Búsqueda de Cobranzas Pendientes Gestionadas Personalmente',
		title: 'Cta Cte - Cobranzas Pendientes al Cliente (gestión personal)',
		importe: 'Subtotal Importe: ',
		saldo: 'Subtotal Saldo: ',
		labels: {
			fecha_pago: 'Fecha de Pago',
			cobrador: 'Cobrador',
		},
		buttons: {
			saldar: 'Saldar Cobranzas Seleccionadas',
		},
	};
};
