export const reportes_descargados = () => {
	return {
		toast: {
			error: {
				summary: 'Error',
				detail: 'Error al obtener los reportes.',
			},
		},
		sidebar: {
			title: 'Búsqueda de Reportes Descargados',
		},
	};
};
