export const cheques = () => {
	return {
		contextMenu: {
			verEditar: 'Ver / Editar Cheque',
			delete: 'Borrar Cheque',
		},
		toast: {
			error: {
				summary: 'Error',
				detail: {
					searching: 'Error buscando clientes',
					deleting: 'Error borrando cheque',
				},
			},
		},
		title: {
			edit: 'Editar Cheque',
			add: 'Agregar Cheque',
            cheques: 'Cheques'
		},
		confirm: {
			message: '¿Está seguro que desea borrar este contacto?',
		},
        cheque: 'Cheque',
		sideBarTitle: 'Búsqueda de Cheques',
		labels: {
			fechaDesde: 'Fecha de cobro mayor a',
			fechaHasta: 'Fecha de cobro menor a',
			origen: 'Origen',
			fechaEmision: 'Fecha de Emisión',
			bank: 'Banco',
			fechaCobro: 'Fecha de Cobro',
            fechaRecepcion: 'Fecha de Recepción',
			emisor: 'Emisor',
		},
        subtotal: 'Subtotal Importe'
	};
};
