import { cotizar } from './cotizador/Cotizar';
import { config } from './cotizador/automotor/config';
import { emisiones } from './cotizador/automotor/emisiones';
import { historial } from './cotizador/automotor/historial';
import { parametrosGenerales } from './cotizador/automotor/parametrosGenerales';
import { presupuesto } from './cotizador/automotor/presupuesto';
import { prospectos } from './cotizador/prospectos/prospectos';

export const cotizador = () => {
	return {
		prospectos: prospectos(),
		cotizar: cotizar(),
		automotor: {
			parametrosGenerales: parametrosGenerales(),
			presupuesto: presupuesto(),
			historial: historial(),
			emisiones: emisiones(),
			config: config(),
		},
		components: {
			accesoriosTable: {
				tooltips: {
					agregar: 'Agregar',
				},
				columns: {
					sumaAsegurada: 'Suma Asegurada',
				}
			}
		}
	};
};
